import styled from 'styled-components';

import colors from 'constants/colors';

export const Header = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 16px;
  border-bottom: 1px solid ${colors.gray300};
`;

export const BodySection = styled.div`
  width: 400px;
  display: flex;
`;

export const Body = styled.div`
  height: 65vh;
  max-height: 600px;
  border-bottom: 1px solid ${colors.gray300};
  display: flex;
  ${BodySection} + ${BodySection} {
    border-left: 1px solid ${colors.gray300};
  }
`;

export const Footer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`;

export const HelpContainer = styled.div`
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;

  button + button {
    margin-left: 15px;
  }
`;
