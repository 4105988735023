import {
  ArrowRoundIcon,
  type ArrowRoundIconColor,
  type ArrowRoundIconDirection,
} from 'modules/analytics/components/common/icons/ArrowRoundIcon';
import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import { Container, ValueDescription } from './styles.css';

type TrendProps = {
  value: number;
  unit: Unit;
  reverseColor?: boolean;
};

export const TrendDescription = ({
  value,
  unit,
  reverseColor = false,
}: TrendProps) => {
  let color: ArrowRoundIconColor = 'green';
  let direction: ArrowRoundIconDirection = 'down';
  let signPrefix = '';

  if (value > 0) {
    color = 'red';
    direction = 'up';
    signPrefix = '+';
  }

  if (reverseColor) {
    color = color === 'red' ? 'green' : 'red';
  }

  const { t } = useTranslation('analytics');

  return (
    <Container>
      <ArrowRoundIcon color={color} direction={direction} />
      <ValueDescription>
        {signPrefix}
        {displayValueAndUnit(value, unit, t)}
        {` ${t('reports.common.vsPreviousPeriod')}`}
      </ValueDescription>
    </Container>
  );
};
