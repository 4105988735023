import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { AssetsTable } from 'modules/analytics/components/common/AssetListDrawer/AssetTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellProps, Column } from 'react-table';
import {
  type AssetListDataSource,
  type UsageHoursAssetsListItem,
  type UsageHoursAssetsListResponse,
  usageHoursAssetsListQuery,
} from '../gql';
import { DataCell } from './DataCell';

type AssetsListTableProps = {
  assetFilters: AssetFilterGQL;
  assetListDataSource: AssetListDataSource;
  since: Date;
  until: Date;
  onExportClick?: () => void;
  exportLoading?: boolean;
};

const getMetricColumn = (t: TFunction): Column<UsageHoursAssetsListItem> => ({
  Header: t('reports.usageHours.analysis.table.columns.usageHoursPerDay'),
  id: 'averageUsageHoursPerActiveDays',
  Cell: ({ row: { original } }: CellProps<UsageHoursAssetsListItem>) => {
    const { averageUsageHoursPerActiveDays, name } = original;
    return (
      <DataCell value={averageUsageHoursPerActiveDays} unit="h" title={name} />
    );
  },
  headerStyle: { paddingLeft: '8px' },
  style: { paddingLeft: '8px' },
  sortType: (rowA, rowB) => {
    return (
      rowA.original.averageUsageHoursPerActiveDays -
      rowB.original.averageUsageHoursPerActiveDays
    );
  },
});

export const AssetsListTable = ({
  assetFilters,
  assetListDataSource,
  since,
  until,
  onExportClick,
  exportLoading = false,
}: AssetsListTableProps) => {
  const { t } = useTranslation('analytics');
  const [rows, setRows] = useState<UsageHoursAssetsListItem[]>([]);

  const parseResponse = (data: UsageHoursAssetsListResponse) => {
    setRows(data.viewer.usageHoursReport.assetList);
  };

  const { loading } = useQuery<UsageHoursAssetsListResponse>(
    usageHoursAssetsListQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      onCompleted: parseResponse,
      variables: {
        assetFilters: assetFilters,
        period: {
          since: since,
          until: until,
        },
        dataSource: assetListDataSource,
      },
    },
  );

  const metricColumn = getMetricColumn(t);

  return (
    <AssetsTable
      since={since}
      until={until}
      metricColumn={metricColumn}
      loading={loading}
      page="usageHours"
      rows={rows}
      title={t('reports.usageHours.analysis.assetsPerformance.title')}
      exportLoading={exportLoading}
      onExportClick={onExportClick}
    />
  );
};
