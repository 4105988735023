import colors from 'constants/colors';
import { Category } from 'modules/common-ui';
import styled from 'styled-components';
import type { AssetRowItem } from './types';

const AssetItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 8px 3px 0px;
  gap: 4px;
`;

const AssetItemTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const AssetItemBottom = styled.div`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AssetName = styled.div`
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

type AssetRowProps = {
  asset: AssetRowItem;
};
export const AssetRow = ({ asset }: AssetRowProps) => {
  return (
    <AssetItemContainer>
      <AssetItemTop>
        <AssetName>{asset.name}</AssetName>
        {asset.category && (
          <Category
            category={asset.category}
            style={{ maxWidth: '140px', textAlign: 'center' }}
          />
        )}
      </AssetItemTop>
      <AssetItemBottom>{asset.make}</AssetItemBottom>
    </AssetItemContainer>
  );
};
