import colors from 'constants/colors';
import styled from 'styled-components';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  width: 210px;
`;

export const TootlTipDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TootlTipPreviousPeriod = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.gray500};
`;

export const TooltipDate = styled.div`
  font-weight: 700;
`;

export const TooltipSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipLabel = styled.div``;

export const TooltipValue = styled.div`
  font-weight: 700;
`;

export const TooltipDetail = styled.div``;

export const TooltipLink = styled.a`
  font-weight: 500;
  color: ${colors.blue100};
  text-decoration: none;
  cursor: pointer;
`;
