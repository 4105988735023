import type * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadZonesFragment = {
  __typename?: 'Group';
  zones: Array<{ __typename?: 'Address'; id: string; name: string }>;
};

export type LoadFaultSeveritiesFragment = {
  __typename?: 'Group';
  allFaultSeverities: Array<{ __typename?: 'FaultSeverity'; id: number }>;
};

export type FilterOptionsQueryVariables = Types.Exact<{
  loadZones?: Types.Scalars['Boolean']['input'];
  loadFaultSeverities?: Types.Scalars['Boolean']['input'];
}>;

export type FilterOptionsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    workspace?: {
      __typename?: 'Group';
      id?: string | null;
      filters: {
        __typename?: 'WorkspaceAssetFilters';
        names: Array<string>;
        makers: Array<string>;
        models: Array<string>;
        sources: Array<string>;
        sharing: {
          __typename?: 'AssetSharingDataFilters';
          sharingReferences: Array<string>;
          sharedByOrganizations: Array<{
            __typename?: 'KnownOrganizationSharing';
            id: string;
            name: string;
          }>;
          sharedWithOrganizations: Array<{
            __typename?: 'KnownOrganizationSharing';
            id: string;
            name: string;
          }>;
        };
        enterprises: Array<{
          __typename?: 'Enterprise';
          id: number;
          name: string;
        }>;
        categories: Array<{
          __typename?: 'Category';
          id: number;
          name: string;
        }>;
      };
      customFieldsV2: Array<{
        __typename?: 'CustomFieldV2Type';
        id: number;
        name: string;
        format: string;
        meta: any;
      }>;
      fleetSegments?: Array<{
        __typename?: 'FleetSegment';
        id: number;
        name: string;
        assetCount: number;
        kind: Types.FleetSegmentKind;
        filters: {
          __typename?: 'AssetFilters';
          names?: Array<string> | null;
          categories?: Array<number> | null;
          models?: Array<string> | null;
          makers?: Array<string> | null;
          sources?: Array<string> | null;
          enterprises?: Array<string> | null;
          favorite?: boolean | null;
          zones?: Array<string> | null;
          customfields?: Array<{
            __typename?: 'CustomField';
            id: number;
            values: Array<string>;
          }> | null;
        };
      }> | null;
      zones: Array<{ __typename?: 'Address'; id: string; name: string }>;
      allFaultSeverities: Array<{ __typename?: 'FaultSeverity'; id: number }>;
    } | null;
  } | null;
};

export type FilteredAssetsWithFleetSegmentQueryVariables = Types.Exact<{
  sort: Types.InventorySortBy;
  assetFilters?: Types.InputMaybe<Types.GraphQlAssetFilter>;
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
}>;

export type FilteredAssetsWithFleetSegmentQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    workspace?: {
      __typename?: 'Group';
      id?: string | null;
      inventoryV2: {
        __typename?: 'PaginatedAssets';
        total: number;
        rows: Array<{
          __typename?: 'Asset';
          id: string;
          make?: string | null;
          name?: string | null;
          category?: {
            __typename?: 'Category';
            id: number;
            name: string;
            color: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type CreateFleetSegmentMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  filters: Types.AssetFiltersInput;
}>;

export type CreateFleetSegmentMutation = {
  __typename?: 'Mutation';
  createFleetSegment?: { __typename?: 'FleetSegment'; id: number } | null;
};

export type UpdateFleetSegmentMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  name: Types.Scalars['String']['input'];
  filters: Types.AssetFiltersInput;
}>;

export type UpdateFleetSegmentMutation = {
  __typename?: 'Mutation';
  updateFleetSegment?: { __typename?: 'FleetSegment'; id: number } | null;
};

export type DeleteFleetSegmentMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;

export type DeleteFleetSegmentMutation = {
  __typename?: 'Mutation';
  deleteFleetSegment?: boolean | null;
};

export const LoadZonesFragmentDoc = gql`
    fragment LoadZones on Group {
  zones {
    id
    name
  }
}
    `;
export const LoadFaultSeveritiesFragmentDoc = gql`
    fragment LoadFaultSeverities on Group {
  allFaultSeverities {
    id
  }
}
    `;
export const FilterOptionsDocument = gql`
    query FilterOptions($loadZones: Boolean! = true, $loadFaultSeverities: Boolean! = false) {
  viewer {
    id
    workspace {
      id
      filters {
        sharing {
          sharingReferences
          sharedByOrganizations {
            id
            name
          }
          sharedWithOrganizations {
            id
            name
          }
        }
        enterprises {
          id
          name
        }
        categories {
          id
          name
        }
        names
        makers
        models
        sources
      }
      ...LoadZones @include(if: $loadZones)
      ...LoadFaultSeverities @include(if: $loadFaultSeverities)
      customFieldsV2 {
        id
        name
        format
        meta
      }
      fleetSegments {
        id
        name
        assetCount
        filters {
          names
          categories
          models
          makers
          sources
          enterprises
          customfields {
            id
            values
          }
          favorite
          zones
        }
        kind
      }
    }
  }
}
    ${LoadZonesFragmentDoc}
${LoadFaultSeveritiesFragmentDoc}`;

/**
 * __useFilterOptionsQuery__
 *
 * To run a query within a React component, call `useFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterOptionsQuery({
 *   variables: {
 *      loadZones: // value for 'loadZones'
 *      loadFaultSeverities: // value for 'loadFaultSeverities'
 *   },
 * });
 */
export function useFilterOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterOptionsQuery,
    FilterOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterOptionsQuery, FilterOptionsQueryVariables>(
    FilterOptionsDocument,
    options,
  );
}
export function useFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterOptionsQuery,
    FilterOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterOptionsQuery, FilterOptionsQueryVariables>(
    FilterOptionsDocument,
    options,
  );
}
export type FilterOptionsQueryHookResult = ReturnType<
  typeof useFilterOptionsQuery
>;
export type FilterOptionsLazyQueryHookResult = ReturnType<
  typeof useFilterOptionsLazyQuery
>;
export type FilterOptionsQueryResult = Apollo.QueryResult<
  FilterOptionsQuery,
  FilterOptionsQueryVariables
>;
export const FilteredAssetsWithFleetSegmentDocument = gql`
    query FilteredAssetsWithFleetSegment($sort: InventorySortBy!, $assetFilters: GraphQLAssetFilter, $limit: Int!, $offset: Int!) {
  viewer {
    id
    workspace {
      id
      inventoryV2(
        limit: $limit
        offset: $offset
        sort: $sort
        assetFilters: $assetFilters
      ) {
        rows {
          id
          category {
            id
            name
            color
          }
          make
          name
        }
        total
      }
    }
  }
}
    `;

/**
 * __useFilteredAssetsWithFleetSegmentQuery__
 *
 * To run a query within a React component, call `useFilteredAssetsWithFleetSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredAssetsWithFleetSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredAssetsWithFleetSegmentQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      assetFilters: // value for 'assetFilters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFilteredAssetsWithFleetSegmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilteredAssetsWithFleetSegmentQuery,
    FilteredAssetsWithFleetSegmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilteredAssetsWithFleetSegmentQuery,
    FilteredAssetsWithFleetSegmentQueryVariables
  >(FilteredAssetsWithFleetSegmentDocument, options);
}
export function useFilteredAssetsWithFleetSegmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilteredAssetsWithFleetSegmentQuery,
    FilteredAssetsWithFleetSegmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilteredAssetsWithFleetSegmentQuery,
    FilteredAssetsWithFleetSegmentQueryVariables
  >(FilteredAssetsWithFleetSegmentDocument, options);
}
export type FilteredAssetsWithFleetSegmentQueryHookResult = ReturnType<
  typeof useFilteredAssetsWithFleetSegmentQuery
>;
export type FilteredAssetsWithFleetSegmentLazyQueryHookResult = ReturnType<
  typeof useFilteredAssetsWithFleetSegmentLazyQuery
>;
export type FilteredAssetsWithFleetSegmentQueryResult = Apollo.QueryResult<
  FilteredAssetsWithFleetSegmentQuery,
  FilteredAssetsWithFleetSegmentQueryVariables
>;
export const CreateFleetSegmentDocument = gql`
    mutation createFleetSegment($name: String!, $filters: AssetFiltersInput!) {
  createFleetSegment(name: $name, filters: $filters) {
    id
  }
}
    `;
export type CreateFleetSegmentMutationFn = Apollo.MutationFunction<
  CreateFleetSegmentMutation,
  CreateFleetSegmentMutationVariables
>;

/**
 * __useCreateFleetSegmentMutation__
 *
 * To run a mutation, you first call `useCreateFleetSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFleetSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFleetSegmentMutation, { data, loading, error }] = useCreateFleetSegmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCreateFleetSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFleetSegmentMutation,
    CreateFleetSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFleetSegmentMutation,
    CreateFleetSegmentMutationVariables
  >(CreateFleetSegmentDocument, options);
}
export type CreateFleetSegmentMutationHookResult = ReturnType<
  typeof useCreateFleetSegmentMutation
>;
export type CreateFleetSegmentMutationResult =
  Apollo.MutationResult<CreateFleetSegmentMutation>;
export type CreateFleetSegmentMutationOptions = Apollo.BaseMutationOptions<
  CreateFleetSegmentMutation,
  CreateFleetSegmentMutationVariables
>;
export const UpdateFleetSegmentDocument = gql`
    mutation updateFleetSegment($id: Int, $name: String!, $filters: AssetFiltersInput!) {
  updateFleetSegment(id: $id, name: $name, filters: $filters) {
    id
  }
}
    `;
export type UpdateFleetSegmentMutationFn = Apollo.MutationFunction<
  UpdateFleetSegmentMutation,
  UpdateFleetSegmentMutationVariables
>;

/**
 * __useUpdateFleetSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateFleetSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFleetSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFleetSegmentMutation, { data, loading, error }] = useUpdateFleetSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUpdateFleetSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFleetSegmentMutation,
    UpdateFleetSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFleetSegmentMutation,
    UpdateFleetSegmentMutationVariables
  >(UpdateFleetSegmentDocument, options);
}
export type UpdateFleetSegmentMutationHookResult = ReturnType<
  typeof useUpdateFleetSegmentMutation
>;
export type UpdateFleetSegmentMutationResult =
  Apollo.MutationResult<UpdateFleetSegmentMutation>;
export type UpdateFleetSegmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateFleetSegmentMutation,
  UpdateFleetSegmentMutationVariables
>;
export const DeleteFleetSegmentDocument = gql`
    mutation deleteFleetSegment($id: Int!) {
  deleteFleetSegment(id: $id)
}
    `;
export type DeleteFleetSegmentMutationFn = Apollo.MutationFunction<
  DeleteFleetSegmentMutation,
  DeleteFleetSegmentMutationVariables
>;

/**
 * __useDeleteFleetSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteFleetSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFleetSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFleetSegmentMutation, { data, loading, error }] = useDeleteFleetSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFleetSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFleetSegmentMutation,
    DeleteFleetSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFleetSegmentMutation,
    DeleteFleetSegmentMutationVariables
  >(DeleteFleetSegmentDocument, options);
}
export type DeleteFleetSegmentMutationHookResult = ReturnType<
  typeof useDeleteFleetSegmentMutation
>;
export type DeleteFleetSegmentMutationResult =
  Apollo.MutationResult<DeleteFleetSegmentMutation>;
export type DeleteFleetSegmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteFleetSegmentMutation,
  DeleteFleetSegmentMutationVariables
>;
