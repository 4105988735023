import styled from 'styled-components';
import { formatAsHoursMinutes } from 'utils/time';

type HoursCellProps = {
  hours: number | null;
};

const Container = styled.div`
  display: flex;
`;

export const HoursCell = ({ hours }: HoursCellProps) => {
  const value = hours === null ? '-' : formatAsHoursMinutes(hours);
  return <Container>{value}</Container>;
};
