import html2PDF from 'jspdf-html2canvas';
import type { MutableRefObject, RefObject } from 'react';

export const buildExportPdfFunction = (
  ref: RefObject<HTMLDivElement>,
  options: {
    onExportStart?: () => void;
    onExportEnd?: () => void;
    ignoreElements?: Array<
      RefObject<HTMLDivElement> | MutableRefObject<HTMLDivElement | undefined>
    >;
  } = {},
) => {
  return () => {
    if (!ref.current) return;
    if (options.onExportStart) {
      options.onExportStart();
    }
    html2PDF(ref.current, {
      jsPDF: {
        format: 'a4',
      },
      html2canvas: {
        ignoreElements: (domNode) => {
          if (options.ignoreElements) {
            return options.ignoreElements.some((ref) => {
              return ref.current === domNode;
            });
          }
          return false;
        },
      },
      imageType: 'image/jpeg',
      success: (pdf) => {
        pdf.save('export.pdf');
      },
    })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      })
      .finally(() => {
        if (options.onExportEnd) {
          options.onExportEnd();
        }
      });
  };
};
