import { AnalyticsSection } from './analytics/AnalyticsSection';
import { FleetSection } from './fleet/FleetSection';

export const BodyContent = () => {
  return (
    <>
      <FleetSection />
      <AnalyticsSection />
    </>
  );
};
