import styled from 'styled-components';

import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';

export const SubHeaderContainer = styled.div`
  display: flex;

  div + div {
    margin-left: 4px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    display: none;
  }
`;

export const LinkText = styled.span`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    margin-left: 4px;
  }
`;
