import {
  FAVORITES_FILTER_VALUES,
  FILTER_TYPES,
  SHARING_IN_PROGRESS_STATUS_FILTER_VALUES,
} from 'constants/filter';
import type { TFunction } from 'i18next';
import type {
  CustomFieldsFilterOption,
  FilterOptionsList,
} from 'modules/common-ui';
import type {
  FilterObject,
  FilterOption,
} from 'modules/common-ui/components/Filter/FilterObject.type';
import type { AssetExtraFilterKey } from './types';

const getAssetNameFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.ASSET_NAME,
    label: t('filter:filter.filterType.assetName'),
    multiselect: true,
    options,
  };
};

const getBrandFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.BRAND,
    label: t('filter:filter.filterType.brand'),
    multiselect: true,
    options,
  };
};

const getEnterpriseFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.ENTERPRISE,
    label: t('filter:filter.filterType.enterprise'),
    multiselect: true,
    options,
  };
};

const getModelFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.MODEL,
    label: t('filter:filter.filterType.model'),
    multiselect: true,
    options,
  };
};

const getCategoryFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.CATEGORY,
    label: t('filter:filter.filterType.category'),
    multiselect: true,
    options,
  };
};

const getSourceFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.SOURCE,
    label: t('filter:filter.filterType.source'),
    multiselect: true,
    options,
  };
};

const getFavoriteFilter = (t: TFunction): FilterObject => {
  return {
    type: FILTER_TYPES.FAVORITE,
    label: t('filter:filter.filterType.favorite'),
    multiselect: false,
    options: [
      {
        label: t('filter:filter.favoritesOptions.yes'),
        value: FAVORITES_FILTER_VALUES.YES,
      },
      {
        label: t('filter:filter.favoritesOptions.no'),
        value: FAVORITES_FILTER_VALUES.NO,
      },
    ],
  };
};

const getZoneFilter = (options: FilterOption[], t: TFunction): FilterObject => {
  return {
    type: FILTER_TYPES.ZONE,
    label: t('filter:filter.filterType.zone'),
    multiselect: true,
    options,
  };
};

const getCustomFieldsFilter = (
  customFieldsFilters: CustomFieldsFilterOption[],
) => {
  return customFieldsFilters.map((cf) => ({
    type: `${FILTER_TYPES.CUSTOM_FIELD_V2}_${cf.id}`,
    label: cf.label,
    multiselect: true,
    options: cf.options,
    isCustomField: true,
    customFieldId: cf.id,
    key: cf.id,
  }));
};

const getFaultSeverityFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.FAULT_SEVERITY,
    label: t('filter:filter.filterType.faultSeverity'),
    multiselect: true,
    options,
  };
};

const getSharingReferenceFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.SHARING_REFERENCE,
    label: t('filter:filter.filterType.sharingReference'),
    multiselect: true,
    options,
  };
};

const getSharingInProgressStatusFilter = (t: TFunction): FilterObject => {
  return {
    type: FILTER_TYPES.SHARING_IN_PROGRESS_STATUS,
    label: t('filter:filter.filterType.sharingInProgressStatus'),
    multiselect: false,
    options: [
      {
        label: t('filter:filter.sharingInProgressStatusOptions.yes'),
        value: SHARING_IN_PROGRESS_STATUS_FILTER_VALUES.YES,
      },
      {
        label: t('filter:filter.sharingInProgressStatusOptions.no'),
        value: SHARING_IN_PROGRESS_STATUS_FILTER_VALUES.NO,
      },
    ],
  };
};

const getSharedByOrganizationFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.SHARED_BY_ORGANIZATION,
    label: t('filter:filter.filterType.sharedByOrganization'),
    multiselect: true,
    options,
  };
};

const getSharedWithOrganizationFilter = (
  options: FilterOption[],
  t: TFunction,
): FilterObject => {
  return {
    type: FILTER_TYPES.SHARED_WITH_ORGANIZATION,
    label: t('filter:filter.filterType.sharedWithOrganization'),
    multiselect: true,
    options,
  };
};

type buildFilterOptionsFromUserSelectionProps = {
  filtersOptions: FilterOptionsList;
  t: TFunction;
  extraFilterOptions: AssetExtraFilterKey[] | undefined;
  hasIssuedSharings: boolean | undefined;
  hasReceivedSharings: boolean | undefined;
};

export const buildFilterOptionsFromUserSelection = ({
  extraFilterOptions,
  filtersOptions,
  hasIssuedSharings,
  hasReceivedSharings,
  t,
}: buildFilterOptionsFromUserSelectionProps) => {
  const filterOptionsResult: FilterObject[] = _getCommonFilterOptions(
    filtersOptions,
    t,
  );
  if (extraFilterOptions?.includes('zone')) {
    filterOptionsResult.push(getZoneFilter(filtersOptions.zones || [], t));
  }
  if (extraFilterOptions?.includes('faultSeverity')) {
    filterOptionsResult.push(
      getFaultSeverityFilter(filtersOptions.faultSeverities || [], t),
    );
  }
  if (extraFilterOptions?.includes('sharingReference')) {
    filterOptionsResult.push(
      getSharingReferenceFilter(filtersOptions.sharingReferences || [], t),
    );
  }
  if (extraFilterOptions?.includes('sharingInProgressStatus')) {
    filterOptionsResult.push(getSharingInProgressStatusFilter(t));
  }

  if (
    extraFilterOptions?.includes('sharedByOrganization') &&
    hasReceivedSharings
  ) {
    filterOptionsResult.push(
      getSharedByOrganizationFilter(
        filtersOptions.sharedByOrganizations || [],
        t,
      ),
    );
  }
  if (
    extraFilterOptions?.includes('sharedWithOrganization') &&
    hasIssuedSharings
  ) {
    filterOptionsResult.push(
      getSharedWithOrganizationFilter(
        filtersOptions.sharedWithOrganizations || [],
        t,
      ),
    );
  }

  return filterOptionsResult;
};

const _getCommonFilterOptions = (
  filtersOptions: FilterOptionsList,
  t: TFunction,
) => [
  getAssetNameFilter(filtersOptions.names, t),
  getBrandFilter(filtersOptions.makers, t),
  getEnterpriseFilter(filtersOptions.enterprises, t),
  getModelFilter(filtersOptions.models, t),
  getCategoryFilter(filtersOptions.categories, t),
  getSourceFilter(filtersOptions.sources, t),
  getFavoriteFilter(t),
  ...getCustomFieldsFilter(filtersOptions.customFieldsFilters),
];
