import { TwoColumnsModal } from 'modules/layout/modals/twoColumns/TwoColumnsModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FleetSegmentForm, SegmentErrors } from '../../types';
import { Header } from './Header';
import { MatchingAssetList } from './MatchingAssetList';
import { SegmentEditor } from './SegmentEditor';

type SegmentMutateModalProps = {
  isOpen: boolean;
  isLoading?: boolean;
  segmentErrors: SegmentErrors;
  initialFleetSegment?: FleetSegmentForm;
  onCreate?: (fleetSegment: FleetSegmentForm) => void;
  onEdit?: (fleetSegment: FleetSegmentForm) => void;
  onCancel: () => void;
  onOutsideClick: () => void;
  resetSegmentErrors: () => void;
};

const defaultSegment: FleetSegmentForm = {
  name: '',
  filters: [{ type: '' }],
  isValid: false,
};

export const SegmentMutateModal = ({
  isOpen,
  isLoading,
  segmentErrors,
  onCreate,
  onEdit,
  onCancel,
  resetSegmentErrors,
  onOutsideClick,
  initialFleetSegment = defaultSegment,
}: SegmentMutateModalProps) => {
  const isEdit = !!initialFleetSegment?.id;

  const { t } = useTranslation('fleetSegment');
  const [fleetSegment, setFleetSegment] = useState<FleetSegmentForm>({
    ...defaultSegment,
    ...initialFleetSegment,
  });

  const leftContent = (
    <SegmentEditor
      fleetSegment={fleetSegment}
      segmentErrors={segmentErrors}
      onChange={setFleetSegment}
      resetSegmentErrors={resetSegmentErrors}
    />
  );
  const rightContent = <MatchingAssetList fleetSegment={fleetSegment} />;
  const headerContent = (
    <Header
      title={
        isEdit
          ? t('modal.update.title', { name: initialFleetSegment.name })
          : t('modal.create.title')
      }
      subtitle={isEdit ? '' : t('modal.create.subtitle')}
    />
  );

  const onValidateSegment = () => {
    if (isEdit) {
      onEdit?.(fleetSegment);
    } else {
      onCreate?.(fleetSegment);
    }
  };

  return (
    <>
      <TwoColumnsModal
        leftContent={leftContent}
        rightContent={rightContent}
        headerContent={headerContent}
        validateLabel={
          isEdit ? t('modal.update.submitLabel') : t('modal.create.submitLabel')
        }
        cancelLabel={t('commonUi:cancel')}
        isOpen={isOpen}
        onCancel={onCancel}
        onValidate={onValidateSegment}
        disableValidate={fleetSegment.isValid === false}
        isLoading={isLoading}
        onRequestClose={onOutsideClick}
        sectionWidth="480px"
      />
    </>
  );
};
