import colors from 'constants/colors';
import { ArrowUpTrendIcon } from 'modules/common-ui/components/Icons/ArrowUpTrendIcon/ArrowUpTrendIcon';

export type ArrowRoundIconDirection = 'up' | 'down' | 'flat';
export type ArrowRoundIconColor = 'red' | 'green';

type ArrowRoundIconProps = {
  color: ArrowRoundIconColor;
  direction?: ArrowRoundIconDirection;
};

const colorCode: { [key: string]: { [key: string]: string } } = {
  red: {
    background: colors.red50,
    color: colors.red500,
  },
  green: {
    background: colors.green50,
    color: colors.green700,
  },
};

export const ArrowRoundIcon = ({ direction, color }: ArrowRoundIconProps) => {
  const rotate = direction === 'down' ? 'rotate(180deg)' : '';

  return (
    <ArrowUpTrendIcon
      style={{ transform: rotate }}
      color={colorCode[color].color}
      backgroundColor={colorCode[color].background}
    />
  );
};
