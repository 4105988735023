import {
  SimpleValue,
  type SimpleValueProps,
} from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValue';
import { useState } from 'react';
import { CustomTooltip } from '../../common/CustomTooltip/CustomTooltip';
import { TextCard, type TextCardProps } from '../../common/layout/Card';

type SimpleValueCardProps = TextCardProps<SimpleValueProps>;

export const SimpleValueCard = ({
  title,
  titleInfo,
  subtitle,
  tooltip,
  onClick,
  ...rest
}: SimpleValueCardProps) => {
  const [randomId] = useState(() => Math.random().toString(36).slice(2));
  const tooltipId = `simple-value-card-tooltip-${randomId}`;
  return (
    <>
      <TextCard
        title={title}
        titleInfo={titleInfo}
        subtitle={subtitle}
        tooltipId={tooltipId}
        onClick={onClick}
      >
        <SimpleValue {...rest} />
      </TextCard>
      {tooltip && <CustomTooltip tooltipId={tooltipId} tooltip={tooltip} />}
    </>
  );
};
