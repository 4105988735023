import { FAVORITES_FILTER_VALUES, FILTER_TYPES } from 'constants/filter';
import { buildFilterOptionsFromGql } from 'modules/common-ui';
import type { FilterObject } from 'modules/common-ui/components/Filter/FilterObject.type';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterOptionsQuery } from '../../gql.generated';

export const useFilters = () => {
  const { t } = useTranslation('filter');
  const { loading, data } = useFilterOptionsQuery();

  const filtersOptions = useMemo(() => {
    if (loading || !data?.viewer?.workspace) {
      return buildFilterOptionsFromGql();
    }
    return buildFilterOptionsFromGql({
      ...data.viewer.workspace,
      ...data.viewer.workspace.filters,
    });
  }, [data, loading]);

  const filterOptionsProp: FilterObject[] = useMemo(() => {
    const filterOptionsResult: FilterObject[] = [
      {
        type: FILTER_TYPES.ASSET_NAME,
        label: t('filter.filterType.assetName'),
        multiselect: true,
        options: filtersOptions.names,
      },
      {
        type: FILTER_TYPES.BRAND,
        label: t('filter.filterType.brand'),
        multiselect: true,
        options: filtersOptions.makers,
      },
      {
        type: FILTER_TYPES.ENTERPRISE,
        label: t('filter.filterType.enterprise'),
        multiselect: true,
        options: filtersOptions.enterprises,
      },
      {
        type: FILTER_TYPES.MODEL,
        label: t('filter.filterType.model'),
        multiselect: true,
        options: filtersOptions.models,
      },
      {
        type: FILTER_TYPES.CATEGORY,
        label: t('filter.filterType.category'),
        multiselect: true,
        options: filtersOptions.categories,
      },
      {
        type: FILTER_TYPES.SOURCE,
        label: t('filter.filterType.source'),
        multiselect: true,
        options: filtersOptions.sources,
      },
      {
        type: FILTER_TYPES.FAVORITE,
        label: t('filter.filterType.favorite'),
        multiselect: false,
        options: [
          {
            label: t('filter.favoritesOptions.yes'),
            value: FAVORITES_FILTER_VALUES.YES,
          },
          {
            label: t('filter.favoritesOptions.no'),
            value: FAVORITES_FILTER_VALUES.NO,
          },
        ],
      },
      {
        type: FILTER_TYPES.ZONE,
        label: t('filter:filter.filterType.zone'),
        multiselect: true,
        options: filtersOptions.zones || [],
      },
      ...filtersOptions.customFieldsFilters.map((cf) => ({
        type: `${FILTER_TYPES.CUSTOM_FIELD_V2}_${cf.id}`,
        label: cf.label,
        multiselect: true,
        options: cf.options,
        isCustomField: true,
        customFieldId: cf.id,
        key: cf.id,
      })),
    ];
    return filterOptionsResult;
  }, [filtersOptions, t]);

  return filterOptionsProp;
};
