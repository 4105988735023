import type { EventName } from 'modules/utils/analytics';

export type Page =
  | 'idleRate'
  | 'hourlyConsumption'
  | 'carbonEmissions'
  | 'usageHours'
  | 'equipmentFaults'
  | 'fleetData'
  | 'assetProfile'
  | 'home';

export type Section =
  | 'overview'
  | 'analysis'
  | 'evolution'
  | 'assetsPerformance'
  | 'assetList'
  | 'enterprisesPerformance'
  | 'categories';

type TrackFunction = (
  eventName: EventName,
  properties: Record<string, any>,
) => void;

export const trackReportExported = (
  track: TrackFunction,
  page: Page,
  section: Section,
  format: string,
) => {
  track('Report exported', {
    page,
    section,
    format: format.toUpperCase(),
  });
};
export const trackPeriodicitySelected = (
  track: TrackFunction,
  page: Page,
  section: Section,
  periodicity: string,
) => {
  track('Periodicity selected', {
    page,
    section,
    periodicity,
  });
};

export const trackSwitchReportClicked = (track: TrackFunction, to: string) => {
  track('Switch report clicked', {
    to,
  });
};

export const trackColumnUpdated = (
  track: TrackFunction,
  attributes: {
    page: Page;
    section: Section;
    column: string;
    enabled: boolean;
  },
) => {
  track('Column updated', attributes);
};

export const trackColumnSorted = (
  track: TrackFunction,
  attributes: {
    page: Page;
    section: Section;
    column: string;
    order: 'asc' | 'desc';
  },
) => {
  track('Column sorted', attributes);
};

export const trackNumberOfResultChanged = (
  track: TrackFunction,
  attributes: {
    page: Page;
    section: Section;
    number: number;
  },
) => {
  track('Number of result changed', attributes);
};

export const trackAssetDrawerOpened = (
  track: TrackFunction,
  attributes: {
    page: Page;
    section: Section;
    source: string;
  },
) => {
  track('Report asset list lookup opened', attributes);
};
