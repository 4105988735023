/* eslint-disable max-statements */
import type { FilterTypeKey } from 'constants/filter';
import {
  filterAssetFilters,
  usePersistedReportFilters,
} from 'context/filters/PersistentReportFiltersContext';
import {
  Filter,
  appliedFiltersToSelectedFilters,
  buildFilterOptionsFromGql,
} from 'modules/common-ui';
import type { FilterObject } from 'modules/common-ui/components/Filter/FilterObject.type';
import type { CalloutOnFilter } from 'modules/common-ui/components/Filter/FilterSelector';
import { useSharingListContext } from 'modules/sharing/hooks/getSharingListContext/getSharingListContext.hook';
import { useTracking } from 'modules/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FleetSegment } from '../../../common-ui/models/FleetSegment';
import { buildFilterOptionsFromUserSelection } from './buildFilterOptions';
import { SegmentCreate } from './components/SegmentCreate';
import { SegmentDelete } from './components/SegmentDelete';
import { SegmentEdit } from './components/SegmentEdit';
import { useFilterOptionsQuery } from './gql.generated';
import { useFleetSegmentList } from './hooks/fleetSegmentList/fleetSegmentList';
import type {
  ActiveAssetFilters,
  AssetExtraFilterKey,
  AssetExtraFilterResult,
  AssetFilterOptions,
} from './types';
import {
  computeActiveFilters,
  computeExtraFilterValues,
  selectedFiltersToObject,
} from './utils';

type AssetFilterProps = {
  extraFilterOptions?: AssetExtraFilterKey[];
  onFilter: (
    appliedFilters: AssetFilterOptions,
    selectedSegmentId: number | null,
    extraFilters?: Partial<AssetExtraFilterResult>,
  ) => void;
  onChangeActiveFilters?: (filters: ActiveAssetFilters) => void;
  trackingProps: {
    page: string;
  };
  appliedFilters: AssetFilterOptions;
  small?: boolean;
  tooltip?: string;
  disabled?: boolean;
  alignRight?: boolean;
  zoneFilterCallOut?: boolean;
};

export const AssetFilter = ({
  onChangeActiveFilters,
  onFilter,
  extraFilterOptions,
  trackingProps,
  appliedFilters,
  small = false,
  tooltip = '',
  disabled = false,
  alignRight = true,
  zoneFilterCallOut = true,
}: AssetFilterProps) => {
  const { t } = useTranslation('filter');
  const { track } = useTracking();
  const [openNewSegmentModal, setOpenNewSegmentModal] = useState(false);
  const [openEditSegmentModal, setOpenEditSegmentModal] = useState(false);
  const [openDeleteSegmentModal, setOpenDeleteSegmentModal] = useState(false);
  const { getSelectedSegmentId, isDefaultContext } =
    usePersistedReportFilters();
  const [selectedSegmentId, setSelectedSegmentId] = useState<number | null>(
    getSelectedSegmentId(),
  );
  const [fleetSegmentToEdit, setFleetSegmentToEdit] =
    useState<FleetSegment | null>(null);
  const [fleetSegmentToDelete, setFleetSegmentToDelete] =
    useState<FleetSegment | null>(null);

  const { loading, data, refetch } = useFilterOptionsQuery({
    variables: {
      loadFaultSeverities:
        !!extraFilterOptions && extraFilterOptions.includes('faultSeverity'),
      loadZones: !!extraFilterOptions && extraFilterOptions.includes('zone'),
    },
  });
  const { hasIssuedSharings, hasReceivedSharings } = useSharingListContext();

  const trackFilter = useCallback(
    (assetFilters: AssetFilterOptions) => {
      const isFilterActive = (filter: FilterTypeKey): boolean =>
        assetFilters[filter] !== undefined && assetFilters[filter]!.length > 0;

      // To keep the tracking consistent with the previous implementation,
      // we should use the same keys used on the old implementation
      track('Filter selected', {
        page: trackingProps.page,
        assetNames: isFilterActive('assetName'),
        makers: isFilterActive('brand'),
        models: isFilterActive('model'),
        enterprises: isFilterActive('enterprise'),
        categories: isFilterActive('category'),
        sources: isFilterActive('source'),
        sharingReferences: isFilterActive('sharingReference'),
        sharingInProgressStatus: isFilterActive('sharingInProgressStatus'),
        sharedByOrganizations: isFilterActive('sharedByOrganization'),
        sharedWithOrganizations: isFilterActive('sharedWithOrganization'),
        favorite: assetFilters.favorite !== undefined,
      });
    },
    [track, trackingProps],
  );

  const filtersOptions = useMemo(() => {
    if (loading || !data?.viewer?.workspace) {
      return buildFilterOptionsFromGql();
    }
    const filtersFromWorkspace = data.viewer.workspace;
    return buildFilterOptionsFromGql({
      ...filtersFromWorkspace,
      ...filtersFromWorkspace.filters,
      ...filtersFromWorkspace.filters.sharing,
    });
  }, [data, loading]);

  const filterOptionsProp: FilterObject[] = useMemo(() => {
    const filterOptionsResult = buildFilterOptionsFromUserSelection({
      filtersOptions,
      t,
      extraFilterOptions,
      hasIssuedSharings,
      hasReceivedSharings,
    });
    return filterOptionsResult;
  }, [
    filtersOptions,
    t,
    extraFilterOptions,
    hasIssuedSharings,
    hasReceivedSharings,
  ]);

  const { segments } = useFleetSegmentList(filterOptionsProp);

  // we end up here when we restore a segment from the URL. The parent don't have
  // the corresponding filters so we trigger the onFilter cb to send them
  useEffect(() => {
    // we don't care about getting the segment from the context if we are not in a custom context
    if (isDefaultContext) return;
    const selectedSegmentId = getSelectedSegmentId();
    setSelectedSegmentId(selectedSegmentId);
    const segment = segments.find((s) => s.id === selectedSegmentId);
    if (!segment) return;
    // we don't want to trigger the event if applied filters is already populated
    // to prevent infinite loop
    if (
      Object.keys(appliedFilters).length === 0 &&
      Object.keys(segment.filters).length > 0
    ) {
      onFilter(selectedFiltersToObject(segment.filters), selectedSegmentId);
    }
  }, [
    appliedFilters,
    getSelectedSegmentId,
    onFilter,
    segments,
    isDefaultContext,
  ]);

  useEffect(() => {
    if (!onChangeActiveFilters) {
      return;
    }
    const filteredAppliedFilters = filterAssetFilters(
      appliedFilters,
      filterOptionsProp,
    );
    const activeAssetFilters = computeActiveFilters(
      filteredAppliedFilters,
      filterOptionsProp,
    );
    onChangeActiveFilters(activeAssetFilters);
  }, [appliedFilters, filterOptionsProp, onChangeActiveFilters]);

  const handleFilters = useCallback(
    (assetFilters: AssetFilterOptions, segmentId?: number) => {
      trackFilter(assetFilters);
      onFilter(
        assetFilters,
        segmentId || null,
        computeExtraFilterValues(assetFilters, extraFilterOptions),
      );
    },
    [extraFilterOptions, onFilter, trackFilter],
  );

  const initialSelectedFilters = useMemo(() => {
    return appliedFiltersToSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const triggerEditSegment = (fleetSegment: FleetSegment) => {
    setFleetSegmentToEdit(fleetSegment);
    setOpenEditSegmentModal(true);
  };

  const triggerDeleteSegment = (fleetSegment: FleetSegment) => {
    setFleetSegmentToDelete(fleetSegment);
    setOpenDeleteSegmentModal(true);
  };

  const onSegmentDeleted = () => {
    refetch();
    setOpenDeleteSegmentModal(false);
    const defaultFleetSegment =
      segments.find((s) => s.isDefault) || segments[0];
    const defaultFleetSegmentId = defaultFleetSegment?.id;
    if (defaultFleetSegmentId) {
      const defaultFleetSegmentFilters = selectedFiltersToObject(
        defaultFleetSegment.filters,
      );
      setSelectedSegmentId(defaultFleetSegmentId);
      onFilter(defaultFleetSegmentFilters, selectedSegmentId);
    }
  };

  const calloutOnFilter: CalloutOnFilter | undefined = zoneFilterCallOut
    ? {
        filterType: 'zone',
        text: t('filter.zoneCallOut'),
      }
    : undefined;

  return (
    <>
      <Filter
        disabled={disabled}
        small={small}
        tooltip={tooltip}
        filters={filterOptionsProp}
        segments={segments}
        selectedSegmentId={selectedSegmentId}
        onFilter={handleFilters}
        appliedFilters={filterAssetFilters(appliedFilters, filterOptionsProp)}
        loading={loading}
        alignRight={alignRight}
        triggerCreateSegment={() => setOpenNewSegmentModal(true)}
        triggerEditSegment={triggerEditSegment}
        triggerDeleteSegment={triggerDeleteSegment}
        onSelectSegment={(fleetSegment) => {
          setSelectedSegmentId(fleetSegment?.id || null);
          if (fleetSegment) {
            track('Segment applied', {
              id: fleetSegment.id,
              name: fleetSegment.name,
              filters: fleetSegment.filters,
              page: trackingProps.page,
            });
          }
        }}
        calloutOnFilter={calloutOnFilter}
      />
      {openNewSegmentModal && (
        <SegmentCreate
          initialSelectedFilters={initialSelectedFilters}
          onSegmentCreated={(
            fleetSegmentId: number,
            filters: AssetFilterOptions,
            fleetSegmentName: string,
          ) => {
            refetch();
            setOpenNewSegmentModal(false);
            setSelectedSegmentId(fleetSegmentId);
            onFilter(filters, fleetSegmentId);
            track('Segment created', {
              id: fleetSegmentId,
              name: fleetSegmentName,
              filters: filters,
              page: trackingProps.page,
            });
          }}
          onCancel={() => setOpenNewSegmentModal(false)}
        />
      )}
      {openEditSegmentModal && fleetSegmentToEdit && (
        <SegmentEdit
          onSegmentEdited={(
            fleetSegmentId: number,
            filters: AssetFilterOptions,
          ) => {
            refetch();
            setOpenEditSegmentModal(false);
            setSelectedSegmentId(fleetSegmentId);
            onFilter(filters, fleetSegmentId);
          }}
          onCancel={() => setOpenEditSegmentModal(false)}
          initialFleetSegment={fleetSegmentToEdit}
        />
      )}
      {openDeleteSegmentModal && fleetSegmentToDelete && (
        <SegmentDelete
          onSegmentDeleted={onSegmentDeleted}
          onCancel={() => setOpenDeleteSegmentModal(false)}
          fleetSegment={fleetSegmentToDelete}
        />
      )}
    </>
  );
};
