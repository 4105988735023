import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import { PAGE_LATERAL_MARGIN, REGULAR_WIDTH } from 'modules/layout';
import styled from 'styled-components';

export const HeaderWrapper = styled.div<{ sticky?: boolean }>`
  ${(props) =>
    props.sticky
      ? `
        z-index: 10;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        background-color: white;
      `
      : ''}
`;

export const Header = styled.div<{
  sticky?: boolean;
  noBottomPadding?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: background-color 250ms linear, box-shadow 250ms linear;
  border-bottom: 1px solid transparent;

  padding: 12px ${PAGE_LATERAL_MARGIN};
  ${(props) => props.noBottomPadding && 'padding-bottom: 0px;'}

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    padding: 8px;
  }
`;

export const HeaderContainer = styled.div<{ fullWidth: boolean }>`
  max-width: ${(props) => (props.fullWidth ? '100%' : REGULAR_WIDTH)};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  gap: 32px;
  min-width: 0;
  align-items: center;
`;

export const SubHeaderContainer = styled.div<{ fullWidth: boolean }>`
  max-width: ${(props) => (props.fullWidth ? '100%' : REGULAR_WIDTH)};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6px auto 4px auto;
`;

export const TitleContainer = styled.div`
  flex-direction: column;
  min-width: 0;
`;

export const ActionContainer = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubHeader = styled.div<{ sticky?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 250ms linear, box-shadow 250ms linear;

  padding: 0px ${PAGE_LATERAL_MARGIN};

  font-weight: ${({ theme }) => theme.typology.font.weight.medium};
  font-size: ${({ theme }) => theme.typology.font.size.l};

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    padding:0 8px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Title = styled.div<{ titleIconLayout: 'row' | 'column' }>`
  font-size: 28px;
  font-weight: 600;
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.titleIconLayout === 'column'
      ? `
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  `
      : ''}

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const TitleText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
