import { Button, HModal, Spinner } from 'modules/common-ui';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionsContainer,
  Body,
  BodySection,
  Footer,
  Header,
  HelpContainer,
} from './styles.css';

type TwoColumnsModalProps = {
  isOpen: boolean;
  onRequestClose?: () => void;
  headerContent: ReactNode;
  isLoading?: boolean;
  leftContent: ReactNode;
  rightContent: ReactNode;
  helpContent?: ReactNode;
  cancelLabel?: string;
  onCancel: () => void;
  validateLabel?: string;
  onValidate: () => void;
  disableValidate?: boolean;
  sectionWidth?: string;
};

export const TwoColumnsModal = ({
  isOpen,
  onRequestClose,
  headerContent,
  isLoading = false,
  leftContent,
  rightContent,
  onCancel,
  onValidate,
  cancelLabel,
  validateLabel,
  helpContent,
  disableValidate = false,
  sectionWidth = '400px',
}: TwoColumnsModalProps) => {
  const { t } = useTranslation('commonUi');

  return (
    <HModal
      style={{ overflow: 'visible' }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Header>{headerContent}</Header>
      {isLoading ? (
        <Spinner />
      ) : (
        <Body>
          <BodySection style={{ width: sectionWidth }}>
            {leftContent}
          </BodySection>
          <BodySection style={{ width: sectionWidth }}>
            {rightContent}
          </BodySection>
        </Body>
      )}
      <Footer>
        <HelpContainer>{helpContent}</HelpContainer>
        <ActionsContainer>
          <Button variant="neutral" onClick={onCancel}>
            {cancelLabel || t('cancel')}
          </Button>
          <Button
            disabled={disableValidate || isLoading}
            variant="primary"
            onClick={onValidate}
          >
            {validateLabel || t('validate')}
          </Button>
        </ActionsContainer>
      </Footer>
    </HModal>
  );
};
