import colors from 'constants/colors';
import type { TFunction } from 'i18next';

export const ClickToViewDrawer = (t: TFunction) => {
  return `
  <div style="
    border-radius: 3px;
    background: ${colors.gray700};
    padding: 4px;
    color: ${colors.gray300};
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    height:21px">
    <img src="/images/infoIcon.svg" alt="info" width=12 height=12/>
  
    <p style="margin-left:4px;">${t('analytics:reports.common.viewAssets')}</p>
  </div>`;
};
