import type { PropsWithChildren, ReactNode } from 'react';
import {
  ActionContainer,
  Container,
  HeaderContainer,
  InnerContainer,
  Subtitle,
  Title,
  TitleContainer,
} from './styles.css';

type SectionProps = {
  title: string;
  subtitle?: string | ReactNode;
  actionElement?: ReactNode;
};

export const Section = ({
  children,
  title,
  subtitle,
  actionElement,
}: PropsWithChildren<SectionProps>) => {
  const hasSubtitle = subtitle !== undefined;
  const hasActionElement = actionElement !== undefined;

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>{title}</Title>
          {hasSubtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
        {hasActionElement && <ActionContainer>{actionElement}</ActionContainer>}
      </HeaderContainer>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};
