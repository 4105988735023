import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { transformFiltersToGqlFilters } from 'modules/analytics/components/AssetFilter/utils';
import { LoadingComponent } from 'modules/analytics/components/Dataviz/LineChart/common';
import type { UsageHoursByPeriod } from 'modules/analytics/reports/usageHours/gql';
import { EvolutionDrawer } from 'modules/analytics/reports/usageHours/sections/UsageHoursAnalysis/cards/EvolutionDrawer';
import { useUsageHoursStatistics } from 'modules/analytics/reports/usageHours/sections/UsageHoursAnalysis/cards/UsageHoursStatistics';
import { useMemo, useState } from 'react';
import { type PeriodScale, getEndPeriodDate } from 'utils/time';
import { useGetUsageHoursData } from '../hooks/getUsageHoursData';
import { getLast7DaysPeriod } from '../utils/date';
import { UsageHoursComponent } from './view';

type UsageHoursProps = {
  assetFilters: AssetFilterOptions;
  since: Date;
  until: Date;
};

const computeSelectedPeriod = (scale: PeriodScale, selectedDay: Date) => {
  if (scale === 'day') {
    return {
      since: selectedDay,
      until: getEndPeriodDate(selectedDay, 'day'),
    };
  }
  return getLast7DaysPeriod();
};

export const UsageHours = (props: UsageHoursProps) => {
  const [selectedDataPoint, setSelectedDataPoint] =
    useState<UsageHoursByPeriod | null>(null);
  const [granularityScale, setGranularityScale] = useState<PeriodScale>('day');

  const { assetFilters, since, until } = props;

  const assetFiltersGQL = useMemo(() => {
    return transformFiltersToGqlFilters(assetFilters);
  }, [assetFilters]);

  const { loading, data } = useUsageHoursStatistics({
    assetFilters: assetFiltersGQL,
    since,
    until,
  });

  const { loading: isChartsLoading, data: chartsData } = useGetUsageHoursData({
    assetFilters: assetFiltersGQL,
    since,
    until,
  });

  if (loading || isChartsLoading || !data) {
    return <LoadingComponent />;
  }

  return (
    <>
      <UsageHoursComponent
        averageUsageHoursPerActiveDays={
          data?.averageUsageHoursPerActiveDays.current || null
        }
        previousAverageUsageHoursPerActiveDays={
          data?.averageUsageHoursPerActiveDays.previous || null
        }
        statisticData={data}
        data={chartsData}
        setSelectedDataPoint={setSelectedDataPoint}
        setGranularityScale={setGranularityScale}
      />
      {selectedDataPoint && (
        <EvolutionDrawer
          assetFilters={assetFiltersGQL}
          granularityScale={granularityScale}
          onClose={() => {
            setSelectedDataPoint(null);
          }}
          open={!!selectedDataPoint}
          selectedPeriod={computeSelectedPeriod(
            granularityScale,
            selectedDataPoint.date,
          )}
          usedAssetsCount={selectedDataPoint.activeAssetsCount}
          activeAssetsCount={selectedDataPoint.activeAssetsCount}
          averageUsageHoursPerActiveDays={
            selectedDataPoint.averageUsageHoursPerActiveDays
          }
        />
      )}
    </>
  );
};
