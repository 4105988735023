import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import type { LineChartData } from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { fillEmptyData } from 'modules/analytics/lib/fillEmptyData';
import {
  type UsageHoursByPeriod,
  type UsageHoursByPeriodResponse,
  usageHoursReportEmissionsQuery,
} from 'modules/analytics/reports/usageHours/gql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { displayValueAndUnit } from 'utils/unit';

const parseChartData = (
  period: { since: Date; until: Date },
  gqlData: UsageHoursByPeriod[] | undefined,
  t: TFunction<'analytics', undefined>,
): LineChartData[] => {
  const res: LineChartData[] = [];

  if (!gqlData || gqlData.length === 0) {
    return [];
  }
  for (const item of gqlData) {
    const date = new Date(item.date);
    res.push({
      value: item.averageUsageHoursPerActiveDays,
      date,
      originalData: item,
      tooltipData: [
        {
          label: t(
            'reports.usageHours.overview.chart.tooltip.averageUsageHoursPerActiveDays',
          ),
          value: `<b>${displayValueAndUnit(
            item.averageUsageHoursPerActiveDays,
            'h',
            t,
          )}</b>`,
        },
        {
          label: t('reports.usageHours.overview.chart.tooltip.usedAssets'),
          value: `<b>${item.usedAssetsCount}</b>/${item.activeAssetsCount}`,
        },
      ],
    });
  }

  return fillEmptyData(res, period, 'day');
};

export const useGetUsageHoursData = ({
  assetFilters,
  since,
  until,
}: {
  assetFilters: AssetFilterGQL;
  since: Date;
  until: Date;
}) => {
  const { t } = useTranslation('analytics');

  const { loading, data } = useQuery<UsageHoursByPeriodResponse>(
    usageHoursReportEmissionsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        assetFilters,
        since: since,
        until: until,
        periodScale: 'DAY',
      },
    },
  );

  const parsedData = useMemo(
    () =>
      parseChartData(
        { since, until },
        data?.viewer?.usageHoursReport.usageHours,
        t,
      ),
    [data, since, until, t],
  );

  return {
    data: parsedData,
    loading,
  };
};
