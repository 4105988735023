import * as htmlToImage from 'html-to-image';
import type { RefObject } from 'react';

export type exportImageFormat = 'png' | 'jpg' | 'svg';

export const buildExportImageFunction = (
  format: exportImageFormat,
  ref: RefObject<HTMLDivElement>,
  options: {
    onExportStart?: () => void;
    onExportEnd?: () => void;
    skipClassName?: string;
  } = {},
) => {
  return () => {
    if (!ref.current) return;
    let convertFunc = null;
    switch (format) {
      case 'png':
        convertFunc = htmlToImage.toPng;
        break;
      case 'jpg':
        convertFunc = htmlToImage.toJpeg;
        break;
      // Not working correctly (image is empty) !!
      case 'svg':
        convertFunc = htmlToImage.toSvg;
        break;
    }

    if (!convertFunc) {
      console.error(`Invalid format ${format}`);
      return;
    }

    if (options.onExportStart) {
      options.onExportStart();
    }

    convertFunc(ref.current, {
      filter(domNode) {
        return options.skipClassName
          ? !domNode.classList?.contains(options.skipClassName)
          : true;
      },
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `export.${format}`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      })
      .finally(() => {
        if (options.onExportEnd) {
          options.onExportEnd();
        }
      });
  };
};
