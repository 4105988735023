import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import { NoValue } from './NoValue';
import { NoTrend } from './Trend/NoTrend';
import { TrendDescription } from './Trend/TrendDescription';
import { Container, Infos, InnerContent, Value } from './styles.css';

export type SimpleValueWithInfosProps = {
  value: number | null;
  comparativeValue?: number | null;
  reverseTrendColor?: boolean;
  unit: Unit;
  infos: string | JSX.Element;
};

export const SimpleValueWithInfos = ({
  value,
  comparativeValue,
  reverseTrendColor,
  unit,
  infos,
}: SimpleValueWithInfosProps) => {
  const { t } = useTranslation('analytics');

  const comparativeComponent = () => {
    if (comparativeValue == null || value === null) return <NoTrend />;
    return (
      <TrendDescription
        value={value - comparativeValue}
        unit={unit === '%' ? 'pts' : unit}
        reverseColor={reverseTrendColor}
      />
    );
  };
  const hasComparativeValue = comparativeValue !== undefined;
  const hasValue = value !== null;

  return (
    <Container>
      {hasValue ? (
        <InnerContent>
          <Value>{displayValueAndUnit(value, unit, t)}</Value>
          <Infos>{infos}</Infos>
          {hasComparativeValue && comparativeComponent()}
        </InnerContent>
      ) : (
        <NoValue />
      )}
    </Container>
  );
};
