import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Action, Dispatch } from 'redux';

import { apiClient } from 'modules/api-client';
import { HibooLogoLarge, OnboardingIcon } from 'modules/common-ui';
import { GlobalSearchButton } from 'modules/global-search';
import layout from 'modules/layout';
import { useOnboarding } from 'modules/onboarding';
import { BottomNavigation } from 'modules/sidenav/components/BottomNavigation';
import { userSwitchToken } from 'modules/user/actions';
import type { Store } from 'reducers';
import { removeFromStorage as clearFilters } from 'utils/filters';

import { useQuery } from '@apollo/client';
import { FLAGS } from 'constants/flags';
import { WorkspacePicker } from 'modules/sidenav/components/WorkspacePicker';
import { type GraphQLGroupFeatures, query } from 'modules/sidenav/gql';
import { useFeature } from 'modules/utils';
import { Footer } from '../../components/Footer';
import { NavItem } from '../../components/NavItem';
import { Navigation } from '../../components/Navigation';
import {
  CustomLogo,
  FirstNavPartContainer,
  HibooLogo,
  LogoContainer,
  ScrollableContainer,
  SideTopContainer,
} from './index.css';

type SideNavDesktopProps = {
  token: string | null;
  switchToken: typeof userSwitchToken;
  renderId: number;
  props: {
    appSyncOnly?: boolean;
    destinationsFlag?: boolean;
    tyreReportFlag?: boolean;
    noReport?: boolean;
    newHomeFlag?: boolean;
  };
};

const SideNavDesktopComponent = ({
  token,
  switchToken,
  renderId,
  props = {
    appSyncOnly: false,
    noReport: false,
    destinationsFlag: false,
    newHomeFlag: false,
  },
}: SideNavDesktopProps) => {
  const [t] = useTranslation('sidenav');
  const isOnboarding = useOnboarding();
  const {
    loading: loadingQuery,
    data,
    refetch,
  } = useQuery<GraphQLGroupFeatures>(query, {
    fetchPolicy: 'no-cache',
    variables: { token },
  });

  const [exportCenterFlag, loadingExportCenterFlag] = useFeature(
    FLAGS.ANALYTICS_EXPORT_CENTER,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: refetch need to be called on renderId change
  useEffect(() => {
    refetch();
  }, [renderId, refetch]);

  const onChangeWorkspace = (workspaceId: number) => {
    apiClient.user
      .switchWorkspace(token || '', { workspaceId })
      .then((response) => {
        clearFilters();
        switchToken(
          response.token,
          response.id,
          response.admin,
          response.adminOfOrganization,
          response.workspaceId,
          response.group,
          response.email,
        );
      })
      .catch(() => {});
  };
  const customLogoUrl = data?.viewer?.group?.featureManagement?.customLogoUrl;
  const alt = `${customLogoUrl && data ? data.viewer?.group?.name : ''} logo`;

  const loading = loadingExportCenterFlag || loadingQuery || !data;

  return (
    <layout.SideNavContainer>
      <LogoContainer>
        <Link to="/">
          {customLogoUrl ? (
            <CustomLogo src={customLogoUrl} alt={alt} />
          ) : (
            <HibooLogo>
              <HibooLogoLarge />
            </HibooLogo>
          )}
        </Link>
      </LogoContainer>
      <WorkspacePicker
        loading={loading}
        currentWorkspace={data?.viewer?.group}
        workspaces={data?.viewer?.workspaces || []}
        onChange={onChangeWorkspace}
        flat={props.appSyncOnly}
      />
      <SideTopContainer>
        {isOnboarding && (
          <NavItem
            display
            icon={OnboardingIcon}
            label={t('sidenav.navigation.onboarding')}
            link="/onboarding"
            id="navigation-onboarding"
            alternate
          />
        )}
        {props.appSyncOnly === false && <GlobalSearchButton />}
      </SideTopContainer>
      <ScrollableContainer>
        <FirstNavPartContainer id="first-nav-part-container">
          <Navigation
            featureManagement={data?.viewer?.group?.featureManagement}
            loading={loading}
            noReport={props.noReport}
            tyreReportFlag={props.tyreReportFlag}
            exportCenterFlag={exportCenterFlag}
            appSyncOnly={props.appSyncOnly}
            newHomeFlag={props.newHomeFlag}
          />
        </FirstNavPartContainer>
        <BottomNavigation displayDestinations={!!props.destinationsFlag} />
      </ScrollableContainer>
      <Footer />
    </layout.SideNavContainer>
  );
};

const mapStateToProps = (state: Store) => ({
  token: state.user.token,
  renderId: state.sidenav.get('renderId'),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  switchToken: (
    token: string,
    userId: number,
    admin: boolean,
    adminOfOrganization: boolean,
    workspaceId: number,
    group: string,
    email: string,
  ) => {
    return dispatch(
      userSwitchToken(
        token,
        userId,
        admin,
        adminOfOrganization,
        workspaceId,
        group,
        email,
      ),
    );
  },
});

export const SideNavDesktop = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideNavDesktopComponent);
