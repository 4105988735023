import type { TFunction } from 'i18next';
import { ClickToViewDrawer } from '../../common/ClickToViewDrawer/ClickToViewDrawer';

export type TooltipLine = {
  label: string;
  value: string | number;
};

export const HighchartsTooltipV2 = (
  tooltipData: TooltipLine[],
  t: TFunction,
  hasDrawer = true,
) => {
  const flexRow = (children: string) => {
    return `<div style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;display:flex; flex-direction:row; justify-content: space-between;
    ">${children}</div>`;
  };

  let labels = '';
  let values = '';
  for (const line of tooltipData) {
    labels += `<div>${line.label}</div>`;
    values += `<div>${line.value}</div>`;
  }

  const labelCol = `<div style="display:flex; flex-direction:column">${labels}</div>`;
  const valueCol = `<div style="margin-left: 4px; display:flex; flex-direction:column;">${values}</div>`;

  return flexRow(labelCol + valueCol) + (hasDrawer ? ClickToViewDrawer(t) : '');
};
