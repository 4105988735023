import { NameCell as GenericNameCell } from 'modules/tableV7';

type NameCellProps = {
  name?: string | null;
  make?: string | null;
  assetId: number;
  openLinkInNewWindow?: boolean;
  extraUrlParams?: Record<string, string>;
};

export const NameCell = ({
  name,
  make,
  assetId,
  openLinkInNewWindow = false,
  extraUrlParams,
}: NameCellProps) => {
  const assetLink = `/assets/${assetId}/overview`;

  return (
    <GenericNameCell
      title={name || 'N/A'}
      subtitle={make || ''}
      link={assetLink}
      openLinkInNewWindow={openLinkInNewWindow}
      extraUrlParams={extraUrlParams}
    />
  );
};
