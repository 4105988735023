import colors from 'constants/colors';
import { SortDirection } from 'graphql/types';
import { Spinner } from 'modules/common-ui';
import { AssetRow } from 'modules/common-ui/components/AssetRow/AssetRow';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFilteredAssetsWithFleetSegmentQuery } from '../../gql.generated';
import type { FleetSegmentForm } from '../../types';
import {
  selectedFiltersToObject,
  transformFiltersToGqlFilters,
} from '../../utils';

const Container = styled.div`
  background: ${colors.gray50};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 22px;
  overflow: auto;
  gap: 16px;
`;

const NoFilter = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 8px 0;
  color: ${colors.gray600};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const AssetCount = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 8px 0;
  color: ${colors.gray850};
`;

const AssetList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 10px;
`;

type MatchingAssetListProps = {
  fleetSegment: FleetSegmentForm;
};

export const MatchingAssetList = ({ fleetSegment }: MatchingAssetListProps) => {
  const { t } = useTranslation('fleetSegment');
  const assetFilters = useMemo(() => {
    if (!fleetSegment) return null;
    return transformFiltersToGqlFilters(
      selectedFiltersToObject(fleetSegment.filters || []),
    );
  }, [fleetSegment]);

  const { loading, data } = useFilteredAssetsWithFleetSegmentQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 10000,
      offset: 0,
      sort: { field: 'name', order: SortDirection.Asc },
      assetFilters,
    },
    skip: !assetFilters || Object.keys(assetFilters).length === 0,
  });

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  const hasNoFilters =
    !fleetSegment ||
    (fleetSegment.filters || []).filter((f) => f.value && f.value.length > 0)
      .length === 0;

  const assetsRows = data?.viewer?.workspace?.inventoryV2?.rows || [];

  return (
    <Container>
      <AssetCount>
        {t('common.assetAvailable', { count: assetsRows.length })}
      </AssetCount>
      {hasNoFilters ? (
        <NoFilter>{t('common.noFilter')}</NoFilter>
      ) : (
        <AssetList>
          {assetsRows.map(
            (row) => row && <AssetRow key={row.id} asset={row} />,
          )}
        </AssetList>
      )}
    </Container>
  );
};
