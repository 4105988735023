import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import { AssetFilter } from 'modules/analytics/components/AssetFilter/AssetFilter';
import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { Section } from 'modules/analytics/components/common/layout/Section/Section';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UsageHours } from './kpiViewer/UsageHours/';
import { getLast7DaysPeriod } from './kpiViewer/utils/date';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    flex-direction: column;
  }
`;

export const AnalyticsSection = () => {
  const { t } = useTranslation('newHome');
  const [appliedFilters, setAppliedFilters] = useState<AssetFilterOptions>({});
  const { since, until } = useMemo(() => getLast7DaysPeriod(), []);

  return (
    <Section
      title={t('analytics.last7days')}
      subtitle={t('analytics.subtitle')}
      actionElement={
        <AssetFilter
          appliedFilters={appliedFilters}
          onFilter={(filters) => {
            setAppliedFilters(filters);
          }}
          trackingProps={{ page: 'new-home' }}
          alignRight
        />
      }
    >
      <Container>
        <UsageHours assetFilters={appliedFilters} since={since} until={until} />
      </Container>
    </Section>
  );
};
