export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
};

/** API Accesses saved as users */
export type ApiAccess = {
  __typename?: 'APIAccess';
  admin?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  passwordClear?: Maybe<Scalars['String']['output']>;
  passwordIdentifier?: Maybe<Scalars['String']['output']>;
};

export type ActiveAssetMetadata = {
  __typename?: 'ActiveAssetMetadata';
  createdAt: Scalars['DateTime']['output'];
  status: AssetStatus;
};

/** An address part of the organization address book */
export type Address = {
  __typename?: 'Address';
  address?: Maybe<AlertAddress>;
  area?: Maybe<FeatureCollection>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  category?: Maybe<AddressCategory>;
  center?: Maybe<PointType>;
  chronology?: Maybe<PaginatedZonePresence>;
  created_at?: Maybe<Scalars['String']['output']>;
  faults?: Maybe<ZoneFaults>;
  group?: Maybe<Group>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  site?: Maybe<Site>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  workspaceIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

/** An address part of the organization address book */
export type AddressChronologyArgs = {
  filters?: InputMaybe<ZonePresenceFilters>;
  pagination: Pagination;
  period: Period;
};

/** An address part of the organization address book */
export type AddressFaultsArgs = {
  filters?: InputMaybe<ZoneFaultsFilters>;
  period: Period;
};

/** A group of addresses */
export type AddressCategory = {
  __typename?: 'AddressCategory';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
};

export type AddressGeometry = {
  __typename?: 'AddressGeometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Alert = {
  __typename?: 'Alert';
  allAssets: Scalars['Boolean']['output'];
  assetFilteringMode?: Maybe<AssetFilteringMode>;
  assets: Array<Asset>;
  createdAt: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  data?: Maybe<AlertData>;
  group?: Maybe<Group>;
  id: Scalars['Int']['output'];
  invalidReason?: Maybe<AlertInvalidReason>;
  isValid: Scalars['Boolean']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notify?: Maybe<AlertNotifyType>;
  segment?: Maybe<FleetSegment>;
  timeRange?: Maybe<AlertTimeRange>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** activity alert data */
export type AlertActivityData = {
  __typename?: 'AlertActivityData';
  nbHours?: Maybe<Scalars['Float']['output']>;
  nbMinutes?: Maybe<Scalars['Float']['output']>;
};

/** Activity alert data */
export type AlertActivityDataInput = {
  nbHours?: InputMaybe<Scalars['Float']['input']>;
  nbMinutes?: InputMaybe<Scalars['Float']['input']>;
};

/** A GeoJSON address */
export type AlertAddress = {
  __typename?: 'AlertAddress';
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  created_at?: Maybe<Scalars['String']['output']>;
  geometry?: Maybe<AddressGeometry>;
  place_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** A GeoJSON address */
export type AlertAddressInput = {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  created_at?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<AlertAddressInputGeometry>;
  place_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AlertAddressInputGeometry = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  interpolated?: InputMaybe<Scalars['Boolean']['input']>;
  omitted?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AlertData =
  | AlertActivityData
  | AlertFaultData
  | AlertGeofencingData
  | AlertHourmeterThresholdData
  | AlertOdometerThresholdData
  | AlertUnderuseData;

/** Fault alert data */
export type AlertFaultData = {
  __typename?: 'AlertFaultData';
  levels?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

/** Fault alert input data */
export type AlertFaultDataInput = {
  levels?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** geofencing alert data */
export type AlertGeofencingData = {
  __typename?: 'AlertGeofencingData';
  address?: Maybe<AlertAddress>;
  area?: Maybe<FeatureCollection>;
  linkedZone?: Maybe<Zone>;
  when?: Maybe<AlertWhenType>;
};

/** geofencing alert data */
export type AlertGeofencingDataInput = {
  address?: InputMaybe<AlertAddressInput>;
  area?: InputMaybe<FeatureCollectionInput>;
  linkedZone?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<AlertWhenInput>;
};

/** hourmeter threshold alert data */
export type AlertHourmeterThresholdData = {
  __typename?: 'AlertHourmeterThresholdData';
  earlyAlert?: Maybe<Scalars['Boolean']['output']>;
  nbHours?: Maybe<Scalars['Float']['output']>;
};

/** hourmeter threshold alert data */
export type AlertHourmeterThresholdDataInput = {
  earlyAlert?: InputMaybe<Scalars['Boolean']['input']>;
  nbHours?: InputMaybe<Scalars['Float']['input']>;
};

export enum AlertInvalidReason {
  NoAssets = 'NO_ASSETS',
  SegmentDeleted = 'SEGMENT_DELETED',
}

export enum AlertKind {
  Fault = 'fault',
  Geofencing = 'geofencing',
  HourmeterThreshold = 'hourmeter_threshold',
  OdometerThreshold = 'odometer_threshold',
  Overuse = 'overuse',
  Underuse = 'underuse',
}

/** notifications subscriptions */
export type AlertNotifyInput = {
  schedule?: InputMaybe<AlertNotifySchedule>;
  users?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum AlertNotifySchedule {
  Daily = 'daily',
  Event = 'event',
  Hourly = 'hourly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Weekly = 'weekly',
}

/** notifications subscriptions */
export type AlertNotifyType = {
  __typename?: 'AlertNotifyType';
  schedule?: Maybe<AlertNotifySchedule>;
  users: Array<User>;
};

/** odometer threshold alert data */
export type AlertOdometerThresholdData = {
  __typename?: 'AlertOdometerThresholdData';
  earlyAlert?: Maybe<Scalars['Boolean']['output']>;
  nbKilometers?: Maybe<Scalars['Float']['output']>;
};

/** odometer threshold alert data */
export type AlertOdometerThresholdDataInput = {
  earlyAlert?: InputMaybe<Scalars['Boolean']['input']>;
  nbKilometers?: InputMaybe<Scalars['Float']['input']>;
};

export type AlertTimeRange = {
  __typename?: 'AlertTimeRange';
  blackList: Array<AlertTimeRangeItem>;
  whiteList: Array<AlertTimeRangeItem>;
};

export type AlertTimeRangeInput = {
  blackList?: InputMaybe<Array<InputMaybe<AlertTimeRangeItemInput>>>;
  whiteList?: InputMaybe<Array<InputMaybe<AlertTimeRangeItemInput>>>;
};

export type AlertTimeRangeItem = {
  __typename?: 'AlertTimeRangeItem';
  days: Array<Scalars['Int']['output']>;
  timeFrom?: Maybe<Scalars['Float']['output']>;
  timeTo?: Maybe<Scalars['Float']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type AlertTimeRangeItemInput = {
  days?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  timeFrom?: InputMaybe<Scalars['Float']['input']>;
  timeTo?: InputMaybe<Scalars['Float']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type AlertTriggered = {
  __typename?: 'AlertTriggered';
  alert: Alert;
  assetInOrganizationId: Scalars['Int']['output'];
  completionDate: Scalars['DateTime']['output'];
  data: AlertTriggeredData;
  id: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
};

export type AlertTriggeredData =
  | AlertTriggeredFaultData
  | AlertTriggeredGeofencingData
  | AlertTriggeredHourmeterThresholdData
  | AlertTriggeredOdometerThresholdData
  | AlertTriggeredOveruseData
  | AlertTriggeredUnderuseData
  | EarlyAlertTriggeredHourmeterThresholdData
  | EarlyAlertTriggeredOdometerThresholdData;

/** faults count by severity */
export type AlertTriggeredFaultCountBySeverity = {
  __typename?: 'AlertTriggeredFaultCountBySeverity';
  critical?: Maybe<Scalars['Int']['output']>;
  high?: Maybe<Scalars['Int']['output']>;
  low?: Maybe<Scalars['Int']['output']>;
  medium?: Maybe<Scalars['Int']['output']>;
  unknown?: Maybe<Scalars['Int']['output']>;
};

/** fault alertTriggered data */
export type AlertTriggeredFaultData = {
  __typename?: 'AlertTriggeredFaultData';
  countBySeverity: AlertTriggeredFaultCountBySeverity;
  lastHourmeter?: Maybe<Scalars['Float']['output']>;
  lastPosition?: Maybe<AlertTriggeredFaultPosition>;
  since: Scalars['DateTime']['output'];
  until: Scalars['DateTime']['output'];
};

/** fault alertTriggered position */
export type AlertTriggeredFaultPosition = {
  __typename?: 'AlertTriggeredFaultPosition';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  postalAddress?: Maybe<Scalars['String']['output']>;
};

/** geofencing alertTriggered data */
export type AlertTriggeredGeofencingData = {
  __typename?: 'AlertTriggeredGeofencingData';
  stopDate?: Maybe<Scalars['String']['output']>;
  triggerDate: Scalars['String']['output'];
  triggeredRule: AlertTriggeredGeofencingTriggeredRule;
};

export enum AlertTriggeredGeofencingTriggeredRule {
  In = 'in',
  Out = 'out',
}

/** hourmeter threshold alertTriggered data */
export type AlertTriggeredHourmeterThresholdData = {
  __typename?: 'AlertTriggeredHourmeterThresholdData';
  cumulativeUsageHoursValue: Scalars['Float']['output'];
  reachedAlertThreshold: Scalars['Float']['output'];
};

/** odometer threshold alertTriggered data */
export type AlertTriggeredOdometerThresholdData = {
  __typename?: 'AlertTriggeredOdometerThresholdData';
  cumulativeDistanceValue: Scalars['Float']['output'];
  reachedAlertThreshold: Scalars['Float']['output'];
};

/** overuse alertTriggered data */
export type AlertTriggeredOveruseData = {
  __typename?: 'AlertTriggeredOveruseData';
  usageHours: Scalars['Float']['output'];
};

/** underuse alertTriggered data */
export type AlertTriggeredUnderuseData = {
  __typename?: 'AlertTriggeredUnderuseData';
  usageHours: Scalars['Float']['output'];
};

/** underuse alert data */
export type AlertUnderuseData = {
  __typename?: 'AlertUnderuseData';
  nbHours?: Maybe<Scalars['Float']['output']>;
  nbMinutes?: Maybe<Scalars['Float']['output']>;
};

/** Underuse alert data */
export type AlertUnderuseDataInput = {
  nbHours?: InputMaybe<Scalars['Float']['input']>;
  nbMinutes?: InputMaybe<Scalars['Float']['input']>;
};

/** Store when an alert should be triggered */
export type AlertWhenInput = {
  in?: InputMaybe<Scalars['Boolean']['input']>;
  out?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Store when an alert should be triggered */
export type AlertWhenType = {
  __typename?: 'AlertWhenType';
  in?: Maybe<Scalars['Boolean']['output']>;
  out?: Maybe<Scalars['Boolean']['output']>;
};

/** Fleet data daly fuel */
export type AnalyticsFleetDataDailyFuelItem = {
  __typename?: 'AnalyticsFleetDataDailyFuelItem';
  date: Scalars['Date']['output'];
  fuelLiters: Scalars['Float']['output'];
};

/** Fleet data statistics */
export type AnalyticsFleetDataStatistics = {
  __typename?: 'AnalyticsFleetDataStatistics';
  co2Kilograms?: Maybe<ValueAndAssetsCount>;
  fuelEfficiency?: Maybe<ValueAndAssetsCount>;
  fuelLiters?: Maybe<ValueAndAssetsCount>;
  idleHours?: Maybe<ValueAndAssetsCount>;
  idlePercent?: Maybe<ValueAndAssetsCount>;
  kilometers?: Maybe<ValueAndAssetsCount>;
  usageHours?: Maybe<ValueAndAssetsCount>;
};

export type ArchiveAssetResult = {
  __typename?: 'ArchiveAssetResult';
  id: Scalars['Int']['output'];
};

export type ArchivedAssetMetadata = {
  __typename?: 'ArchivedAssetMetadata';
  archiveComment: Scalars['String']['output'];
  archivedAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  status: AssetStatus;
};

export type Asset = {
  __typename?: 'Asset';
  activeAlertCount?: Maybe<Scalars['Int']['output']>;
  addedOnWorkspace?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use metadata.archiveComment instead */
  archiveComment?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use metadata.archivedAt instead */
  archiveDate?: Maybe<Scalars['String']['output']>;
  availableDataKinds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  averageEngineLoadLast24History?: Maybe<
    Array<Maybe<AssetAverageEngineLoadLast24>>
  >;
  averageHourlyConsumption?: Maybe<AssetHourlyConsumption>;
  batteryLevelHistory?: Maybe<Array<Maybe<AssetBatteryLevel>>>;
  batteryVoltageHistory?: Maybe<Array<Maybe<AssetBatteryVoltage>>>;
  category?: Maybe<Category>;
  chronology?: Maybe<PaginatedZonePresenceForAsset>;
  createdAt?: Maybe<Scalars['String']['output']>;
  cumulativeActiveRegenerationHourHistory?: Maybe<
    Array<Maybe<AssetCumulativeActiveRegenerationHour>>
  >;
  cumulativeDistanceHistory?: Maybe<Array<Maybe<AssetCumulativeDistance>>>;
  cumulativeFuelHistory?: Maybe<Array<Maybe<AssetCumulativeFuel>>>;
  cumulativeIdleHourHistory?: Maybe<Array<Maybe<AssetCumulativeIdleHour>>>;
  cumulativeLoadCountHistory?: Maybe<Array<Maybe<AssetCumulativeLoadCount>>>;
  cumulativePayloadHistory?: Maybe<Array<Maybe<AssetCumulativePayload>>>;
  cumulativePowerTakeOffHourHistory?: Maybe<
    Array<Maybe<AssetCumulativePowerTakeOffHour>>
  >;
  cumulativeUsageHourHistory?: Maybe<Array<Maybe<AssetCumulativeUsageHour>>>;
  customFieldsV2: Array<AssetCustomFieldV2Type>;
  dailyAllUsages?: Maybe<Array<Maybe<DailyAllUsage>>>;
  dailyCO2History?: Maybe<Array<Maybe<AssetDailyCo2>>>;
  dailyFuelHistory?: Maybe<Array<Maybe<AssetDailyFuelType>>>;
  dailyHourHistory?: Maybe<Array<Maybe<AssetDailyHour>>>;
  dataKindData?: Maybe<DataKindDataType>;
  dataKinds?: Maybe<Array<Maybe<AssetDataKind>>>;
  dataSources?: Maybe<Array<Maybe<DataSource>>>;
  defRemainingHistory?: Maybe<Array<Maybe<AssetDefRemainingType>>>;
  energyType?: Maybe<EnergyTypeType>;
  energyTypeId?: Maybe<Scalars['Int']['output']>;
  engineConditionHistory?: Maybe<Array<Maybe<AssetEngineCondition>>>;
  engineCoolantTemperatureHistory?: Maybe<
    Array<Maybe<AssetEngineCoolantTemperature>>
  >;
  engineLoadFactorHistory?: Maybe<Array<Maybe<AssetEngineLoadFactor>>>;
  enterprise?: Maybe<Scalars['String']['output']>;
  enterpriseEntity?: Maybe<Enterprise>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  faultCodeHistory?: Maybe<Array<Maybe<AssetFaultCode>>>;
  faultSeverityCounts?: Maybe<GetAssetInOrgSeverityCountsType>;
  faultsWithTranslation?: Maybe<PaginatedAssetFaultWithTranslationItemType>;
  favorite: Scalars['Boolean']['output'];
  filesCount: Scalars['Int']['output'];
  filesPaginated?: Maybe<PaginatedAssetInOrganizationFileType>;
  fuelRemainingHistory?: Maybe<Array<Maybe<AssetFuelRemaining>>>;
  getAssetTyreHistory?: Maybe<PaginatedTyreHistoryRowType>;
  getLastDataDateByDataKinds: Array<LastDataDateByDataKindGqlType>;
  hasTrips?: Maybe<Scalars['Boolean']['output']>;
  hasTyreSnapshot?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  inProgressOrLatestSharing?: Maybe<InProgressOrLatestSharing>;
  issue?: Maybe<AssetInOrganizationIssue>;
  issues?: Maybe<PaginatedAssetInOrganizationIssue>;
  latestAverageEngineLoadLast24?: Maybe<AssetAverageEngineLoadLast24>;
  latestBatteryLevel?: Maybe<AssetBatteryLevel>;
  latestCumulativeActiveRegenerationHour?: Maybe<AssetCumulativeActiveRegenerationHour>;
  latestCumulativeDistance?: Maybe<AssetCumulativeDistance>;
  latestCumulativeFuel?: Maybe<AssetCumulativeFuel>;
  latestCumulativeIdleHour?: Maybe<AssetCumulativeIdleHour>;
  latestCumulativeLoadCount?: Maybe<AssetCumulativeLoadCount>;
  latestCumulativePayload?: Maybe<AssetCumulativePayload>;
  latestCumulativePowerTakeOffHour?: Maybe<AssetCumulativePowerTakeOffHour>;
  latestCumulativeUsageHour?: Maybe<AssetCumulativeUsageHour>;
  latestDEFRemaining?: Maybe<AssetDefRemainingType>;
  latestDataDate?: Maybe<Scalars['DateTime']['output']>;
  latestDeviceBatteryLevel?: Maybe<AssetDeviceBatteryLevel>;
  latestEngineCondition?: Maybe<AssetEngineCondition>;
  latestFuelRemaining?: Maybe<AssetFuelRemaining>;
  latestPeakDailySpeed?: Maybe<AssetPeakDailySpeed>;
  latestPosition?: Maybe<AssetPosition>;
  latestTriggeredAlert?: Maybe<AlertTriggered>;
  linkedWorkspaces?: Maybe<Array<Maybe<AssetWorkspaceType>>>;
  listAssetAvailableTyres?: Maybe<Array<Maybe<TyreSnapshotPositionType>>>;
  listAssetTyreLastSnapshots?: Maybe<Array<Maybe<TyreLastSnapshotType>>>;
  make?: Maybe<Scalars['String']['output']>;
  metadata: AssetMetadata;
  model?: Maybe<Scalars['String']['output']>;
  modelManufacturer?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use owned field instead. This field is deprecated and will be removed in the future. */
  organizationId?: Maybe<Scalars['String']['output']>;
  owned: Scalars['Boolean']['output'];
  peakDailySpeedHistory?: Maybe<Array<Maybe<AssetPeakDailySpeed>>>;
  positionsPaginated?: Maybe<PaginatedAssetPosition>;
  publicURL?: Maybe<Scalars['String']['output']>;
  sensorBooleanHistory?: Maybe<Array<Maybe<AssetSensorBoolean>>>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  sharingInformations?: Maybe<AssetSharingInformations>;
  source?: Maybe<Scalars['String']['output']>;
  suggestedCategories?: Maybe<Array<Maybe<AssetInOrganizationCategory>>>;
  temperatureHistory2?: Maybe<Array<Maybe<AssetTemperature>>>;
  triggeredAlerts?: Maybe<Array<Maybe<AlertTriggered>>>;
  trips?: Maybe<Array<Maybe<Trip>>>;
  workingDaysCount?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type AssetAverageEngineLoadLast24HistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetAverageHourlyConsumptionArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetBatteryLevelHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetBatteryVoltageHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetChronologyArgs = {
  filters?: InputMaybe<ZoneFilters>;
  pagination: Pagination;
  period: Period;
};

export type AssetCumulativeActiveRegenerationHourHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativeDistanceHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativeFuelHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativeIdleHourHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativeLoadCountHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativePayloadHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativePowerTakeOffHourHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetCumulativeUsageHourHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetDailyAllUsagesArgs = {
  includeSuspicious?: InputMaybe<Scalars['Boolean']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};

export type AssetDailyCo2HistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetDailyFuelHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetDailyHourHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetDataKindDataArgs = {
  dataKind: DataKindCode;
  period: Period;
};

export type AssetDefRemainingHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetEngineConditionHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetEngineCoolantTemperatureHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetEngineLoadFactorHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetFaultCodeHistoryArgs = {
  filterFaultSeverity?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetFaultSeverityCountsArgs = {
  period: FaultPeriod;
  severities?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AssetFaultsWithTranslationArgs = {
  pagination?: InputMaybe<Pagination>;
  period?: InputMaybe<FaultPeriod>;
  search?: InputMaybe<Scalars['String']['input']>;
  severities?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sortBy?: InputMaybe<Array<InputMaybe<SortByField>>>;
};

export type AssetFilesCountArgs = {
  onlyShareableFiles: Scalars['Boolean']['input'];
};

export type AssetFilesPaginatedArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<AssetInOrganizationFileSortBy>;
};

export type AssetFuelRemainingHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetGetAssetTyreHistoryArgs = {
  pagination?: InputMaybe<Pagination>;
  period?: InputMaybe<Period>;
};

export type AssetIssueArgs = {
  id: Scalars['ID']['input'];
};

export type AssetIssuesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestAverageEngineLoadLast24Args = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestBatteryLevelArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativeActiveRegenerationHourArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativeDistanceArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativeFuelArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativeIdleHourArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativeLoadCountArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativePayloadArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativePowerTakeOffHourArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestCumulativeUsageHourArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestDefRemainingArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestDeviceBatteryLevelArgs = {
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AssetLatestEngineConditionArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestFuelRemainingArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLatestPeakDailySpeedArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssetPeakDailySpeedHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetPositionsPaginatedArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetSensorBooleanHistoryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetSuggestedCategoriesArgs = {
  numberOfSuggestions?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetTemperatureHistory2Args = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type AssetTriggeredAlertsArgs = {
  alertKinds: Array<InputMaybe<Scalars['String']['input']>>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type AssetTripsArgs = {
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};

export type AssetWorkingDaysCountArgs = {
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** An asset average engine load in the last 24 hours */
export type AssetAverageEngineLoadLast24 = {
  __typename?: 'AssetAverageEngineLoadLast24';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
};

/** An asset battery level */
export type AssetBatteryLevel = {
  __typename?: 'AssetBatteryLevel';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
};

/** An asset battery voltage */
export type AssetBatteryVoltage = {
  __typename?: 'AssetBatteryVoltage';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  volts: Scalars['Float']['output'];
};

/** A asset category search result object type */
export type AssetCategorySearchResult = {
  __typename?: 'AssetCategorySearchResult';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** An asset cumulative active regeneration hour */
export type AssetCumulativeActiveRegenerationHour = {
  __typename?: 'AssetCumulativeActiveRegenerationHour';
  date: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

/** An asset cumulative distance */
export type AssetCumulativeDistance = {
  __typename?: 'AssetCumulativeDistance';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kilometers: Scalars['Float']['output'];
};

/** An asset cumulative fuel */
export type AssetCumulativeFuel = {
  __typename?: 'AssetCumulativeFuel';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  liters: Scalars['Float']['output'];
};

/** An asset cumulative idle hours */
export type AssetCumulativeIdleHour = {
  __typename?: 'AssetCumulativeIdleHour';
  date: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

/** An asset cumulative load count */
export type AssetCumulativeLoadCount = {
  __typename?: 'AssetCumulativeLoadCount';
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** An asset cumulative payload */
export type AssetCumulativePayload = {
  __typename?: 'AssetCumulativePayload';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tons: Scalars['Float']['output'];
};

/** An asset cumulative power take off hours */
export type AssetCumulativePowerTakeOffHour = {
  __typename?: 'AssetCumulativePowerTakeOffHour';
  date: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

/** An asset cumulative usage hours */
export type AssetCumulativeUsageHour = {
  __typename?: 'AssetCumulativeUsageHour';
  date: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

/** Custom Fields for Asset */
export type AssetCustomFieldV2Type = {
  __typename?: 'AssetCustomFieldV2Type';
  assetId: Scalars['Int']['output'];
  customField: CustomFieldV2Type;
  customFieldId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  value: Scalars['JSON']['output'];
};

/** An asset DEF Remaining */
export type AssetDefRemainingType = {
  __typename?: 'AssetDEFRemainingType';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
  tankCapacityLiters?: Maybe<Scalars['Float']['output']>;
};

/** An asset daily co2 */
export type AssetDailyCo2 = {
  __typename?: 'AssetDailyCO2';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kilograms: Scalars['Float']['output'];
};

/** An asset daily fuel */
export type AssetDailyFuelType = {
  __typename?: 'AssetDailyFuelType';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  liters: Scalars['Float']['output'];
};

/** An asset daily hour */
export type AssetDailyHour = {
  __typename?: 'AssetDailyHour';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idleHours?: Maybe<Scalars['Float']['output']>;
  operatingHours?: Maybe<Scalars['Float']['output']>;
  usageHours: Scalars['Float']['output'];
};

/** asset data kind */
export type AssetDataKind = {
  __typename?: 'AssetDataKind';
  assetId?: Maybe<Scalars['Int']['output']>;
  dataKind?: Maybe<DataKind>;
  dataSource?: Maybe<DataSource>;
  lastDataDate?: Maybe<Scalars['String']['output']>;
};

/** An asset device battery level */
export type AssetDeviceBatteryLevel = {
  __typename?: 'AssetDeviceBatteryLevel';
  date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  indicator: AssetDeviceBatteryLevelIndicator;
  percent: Scalars['Float']['output'];
};

/** An asset device battery level indicator */
export enum AssetDeviceBatteryLevelIndicator {
  Green = 'GREEN',
  Orange = 'ORANGE',
  Red = 'RED',
}

/** An asset engine condition */
export type AssetEngineCondition = {
  __typename?: 'AssetEngineCondition';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  running: Scalars['Boolean']['output'];
};

/** An asset engineCoolantTemperature */
export type AssetEngineCoolantTemperature = {
  __typename?: 'AssetEngineCoolantTemperature';
  celsius: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** An asset engineLoadFactor */
export type AssetEngineLoadFactor = {
  __typename?: 'AssetEngineLoadFactor';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
};

/** An asset fault code */
export type AssetFaultCode = {
  __typename?: 'AssetFaultCode';
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  levelLabel?: Maybe<Scalars['String']['output']>;
};

export type AssetFaultSeverityCountsItemType = {
  __typename?: 'AssetFaultSeverityCountsItemType';
  asset: Asset;
  critical: Scalars['Int']['output'];
  high: Scalars['Int']['output'];
  low: Scalars['Int']['output'];
  medium: Scalars['Int']['output'];
  unknown: Scalars['Int']['output'];
};

export type AssetFaultWithTranslationItemType = {
  __typename?: 'AssetFaultWithTranslationItemType';
  assetInOrganizationId: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  translation?: Maybe<Scalars['String']['output']>;
};

export enum AssetFilteringMode {
  AllAssets = 'allAssets',
  AssetList = 'assetList',
  Segment = 'segment',
}

export type AssetFilters = {
  __typename?: 'AssetFilters';
  categories?: Maybe<Array<Scalars['Int']['output']>>;
  customfields?: Maybe<Array<CustomField>>;
  enterprises?: Maybe<Array<Scalars['String']['output']>>;
  favorite?: Maybe<Scalars['Boolean']['output']>;
  makers?: Maybe<Array<Scalars['String']['output']>>;
  models?: Maybe<Array<Scalars['String']['output']>>;
  names?: Maybe<Array<Scalars['String']['output']>>;
  sources?: Maybe<Array<Scalars['String']['output']>>;
  zones?: Maybe<Array<Scalars['String']['output']>>;
};

export type AssetFiltersInput = {
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  customfields?: InputMaybe<Array<CustomFieldInput>>;
  enterprises?: InputMaybe<Array<Scalars['String']['input']>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  makers?: InputMaybe<Array<Scalars['String']['input']>>;
  models?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  zones?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** An asset fuel remaining */
export type AssetFuelRemaining = {
  __typename?: 'AssetFuelRemaining';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
};

/** An average hourly consumption between 2 dates */
export type AssetHourlyConsumption = {
  __typename?: 'AssetHourlyConsumption';
  litersPerHour: Scalars['Float']['output'];
};

export type AssetInOrganizationCategory = {
  __typename?: 'AssetInOrganizationCategory';
  color: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AssetInOrganizationFileSortBy = {
  field: AssetInOrganizationFileSortField;
  order: SortOrder;
};

export enum AssetInOrganizationFileSortField {
  Name = 'name',
}

export type AssetInOrganizationFileType = {
  __typename?: 'AssetInOrganizationFileType';
  expirationDate?: Maybe<Scalars['Date']['output']>;
  fileInfos: HibooFileType;
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shareable: Scalars['Boolean']['output'];
  sharedBy?: Maybe<SharedAssetFileSource>;
  uploadedBy?: Maybe<User>;
};

export type AssetInOrganizationFleetData = {
  __typename?: 'AssetInOrganizationFleetData';
  activeDays: Scalars['Int']['output'];
  asset: Asset;
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  fuelPer100Km?: Maybe<Scalars['Float']['output']>;
  fuelPerHours?: Maybe<Scalars['Float']['output']>;
  idlePercent?: Maybe<Scalars['Float']['output']>;
  lastDate: Scalars['Date']['output'];
  sumCo2Kilograms?: Maybe<Scalars['Float']['output']>;
  sumFuelLiters?: Maybe<Scalars['Float']['output']>;
  sumIdleHours?: Maybe<Scalars['Float']['output']>;
  sumKilometers?: Maybe<Scalars['Float']['output']>;
  sumOperatingHours?: Maybe<Scalars['Float']['output']>;
  sumUsageHours?: Maybe<Scalars['Float']['output']>;
};

export type AssetInOrganizationFleetDataSortBy = {
  field: AssetInOrganizationFleetDataSortField;
  order: SortOrder;
};

export enum AssetInOrganizationFleetDataSortField {
  ActiveDays = 'activeDays',
  Category = 'category',
  Enterprise = 'enterprise',
  FuelPer100Km = 'fuelPer100Km',
  FuelPerHours = 'fuelPerHours',
  IdlePercent = 'idlePercent',
  LastDate = 'lastDate',
  Make = 'make',
  Model = 'model',
  Name = 'name',
  SerialNumber = 'serialNumber',
  Source = 'source',
  SumCo2Kilograms = 'sumCo2Kilograms',
  SumFuelLiters = 'sumFuelLiters',
  SumIdleHours = 'sumIdleHours',
  SumKilometers = 'sumKilometers',
  SumOperatingHours = 'sumOperatingHours',
  SumUsageHours = 'sumUsageHours',
}

export type AssetInOrganizationIssue = {
  __typename?: 'AssetInOrganizationIssue';
  activity: Array<Maybe<AssetInOrganizationIssueActivityType>>;
  assignee?: Maybe<User>;
  attachments: Array<Maybe<AssetInOrganizationIssueAttachmentType>>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isCritical: Scalars['Boolean']['output'];
  reportedBy?: Maybe<User>;
  reportedDate: Scalars['String']['output'];
  status: AssetInOrganizationIssueStatus;
  title: Scalars['String']['output'];
};

export type AssetInOrganizationIssueActivityPayloadType =
  | AssetInOrganizationIssueAssignedPayloadType
  | AssetInOrganizationIssueCreatedPayloadType
  | AssetInOrganizationIssueEditedPayloadType
  | AssetInOrganizationIssueSeverityUpdatedPayloadType
  | AssetInOrganizationIssueStatusUpdatedPayloadType;

export type AssetInOrganizationIssueActivityType = {
  __typename?: 'AssetInOrganizationIssueActivityType';
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  payload: AssetInOrganizationIssueActivityPayloadType;
  type: Scalars['String']['output'];
};

export type AssetInOrganizationIssueAssignedPayloadType = {
  __typename?: 'AssetInOrganizationIssueAssignedPayloadType';
  byUser: User;
  newAssignee?: Maybe<User>;
  previousAssignee?: Maybe<User>;
};

export type AssetInOrganizationIssueAttachmentType = {
  __typename?: 'AssetInOrganizationIssueAttachmentType';
  fileInfos: HibooFileType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uploadedBy?: Maybe<User>;
};

export type AssetInOrganizationIssueCreatedPayloadType = {
  __typename?: 'AssetInOrganizationIssueCreatedPayloadType';
  by?: Maybe<User>;
};

export type AssetInOrganizationIssueEditedPayloadType = {
  __typename?: 'AssetInOrganizationIssueEditedPayloadType';
  byUser: User;
  field: Scalars['String']['output'];
  newValue: Scalars['String']['output'];
  previousValue: Scalars['String']['output'];
};

export type AssetInOrganizationIssueSeverityUpdatedPayloadType = {
  __typename?: 'AssetInOrganizationIssueSeverityUpdatedPayloadType';
  byUser: User;
  newSeverity: Scalars['Boolean']['output'];
  previousSeverity: Scalars['Boolean']['output'];
};

export enum AssetInOrganizationIssueStatus {
  Backlog = 'backlog',
  Canceled = 'canceled',
  Done = 'done',
  InProgress = 'in_progress',
  Todo = 'todo',
}

export type AssetInOrganizationIssueStatusUpdatedPayloadType = {
  __typename?: 'AssetInOrganizationIssueStatusUpdatedPayloadType';
  byUser: User;
  newValue: Scalars['String']['output'];
  previousValue: Scalars['String']['output'];
};

export enum AssetListDataSource {
  Daily = 'daily',
  Monthly = 'monthly',
}

export type AssetMetadata = ActiveAssetMetadata | ArchivedAssetMetadata;

/** An asset peak daily speed */
export type AssetPeakDailySpeed = {
  __typename?: 'AssetPeakDailySpeed';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kilometersPerHour: Scalars['Float']['output'];
};

/** An asset position */
export type AssetPosition = {
  __typename?: 'AssetPosition';
  altitude?: Maybe<Scalars['Float']['output']>;
  date: Scalars['String']['output'];
  heading?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  nearbyAssets: Array<NearbyAsset>;
  postalAddress?: Maybe<Scalars['String']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
  zones: Array<Zone>;
};

/** An asset position */
export type AssetPositionNearbyAssetsArgs = {
  assetFilters?: InputMaybe<GraphQlAssetFilter>;
};

/** result of asset search by keyword */
export type AssetSearchResult = {
  __typename?: 'AssetSearchResult';
  category?: Maybe<AssetCategorySearchResult>;
  id: Scalars['ID']['output'];
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sharingInformations?: Maybe<AssetSharingInformations>;
  status: AssetSearchResultStatus;
};

export enum AssetSearchResultStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
}

/** An asset sensorBoolean */
export type AssetSensorBoolean = {
  __typename?: 'AssetSensorBoolean';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  state: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type AssetSharingDataFilters = {
  __typename?: 'AssetSharingDataFilters';
  sharedByOrganizations: Array<KnownOrganizationSharing>;
  sharedWithOrganizations: Array<KnownOrganizationSharing>;
  sharingReferences: Array<Scalars['String']['output']>;
  sharingStatuses: Array<SharingStatus>;
};

export type AssetSharingInformations = {
  __typename?: 'AssetSharingInformations';
  isSharingInProgress: Scalars['Boolean']['output'];
  sharedBy?: Maybe<SharedAssetOwnerOrganization>;
  sharedWith: Array<SharedAssetBeneficiaryOrganization>;
};

export enum AssetStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** An asset temperature */
export type AssetTemperature = {
  __typename?: 'AssetTemperature';
  celsius: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type AssetTyreLastSnapshotsType = {
  __typename?: 'AssetTyreLastSnapshotsType';
  asset: Asset;
  assetId?: Maybe<Scalars['Int']['output']>;
  lastDataDate?: Maybe<Scalars['String']['output']>;
  lastFaultDate?: Maybe<Scalars['String']['output']>;
  lastRiskyTyreSnapshots?: Maybe<Array<Maybe<TyreLastSnapshotType>>>;
  lastTyreSnapshots?: Maybe<Array<Maybe<TyreLastSnapshotType>>>;
};

/** workspace of an asset */
export type AssetWorkspaceType = {
  __typename?: 'AssetWorkspaceType';
  id: Scalars['Int']['output'];
  isRootWorkspace: Scalars['Boolean']['output'];
  workspaceId: Scalars['Int']['output'];
  workspaceName: Scalars['String']['output'];
};

export type AssignPublicUrl = {
  __typename?: 'AssignPublicURL';
  publicId?: Maybe<Scalars['String']['output']>;
};

/** An asset in organization category */
export type Category = {
  __typename?: 'Category';
  color: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Co2AssetPerformance = {
  __typename?: 'Co2AssetPerformance';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  categoryId?: Maybe<Scalars['Int']['output']>;
  co2Per100km?: Maybe<Co2Weight>;
  co2PerUsageHour?: Maybe<Co2Weight>;
  enterprise?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sharingInformations?: Maybe<AssetSharingInformations>;
  totalCo2Weight?: Maybe<Co2Weight>;
  totalUsageHours?: Maybe<Scalars['Float']['output']>;
};

export type Co2AssetsPerformanceSortBy = {
  field: Co2AssetsPerformanceSortField;
  order: SortOrder;
};

export enum Co2AssetsPerformanceSortField {
  Category = 'category',
  Co2Per100km = 'co2Per100km',
  Co2PerUsageHour = 'co2PerUsageHour',
  Enterprise = 'enterprise',
  Make = 'make',
  Model = 'model',
  Name = 'name',
  TotalCo2Weight = 'totalCo2Weight',
  TotalUsageHours = 'totalUsageHours',
}

export type Co2Emission = {
  __typename?: 'Co2Emission';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  fuelConsumption: Scalars['Float']['output'];
  usageHours: Scalars['Float']['output'];
  weight: Co2Weight;
};

export type Co2EmissionAsset = {
  __typename?: 'Co2EmissionAsset';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  make?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  totalCo2Weight: Co2Weight;
};

export type Co2EmissionCategory = {
  __typename?: 'Co2EmissionCategory';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  categoryId?: Maybe<Scalars['Int']['output']>;
  weight: Co2Weight;
};

export type Co2EmissionHistoryItem = {
  __typename?: 'Co2EmissionHistoryItem';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  co2Weight: Co2Weight;
  date: Scalars['String']['output'];
};

export type Co2EmissionOnPeriodItem = {
  __typename?: 'Co2EmissionOnPeriodItem';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  co2Weight?: Maybe<Co2Weight>;
  dateEnd: Scalars['Date']['output'];
  dateStart: Scalars['Date']['output'];
};

export type Co2EmissionOnPeriodItemPeriodComparison = {
  __typename?: 'Co2EmissionOnPeriodItemPeriodComparison';
  current?: Maybe<Co2EmissionOnPeriodItem>;
  previous?: Maybe<Co2EmissionOnPeriodItem>;
};

export type Co2EmissionStatistics = {
  __typename?: 'Co2EmissionStatistics';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  co2Weight: Co2Weight;
  co2WeightPer100Km?: Maybe<Co2Weight>;
  co2WeightPerHour?: Maybe<Co2Weight>;
  period: DatePeriod;
};

export type Co2EmissionStatisticsPeriodComparison = {
  __typename?: 'Co2EmissionStatisticsPeriodComparison';
  current?: Maybe<Co2EmissionStatistics>;
  previous?: Maybe<Co2EmissionStatistics>;
};

export type Co2Report = {
  __typename?: 'Co2Report';
  assetList?: Maybe<Array<Maybe<Co2EmissionAsset>>>;
  assetsPerformance?: Maybe<PaginatedCo2AssetPerformance>;
  co2EmissionStatistics?: Maybe<Co2EmissionStatisticsPeriodComparison>;
  emissionByCategory?: Maybe<Array<Maybe<Co2EmissionCategory>>>;
  emissionCurrentMonth?: Maybe<Co2EmissionOnPeriodItemPeriodComparison>;
  emissionCurrentWeek?: Maybe<Co2EmissionOnPeriodItemPeriodComparison>;
  emissionCurrentYear?: Maybe<Co2EmissionOnPeriodItemPeriodComparison>;
  emissionHistory?: Maybe<Array<Maybe<Co2EmissionHistoryItem>>>;
  emissions?: Maybe<Array<Maybe<Co2Emission>>>;
};

export type Co2ReportAssetListArgs = {
  assetFilters: ReportAssetFilter;
  dataSource: AssetListDataSource;
  period: Period;
};

export type Co2ReportAssetsPerformanceArgs = {
  assetFilters: ReportAssetFilter;
  pagination: Pagination;
  period: Period;
  sortBy: Co2AssetsPerformanceSortBy;
};

export type Co2ReportCo2EmissionStatisticsArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
};

export type Co2ReportEmissionByCategoryArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
};

export type Co2ReportEmissionCurrentMonthArgs = {
  assetFilters: ReportAssetFilter;
};

export type Co2ReportEmissionCurrentWeekArgs = {
  assetFilters: ReportAssetFilter;
};

export type Co2ReportEmissionCurrentYearArgs = {
  assetFilters: ReportAssetFilter;
};

export type Co2ReportEmissionHistoryArgs = {
  assetFilters: ReportAssetFilter;
  periodScale: PeriodScale;
};

export type Co2ReportEmissionsArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
  periodScale: PeriodScale;
};

export type Co2Weight = {
  __typename?: 'Co2Weight';
  unit: Co2WeightUnit;
  value: Scalars['Float']['output'];
};

export enum Co2WeightUnit {
  Kg = 'kg',
  T = 't',
}

export type Coordinates = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type CurrentMonthIdleRate = {
  __typename?: 'CurrentMonthIdleRate';
  current?: Maybe<IdleRate>;
  previous?: Maybe<IdleRate>;
};

export type CurrentWeekIdleRate = {
  __typename?: 'CurrentWeekIdleRate';
  current?: Maybe<IdleRate>;
  previous?: Maybe<IdleRate>;
};

export type CurrentYearIdleRate = {
  __typename?: 'CurrentYearIdleRate';
  current?: Maybe<IdleRate>;
  previous?: Maybe<IdleRate>;
};

export type CustomField = {
  __typename?: 'CustomField';
  id: Scalars['Int']['output'];
  values: Array<Scalars['String']['output']>;
};

export type CustomFieldInput = {
  id: Scalars['Int']['input'];
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CustomFieldType = {
  id?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Custom Fields for Asset */
export type CustomFieldV2Type = {
  __typename?: 'CustomFieldV2Type';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  format: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  meta: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  workspaces: Array<Group>;
};

/** All prismed daily usage data */
export type DailyAllUsage = {
  __typename?: 'DailyAllUsage';
  DEFRemainingRatio?: Maybe<AssetDefRemainingType>;
  assetId?: Maybe<Scalars['Int']['output']>;
  averageDailyEngineLoadFactor?: Maybe<Scalars['Float']['output']>;
  co2?: Maybe<Scalars['Float']['output']>;
  cumulativeActiveRegeneration?: Maybe<AssetCumulativeActiveRegenerationHour>;
  cumulativeDistance?: Maybe<AssetCumulativeDistance>;
  cumulativeFuel?: Maybe<AssetCumulativeFuel>;
  cumulativeIdleHours?: Maybe<Scalars['Float']['output']>;
  cumulativeLoad?: Maybe<AssetCumulativeLoadCount>;
  cumulativePayloadTotal?: Maybe<AssetCumulativePayload>;
  cumulativePowerTakeOff?: Maybe<AssetCumulativePowerTakeOffHour>;
  cumulativeUsage?: Maybe<AssetCumulativeUsageHour>;
  cumulativeUsageHours?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  fuelUsed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idleHours?: Maybe<Scalars['Float']['output']>;
  idleHoursPercent?: Maybe<Scalars['Float']['output']>;
  odometer?: Maybe<Scalars['Float']['output']>;
  operatingHours?: Maybe<Scalars['Float']['output']>;
  operatingHoursPercent?: Maybe<Scalars['Float']['output']>;
  peakDailySpeed?: Maybe<AssetPeakDailySpeed>;
  usageHours?: Maybe<Scalars['Float']['output']>;
};

/** Statistics for prismed summed daily usage data */
export type DailyStatisticsType = {
  __typename?: 'DailyStatisticsType';
  co2?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  fuelUsed?: Maybe<Scalars['Float']['output']>;
  idleHours?: Maybe<Scalars['Float']['output']>;
};

/** Statistics for prismed daily usage data */
export type DailyUsageStatisticsType = {
  __typename?: 'DailyUsageStatisticsType';
  co2Sum?: Maybe<Scalars['Float']['output']>;
  dailyStats?: Maybe<Array<Maybe<DailyStatisticsType>>>;
  distanceSum?: Maybe<Scalars['Float']['output']>;
  fuelUsedSum?: Maybe<Scalars['Float']['output']>;
  globalIdlePercent?: Maybe<Scalars['Float']['output']>;
  globalLitersPerHour?: Maybe<Scalars['Float']['output']>;
  idleHoursSum?: Maybe<Scalars['Float']['output']>;
  operatingHoursSum?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  usageHoursSum?: Maybe<Scalars['Float']['output']>;
};

/** Dashboard per workspace */
export type Dashboard = {
  __typename?: 'Dashboard';
  labelKey?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Data kinds */
export type DataKind = {
  __typename?: 'DataKind';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum DataKindCode {
  Activity = 'activity',
  ActivityDistance = 'activity_distance',
  ActivityFuel = 'activity_fuel',
  ActivityIdleHours = 'activity_idle_hours',
  ActivityOperatingHours = 'activity_operating_hours',
  ActivityPeriodAggregated = 'activity_period_aggregated',
  ActivityUsageHours = 'activity_usage_hours',
  AverageDailyEngineLoadFactor = 'average_daily_engine_load_factor',
  BatteryLevel = 'battery_level',
  BatteryVoltage = 'battery_voltage',
  CraneState = 'crane_state',
  CumulativeActiveRegenerationHours = 'cumulative_active_regeneration_hours',
  CumulativeDistance = 'cumulative_distance',
  CumulativeFuel = 'cumulative_fuel',
  CumulativeIdleHours = 'cumulative_idle_hours',
  CumulativeIdleNonOperatingHours = 'cumulative_idle_non_operating_hours',
  CumulativeLoadCount = 'cumulative_load_count',
  CumulativeOperatingHours = 'cumulative_operating_hours',
  CumulativePayloadTotal = 'cumulative_payload_total',
  CumulativePowerTakeOffHours = 'cumulative_power_take_off_hours',
  CumulativeUsageHours = 'cumulative_usage_hours',
  Daily = 'daily',
  DailyCo2 = 'daily_co2',
  DailyDistance = 'daily_distance',
  DailyFaultCountBySeverity = 'daily_fault_count_by_severity',
  DailyFuel = 'daily_fuel',
  DailyHours = 'daily_hours',
  DailyIdleHours = 'daily_idle_hours',
  DailyIdleNonOperatingHours = 'daily_idle_non_operating_hours',
  DailyLoadCount = 'daily_load_count',
  DailyOperatingHours = 'daily_operating_hours',
  DailyPayload = 'daily_payload',
  DailyPowerTakeOffHours = 'daily_power_take_off_hours',
  DailyUsageHours = 'daily_usage_hours',
  DefRemainingRatio = 'def_remaining_ratio',
  DeviceBatteryLevel = 'device_battery_level',
  DeviceBatteryVoltage = 'device_battery_voltage',
  EngineCondition = 'engine_condition',
  EngineCoolantTemperature = 'engine_coolant_temperature',
  EngineLoadPercentage = 'engine_load_percentage',
  EvBatteryLevel = 'ev_battery_level',
  FaultCodes = 'fault_codes',
  FuelRemaining = 'fuel_remaining',
  FuelUsedOnDevice = 'fuel_used_on_device',
  HourlyCo2 = 'hourly_co2',
  HourlyConsumption = 'hourly_consumption',
  Humidity = 'humidity',
  IdleRate = 'idle_rate',
  PeakDailySpeed = 'peak_daily_speed',
  Per_100kmCo2 = 'per_100km_co2',
  Per_100kmConsumption = 'per_100km_consumption',
  Position = 'position',
  Pressure = 'pressure',
  SensorBoolean = 'sensor_boolean',
  Temperature = 'temperature',
  TyreSnapshot = 'tyre_snapshot',
}

export type DataKindDataType = {
  __typename?: 'DataKindDataType';
  assetInOrganizationId: Scalars['Int']['output'];
  dataKind: Scalars['String']['output'];
  multipleProperties: Scalars['Boolean']['output'];
  unit: Scalars['String']['output'];
  values: Array<Maybe<Values>>;
};

export type DataPack = {
  __typename?: 'DataPack';
  code: Scalars['String']['output'];
  dataCodes: Array<Scalars['String']['output']>;
};

/** Data sources */
export type DataSource = {
  __typename?: 'DataSource';
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deprecated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['Float']['output']>;
  integrations?: Maybe<Array<Maybe<Integration>>>;
  label?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Data sources */
export type DataSourceIntegrationsArgs = {
  currentWorkspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DatePeriod = {
  __typename?: 'DatePeriod';
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
};

export type DeleteAssetResult = {
  __typename?: 'DeleteAssetResult';
  id: Scalars['Int']['output'];
};

/** early hourmeter threshold alertTriggered data */
export type EarlyAlertTriggeredHourmeterThresholdData = {
  __typename?: 'EarlyAlertTriggeredHourmeterThresholdData';
  cumulativeUsageHoursValue: Scalars['Float']['output'];
  hoursUntilNextAlertThreshold: Scalars['Float']['output'];
  nextAlertThreshold: Scalars['Float']['output'];
};

/** early odometer threshold alertTriggered data */
export type EarlyAlertTriggeredOdometerThresholdData = {
  __typename?: 'EarlyAlertTriggeredOdometerThresholdData';
  cumulativeDistanceValue: Scalars['Float']['output'];
  kmUntilNextAlertThreshold: Scalars['Float']['output'];
  nextAlertThreshold: Scalars['Float']['output'];
};

/** Energy Type */
export type EnergyTypeType = {
  __typename?: 'EnergyTypeType';
  code: Scalars['String']['output'];
  emissionFactor?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Enterprise = {
  __typename?: 'Enterprise';
  assetCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EnterpriseSortBy = {
  field: EnterpriseSortField;
  order: SortDirection;
};

export enum EnterpriseSortField {
  Name = 'name',
}

/** An export job with its metadata and status */
export type Export = {
  __typename?: 'Export';
  /** Progress percentage for exports in progress (0-100) */
  completionPercentage?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the export */
  id: Scalars['Int']['output'];
  /** Whether the export is a legacy export */
  isLegacy: Scalars['Boolean']['output'];
  /** The type of export */
  kind: Scalars['String']['output'];
  /** When the export was created */
  startDate: Scalars['DateTime']['output'];
  /** Current status of the export */
  status: ExportStatus;
  /** URLs to download the exported files */
  urls?: Maybe<Array<Scalars['String']['output']>>;
};

/** The status of an export */
export enum ExportStatus {
  /** The export was canceled */
  Canceled = 'CANCELED',
  /** The export is completed */
  Completed = 'COMPLETED',
  /** The export failed */
  Failed = 'FAILED',
  /** The export is in progress */
  InProgress = 'IN_PROGRESS',
  /** The export is waiting to start */
  Pending = 'PENDING',
}

export type FaultPeriod = {
  since: Scalars['String']['input'];
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Types of fault severity */
export type FaultSeverity = {
  __typename?: 'FaultSeverity';
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type FaultWithTranslationItemType = {
  __typename?: 'FaultWithTranslationItemType';
  asset?: Maybe<Asset>;
  assetInOrganizationId: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  translation?: Maybe<Scalars['String']['output']>;
};

export type FaultWithTranslationStatisticsType = {
  __typename?: 'FaultWithTranslationStatisticsType';
  assetCount: Scalars['Int']['output'];
  faultSeverityCount: GetAssetInOrgSeverityCountsType;
};

export type FaultsFilters = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  severities?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** faults with translation */
export type FaultsWithTranslation = {
  __typename?: 'FaultsWithTranslation';
  asset: Asset;
  assetInOrganizationId: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  translation?: Maybe<Scalars['String']['output']>;
};

/** A GeoJSON Feature */
export type Feature = {
  __typename?: 'Feature';
  geometry?: Maybe<Geometry>;
  id?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['JSONObject']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** A GeoJSON FeatureCollection */
export type FeatureCollection = {
  __typename?: 'FeatureCollection';
  features?: Maybe<Array<Maybe<Feature>>>;
  type?: Maybe<Scalars['String']['output']>;
};

/** A GeoJSON FeatureCollection */
export type FeatureCollectionInput = {
  features?: InputMaybe<Array<InputMaybe<FeatureInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** A GeoJSON Feature */
export type FeatureInput = {
  geometry?: InputMaybe<GeometryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSONObject']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** FeatureManagementType */
export type FeatureManagement = {
  __typename?: 'FeatureManagement';
  carbonEmissionsFleetReportUrl: Scalars['String']['output'];
  carbonEmissionsReportUrl: Scalars['String']['output'];
  customLogoUrl?: Maybe<Scalars['String']['output']>;
  dashboards?: Maybe<Array<Dashboard>>;
  displayAlerts: Scalars['Boolean']['output'];
  displayUsageTab: Scalars['Boolean']['output'];
  displayZones: Scalars['Boolean']['output'];
  fleetUsageReportUrl: Scalars['String']['output'];
  hourlyConsumptionReportUrl: Scalars['String']['output'];
  idleRateReportUrl: Scalars['String']['output'];
  inventoryCareReportUrl: Scalars['String']['output'];
  signalHealthReportUrl: Scalars['String']['output'];
};

export type FleetData = {
  __typename?: 'FleetData';
  assetInOrganizationFleetData?: Maybe<PaginatedAssetInOrganizationFleetData>;
  dailyFuel?: Maybe<Array<Maybe<AnalyticsFleetDataDailyFuelItem>>>;
  statistics?: Maybe<AnalyticsFleetDataStatistics>;
};

export type FleetDataAssetInOrganizationFleetDataArgs = {
  assetFilters: ReportAssetFilter;
  pagination: Pagination;
  period: Period;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy: AssetInOrganizationFleetDataSortBy;
};

export type FleetDataDailyFuelArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FleetDataStatisticsArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FleetSegment = {
  __typename?: 'FleetSegment';
  assetCount: Scalars['Int']['output'];
  filters: AssetFilters;
  id: Scalars['Int']['output'];
  kind: FleetSegmentKind;
  name: Scalars['String']['output'];
};

export enum FleetSegmentKind {
  Custom = 'custom',
  Default = 'default',
  Hiboo = 'hiboo',
}

/** Fuel used sum on period */
export type FuelSum = {
  __typename?: 'FuelSum';
  fuelUsedSum?: Maybe<Scalars['Float']['output']>;
};

/** A GeoJSON geometry */
export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<
    Array<Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>>
  >;
  type?: Maybe<Scalars['String']['output']>;
};

/** A GeoJSON geometry */
export type GeometryInput = {
  coordinates?: InputMaybe<
    Array<
      InputMaybe<
        Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>
      >
    >
  >;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Faults' count by severity */
export type GetAssetInOrgSeverityCountsType = {
  __typename?: 'GetAssetInOrgSeverityCountsType';
  criticalNb: Scalars['Int']['output'];
  highNb: Scalars['Int']['output'];
  lowNb: Scalars['Int']['output'];
  mediumNb: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unknownNb: Scalars['Int']['output'];
};

export type GetDailyIdleRateSerieResult = {
  __typename?: 'GetDailyIdleRateSerieResult';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GetFaultsGroupedByAssetPaginatedType = {
  __typename?: 'GetFaultsGroupedByAssetPaginatedType';
  faultSeverityCounts: Array<AssetFaultSeverityCountsItemType>;
};

export type GetFaultsGroupedByAssetType = {
  __typename?: 'GetFaultsGroupedByAssetType';
  faultSeverityCounts: Array<AssetFaultSeverityCountsItemType>;
};

export type GetIdleRateByCategoryMultipleSimpleValue = {
  __typename?: 'GetIdleRateByCategoryMultipleSimpleValue';
  data?: Maybe<GetIdleRateByCategoryMultipleSimpleValueData>;
  key?: Maybe<Scalars['String']['output']>;
};

export type GetIdleRateByCategoryMultipleSimpleValueData = {
  __typename?: 'GetIdleRateByCategoryMultipleSimpleValueData';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type GetIdleRateHistorySerieResult = {
  __typename?: 'GetIdleRateHistorySerieResult';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

/** Info on parent workspace */
export type GetInfoOnParentWorkspaceType = {
  __typename?: 'GetInfoOnParentWorkspaceType';
  userIsAdminInParentWorkspace?: Maybe<Scalars['Boolean']['output']>;
  userIsInParentWorkspace?: Maybe<Scalars['Boolean']['output']>;
};

export type GlobalSearch = AssetSearchResult | ZoneSearchResult;

export type GraphQlAssetFilter = {
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  customfields?: InputMaybe<Array<CustomFieldType>>;
  enterprises?: InputMaybe<Array<Scalars['String']['input']>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  makers?: InputMaybe<Array<Scalars['String']['input']>>;
  models?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  sharedByOrganizations?: InputMaybe<Array<Scalars['Int']['input']>>;
  sharedWithOrganizations?: InputMaybe<Array<Scalars['Int']['input']>>;
  sharingInProgressStatus?: InputMaybe<Scalars['Boolean']['input']>;
  sharingReferences?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  withLatestPosition?: InputMaybe<Scalars['Boolean']['input']>;
  zones?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Group of users and the device/assets they manage */
export type Group = {
  __typename?: 'Group';
  addressCategories: Array<AddressCategory>;
  addressCount?: Maybe<Scalars['Float']['output']>;
  addresses: Array<Address>;
  alert?: Maybe<Alert>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
  allAddressCategories?: Maybe<Array<Maybe<AddressCategory>>>;
  allFaultSeverities: Array<FaultSeverity>;
  archivedInventory: PaginatedAssets;
  asset?: Maybe<Asset>;
  assetsEdge: Array<Asset>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customFieldsV2: Array<CustomFieldV2Type>;
  dataSources?: Maybe<Array<Maybe<DataSource>>>;
  energyTypes?: Maybe<Array<Maybe<EnergyTypeType>>>;
  exports?: Maybe<Array<Export>>;
  featureManagement?: Maybe<FeatureManagement>;
  filters: WorkspaceAssetFilters;
  fleetSegments?: Maybe<Array<FleetSegment>>;
  globalSearch?: Maybe<Array<Maybe<GlobalSearch>>>;
  hasChildren?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integrations?: Maybe<Array<Maybe<Integration>>>;
  inventoryV2: PaginatedAssets;
  isRoot?: Maybe<Scalars['Boolean']['output']>;
  listAssets: PaginatedAssets;
  mapTiles?: Maybe<MapTilesWrapper>;
  name?: Maybe<Scalars['String']['output']>;
  nearbyAssets: Array<NearbyAsset>;
  organizationId?: Maybe<Scalars['String']['output']>;
  parentWorkspaceId?: Maybe<Scalars['Int']['output']>;
  totalAssets?: Maybe<Scalars['Int']['output']>;
  totalAssetsEdge?: Maybe<Scalars['Int']['output']>;
  totalUsers?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
  workspaceRootId?: Maybe<Scalars['Int']['output']>;
  workspaceTyreCondition?: Maybe<WorkspaceTyreConditionType>;
  zones: Array<Address>;
};

/** Group of users and the device/assets they manage */
export type GroupAddressesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Group of users and the device/assets they manage */
export type GroupAlertArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Group of users and the device/assets they manage */
export type GroupAlertsArgs = {
  kind?: InputMaybe<AlertKind>;
};

/** Group of users and the device/assets they manage */
export type GroupArchivedInventoryArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort: InventorySortBy;
};

/** Group of users and the device/assets they manage */
export type GroupAssetArgs = {
  id: Scalars['ID']['input'];
};

/** Group of users and the device/assets they manage */
export type GroupAssetsEdgeArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

/** Group of users and the device/assets they manage */
export type GroupDataSourcesArgs = {
  filterCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Group of users and the device/assets they manage */
export type GroupExportsArgs = {
  status?: InputMaybe<ExportStatus>;
};

/** Group of users and the device/assets they manage */
export type GroupGlobalSearchArgs = {
  limit: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

/** Group of users and the device/assets they manage */
export type GroupIntegrationsArgs = {
  filterDataSource?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** Group of users and the device/assets they manage */
export type GroupInventoryV2Args = {
  assetFilters?: InputMaybe<GraphQlAssetFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort: InventorySortBy;
};

/** Group of users and the device/assets they manage */
export type GroupListAssetsArgs = {
  assetFilters?: InputMaybe<GraphQlAssetFilter>;
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Group of users and the device/assets they manage */
export type GroupNearbyAssetsArgs = {
  assetFilters?: InputMaybe<GraphQlAssetFilter>;
  coordinates: Coordinates;
};

/** Group of users and the device/assets they manage */
export type GroupZonesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type HibooFileType = {
  __typename?: 'HibooFileType';
  contentType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sizeKb: Scalars['Float']['output'];
  uploadDate: Scalars['String']['output'];
};

export type HourlyConsumptionAsset = {
  __typename?: 'HourlyConsumptionAsset';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  averageFuelUsedPerHour: Scalars['Float']['output'];
  make?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HourlyConsumptionAssetPerformanceRow = {
  __typename?: 'HourlyConsumptionAssetPerformanceRow';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId?: Maybe<Scalars['Int']['output']>;
  categoryAssetsCount?: Maybe<Scalars['Int']['output']>;
  categoryDelta?: Maybe<Scalars['Float']['output']>;
  categoryHourlyConsumption?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  currentHourlyConsumption?: Maybe<Scalars['Float']['output']>;
  enterprise?: Maybe<Scalars['String']['output']>;
  fuelUsed?: Maybe<Scalars['Float']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelAssetsCount?: Maybe<Scalars['Int']['output']>;
  modelDelta?: Maybe<Scalars['Float']['output']>;
  modelHourlyConsumption?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previousHourlyConsumption?: Maybe<Scalars['Float']['output']>;
  previousHourlyConsumptionDelta?: Maybe<Scalars['Float']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  sharingInformations?: Maybe<AssetSharingInformations>;
  source?: Maybe<Scalars['String']['output']>;
  usageHours?: Maybe<Scalars['Float']['output']>;
};

export type HourlyConsumptionAssetsPerformanceSort = {
  desc: Scalars['Boolean']['input'];
  id: HourlyConsumptionAssetsPerformanceSortField;
};

export enum HourlyConsumptionAssetsPerformanceSortField {
  CategoryDelta = 'categoryDelta',
  CategoryId = 'categoryId',
  Enterprise = 'enterprise',
  HourlyConsumption = 'hourlyConsumption',
  Model = 'model',
  ModelDelta = 'modelDelta',
  Name = 'name',
  PreviousHourlyConsumptionDelta = 'previousHourlyConsumptionDelta',
  SerialNumber = 'serialNumber',
  Source = 'source',
  UsageHours = 'usageHours',
}

export type HourlyConsumptionComparativeAverage = {
  __typename?: 'HourlyConsumptionComparativeAverage';
  current?: Maybe<HourlyConsumptionItem>;
  previous?: Maybe<HourlyConsumptionItem>;
};

export type HourlyConsumptionComparativePeriod = {
  __typename?: 'HourlyConsumptionComparativePeriod';
  current?: Maybe<HourlyConsumptionItem>;
  previous?: Maybe<HourlyConsumptionItem>;
};

export type HourlyConsumptionEnterprisesPerformanceRow = {
  __typename?: 'HourlyConsumptionEnterprisesPerformanceRow';
  currentHourlyConsumption?: Maybe<Scalars['Float']['output']>;
  enterprise?: Maybe<Scalars['String']['output']>;
  previousHourlyConsumption?: Maybe<Scalars['Float']['output']>;
  previousHourlyConsumptionDelta?: Maybe<Scalars['Float']['output']>;
  usageHours?: Maybe<Scalars['Float']['output']>;
};

export type HourlyConsumptionEnterprisesPerformanceSort = {
  desc: Scalars['Boolean']['input'];
  id: HourlyConsumptionEnterprisesPerformanceSortField;
};

export enum HourlyConsumptionEnterprisesPerformanceSortField {
  Enterprise = 'enterprise',
  HourlyConsumption = 'hourlyConsumption',
  PreviousHourlyConsumptionDelta = 'previousHourlyConsumptionDelta',
  UsageHours = 'usageHours',
}

export type HourlyConsumptionHistoryItem = {
  __typename?: 'HourlyConsumptionHistoryItem';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type HourlyConsumptionItem = {
  __typename?: 'HourlyConsumptionItem';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  period?: Maybe<DatePeriod>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type HourlyConsumptionMultipleSimpleValueData = {
  __typename?: 'HourlyConsumptionMultipleSimpleValueData';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type HourlyConsumptionMultipleSimpleValueItem = {
  __typename?: 'HourlyConsumptionMultipleSimpleValueItem';
  data?: Maybe<HourlyConsumptionMultipleSimpleValueData>;
  key?: Maybe<Scalars['String']['output']>;
};

export type HourlyConsumptionSerieResultItem = {
  __typename?: 'HourlyConsumptionSerieResultItem';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type IdleRate = {
  __typename?: 'IdleRate';
  activeAssetsCount: Scalars['Int']['output'];
  assetsCount: Scalars['Int']['output'];
  period: DatePeriod;
  value?: Maybe<Scalars['Float']['output']>;
};

export type IdleRateAsset = {
  __typename?: 'IdleRateAsset';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  idleRate: Scalars['Float']['output'];
  make?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IdleRateAssetPerformanceRow = {
  __typename?: 'IdleRateAssetPerformanceRow';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId?: Maybe<Scalars['Int']['output']>;
  categoryAssetsCount?: Maybe<Scalars['Int']['output']>;
  categoryDelta?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  categoryIdleRate?: Maybe<Scalars['Float']['output']>;
  currentIdleRate?: Maybe<Scalars['Float']['output']>;
  enterprise?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelAssetsCount?: Maybe<Scalars['Int']['output']>;
  modelDelta?: Maybe<Scalars['Float']['output']>;
  modelIdleRate?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previousIdleRate?: Maybe<Scalars['Float']['output']>;
  previousIdleRateDelta?: Maybe<Scalars['Float']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  sharingInformations?: Maybe<AssetSharingInformations>;
  source?: Maybe<Scalars['String']['output']>;
  usageHours?: Maybe<Scalars['Float']['output']>;
};

export type IdleRateAssetsPerformanceSort = {
  desc: Scalars['Boolean']['input'];
  id: IdleRateAssetsPerformanceSortField;
};

export enum IdleRateAssetsPerformanceSortField {
  Category = 'category',
  CategoryDelta = 'categoryDelta',
  Enterprise = 'enterprise',
  IdleRate = 'idleRate',
  Model = 'model',
  ModelDelta = 'modelDelta',
  Name = 'name',
  PreviousIdleRateDelta = 'previousIdleRateDelta',
  SerialNumber = 'serialNumber',
  Source = 'source',
  UsageHours = 'usageHours',
}

export type IdleRateEnterprisesPerformanceRow = {
  __typename?: 'IdleRateEnterprisesPerformanceRow';
  currentIdleRate?: Maybe<Scalars['Float']['output']>;
  enterprise?: Maybe<Scalars['String']['output']>;
  previousIdleRate?: Maybe<Scalars['Float']['output']>;
  previousIdleRateDelta?: Maybe<Scalars['Float']['output']>;
  usageHours?: Maybe<Scalars['Float']['output']>;
};

export type IdleRateEnterprisesPerformanceSort = {
  desc: Scalars['Boolean']['input'];
  id: IdleRateEnterprisesPerformanceSortField;
};

export enum IdleRateEnterprisesPerformanceSortField {
  Enterprise = 'enterprise',
  IdleRate = 'idleRate',
  PreviousIdleRateDelta = 'previousIdleRateDelta',
  UsageHours = 'usageHours',
}

export type InProgressOrLatestSharing = IssuedSharing | ReceivedSharing;

/** Integration data sources */
export type Integration = {
  __typename?: 'Integration';
  active?: Maybe<Scalars['Boolean']['output']>;
  assetsCount?: Maybe<Scalars['Int']['output']>;
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['JSON']['output']>;
  dataSource?: Maybe<DataSource>;
  dataSourceId?: Maybe<Scalars['Float']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  runStatus?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<IntegrationScheduleType>;
  schedules?: Maybe<Array<Maybe<IntegrationScheduleType>>>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['Float']['output']>;
};

/** Integration schedule type */
export type IntegrationScheduleType = {
  __typename?: 'IntegrationScheduleType';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<DataSource>;
  dataSourceId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  integration?: Maybe<Integration>;
  integrationId?: Maybe<Scalars['Int']['output']>;
  intervalMinutes?: Maybe<Scalars['Int']['output']>;
  lastRunDate?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** customFields for an asset */
export type InventoryCustomFieldsInputType = {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Data for an asset */
export type InventoryInputType = {
  category?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Array<InputMaybe<InventoryCustomFieldsInputType>>>;
  energyTypeCode?: InputMaybe<Scalars['String']['input']>;
  enterprise?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  workspaces?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type InventorySortBy = {
  field: Scalars['String']['input'];
  order: SortDirection;
};

/** Invitation object */
export type InvitationType = {
  __typename?: 'InvitationType';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  admin?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inviter?: Maybe<User>;
  key?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  workspaceRoles?: Maybe<Array<Maybe<InvitationWorkspaceRoleType>>>;
  workspaces?: Maybe<Array<Maybe<Group>>>;
};

/** Invitation and workspace relation with role */
export type InvitationWorkspaceRoleType = {
  __typename?: 'InvitationWorkspaceRoleType';
  admin?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invitation?: Maybe<InvitationType>;
  invitationId?: Maybe<Scalars['Int']['output']>;
  workspace?: Maybe<Group>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type IssuedSharing = {
  __typename?: 'IssuedSharing';
  assetIds: Array<Maybe<Scalars['Int']['output']>>;
  beneficiary: IssuedSharingBeneficiary;
  createdAt: Scalars['String']['output'];
  createdByUser: User;
  dataPackCodes: Array<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalReference: Scalars['String']['output'];
  migratedFromV1: Scalars['Boolean']['output'];
  nbAssets: Scalars['Int']['output'];
  ownerOrganizationId: Scalars['Int']['output'];
  sharedAssets: PaginatedAssets;
  startDate: Scalars['String']['output'];
  status: SharingStatus;
  updatedAt: Scalars['String']['output'];
};

export type IssuedSharingSharedAssetsArgs = {
  pagination: Pagination;
};

export type IssuedSharingBeneficiary = {
  __typename?: 'IssuedSharingBeneficiary';
  email: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type IssuedSharingDataFilters = {
  __typename?: 'IssuedSharingDataFilters';
  assets: Array<SharingAsset>;
  beneficiaryOrganization: Array<KnownOrganizationSharing>;
  beneficiaryUserEmail: Array<Scalars['String']['output']>;
  createdByUser: Array<SharingUserDataFilter>;
  internalReference: Array<Scalars['String']['output']>;
  status: Array<SharingStatus>;
};

export type IssuedSharingFilters = {
  /** Asset IDs of the assets shared in the issued sharing */
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Emails of the users that the sharing is shared with */
  beneficiaryEmail?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Organization IDs of the organizations that the sharing is shared with */
  beneficiaryOrganizationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** User IDs of the users that created the issued sharing */
  createdByUserId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Internal references of the issued sharings */
  internalReference?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Status of the issued sharing */
  status?: InputMaybe<Array<SharingFilterStatus>>;
};

export enum IssuedSharingSortField {
  BeneficiaryEmail = 'beneficiaryEmail',
  BeneficiaryOrganization = 'beneficiaryOrganization',
  CreatedAt = 'createdAt',
  CreatedByUser = 'createdByUser',
  InternalReference = 'internalReference',
  NbAssets = 'nbAssets',
  StartDate = 'startDate',
  Status = 'status',
}

export type KnownBeneficiaryUserSharing = {
  __typename?: 'KnownBeneficiaryUserSharing';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
};

export type KnownOrganizationSharing = {
  __typename?: 'KnownOrganizationSharing';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Last data date by data kind */
export type LastDataDateByDataKindGqlType = {
  __typename?: 'LastDataDateByDataKindGQLType';
  dataKind: Scalars['String']['output'];
  lastDataDate: Scalars['DateTime']['output'];
};

export type LastDaysIdleRate = {
  __typename?: 'LastDaysIdleRate';
  current?: Maybe<IdleRate>;
  previous?: Maybe<IdleRate>;
};

/** Information necessary to add a map layer onto a mapbox instance */
export type MapTiles = {
  __typename?: 'MapTiles';
  bounds?: Maybe<Array<Scalars['Float']['output']>>;
  id: Scalars['Int']['output'];
  minZoom?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Wrapper for table requests */
export type MapTilesWrapper = {
  __typename?: 'MapTilesWrapper';
  rows: Array<MapTiles>;
  total: Scalars['Int']['output'];
};

export type MultiplePropertiesValue = {
  __typename?: 'MultiplePropertiesValue';
  date: Scalars['DateTime']['output'];
  values: Array<MultiplePropertiesValueDescriptor>;
};

export type MultiplePropertiesValueDescriptor = {
  __typename?: 'MultiplePropertiesValueDescriptor';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActivityAlert?: Maybe<Alert>;
  addAddressCategory?: Maybe<AddressCategory>;
  addAssetToFavorites?: Maybe<Asset>;
  addAssetToWorkspace?: Maybe<Asset>;
  addCategory?: Maybe<AssetInOrganizationCategory>;
  addFaultAlert?: Maybe<Alert>;
  addGeofencingAlert?: Maybe<Alert>;
  addHierarchizedInvitation?: Maybe<InvitationType>;
  addHourmeterThresholdAlert?: Maybe<Alert>;
  addOdometerThresholdAlert?: Maybe<Alert>;
  addUnderuseAlert?: Maybe<Alert>;
  addUserRole?: Maybe<Site>;
  addUserToWorkspace?: Maybe<User>;
  addZoneInWorkspace?: Maybe<Scalars['String']['output']>;
  archiveAsset: ArchiveAssetResult;
  assignAssetInOrganizationIssueToUser?: Maybe<AssetInOrganizationIssue>;
  assignPublicURL?: Maybe<AssignPublicUrl>;
  /** Cancel an export pending or in progress */
  cancelExport: Export;
  createApiAccess?: Maybe<ApiAccess>;
  createCustomField?: Maybe<CustomFieldV2Type>;
  createEnterprise?: Maybe<Enterprise>;
  createFleetSegment?: Maybe<FleetSegment>;
  createIntegration?: Maybe<Integration>;
  createSharing?: Maybe<IssuedSharing>;
  createWorkspace?: Maybe<Scalars['String']['output']>;
  createZone?: Maybe<Zone>;
  deleteAddressCategory?: Maybe<AddressCategory>;
  deleteAlert?: Maybe<Alert>;
  deleteApiAccess?: Maybe<Scalars['Boolean']['output']>;
  deleteAsset: DeleteAssetResult;
  deleteAssetCustomField?: Maybe<AssetCustomFieldV2Type>;
  deleteAssetInOrganizationFile?: Maybe<AssetInOrganizationFileType>;
  deleteAssetInOrganizationIssueAttachment?: Maybe<AssetInOrganizationIssueAttachmentType>;
  deleteCategory?: Maybe<AssetInOrganizationCategory>;
  deleteCustomField?: Maybe<Scalars['Float']['output']>;
  deleteEnterprise?: Maybe<Enterprise>;
  deleteFleetSegment?: Maybe<Scalars['Boolean']['output']>;
  deleteIntegration?: Maybe<Integration>;
  deleteInvitation?: Maybe<Scalars['Boolean']['output']>;
  deleteInvitationWorkspace?: Maybe<InvitationType>;
  deleteSharing?: Maybe<IssuedSharing>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  deleteUserRole?: Maybe<Site>;
  deleteUserRoles?: Maybe<Site>;
  deleteWorkspace?: Maybe<Scalars['Boolean']['output']>;
  editAssetCustomField2?: Maybe<AssetCustomFieldV2Type>;
  editAssetInOrganizationFile?: Maybe<AssetInOrganizationFileType>;
  editAssetInOrganizationIssue?: Maybe<AssetInOrganizationIssue>;
  editAssetInOrganizationIssueDescription?: Maybe<AssetInOrganizationIssue>;
  editAssetInOrganizationIssueTitle?: Maybe<AssetInOrganizationIssue>;
  editAssetWorkspaces2?: Maybe<Array<Maybe<Asset>>>;
  editEnterprise?: Maybe<Enterprise>;
  editSharing?: Maybe<IssuedSharing>;
  editZone?: Maybe<Zone>;
  markNotificationAsRead?: Maybe<Scalars['Boolean']['output']>;
  patchAsset?: Maybe<Asset>;
  removeAssetFromFavorites?: Maybe<Asset>;
  removeAssetFromWorkspace?: Maybe<Asset>;
  removeUserFromWorkspace?: Maybe<User>;
  removeZone?: Maybe<Address>;
  resendInvitation?: Maybe<InvitationType>;
  resendSharingInvitation?: Maybe<IssuedSharing>;
  retryExport?: Maybe<Scalars['Boolean']['output']>;
  setAssetInOrganizationIssueSeverity?: Maybe<AssetInOrganizationIssue>;
  setAssetInOrganizationIssueStatus?: Maybe<AssetInOrganizationIssue>;
  setInvitationWorkspacesAndRoles?: Maybe<Scalars['Boolean']['output']>;
  setParentWorkspace?: Maybe<Scalars['Boolean']['output']>;
  setUserWorkspaces?: Maybe<Scalars['Boolean']['output']>;
  stopSharing?: Maybe<IssuedSharing>;
  unarchiveAsset: UnarchiveAssetResult;
  updateActivityAlert?: Maybe<Alert>;
  updateAddressCategory?: Maybe<AddressCategory>;
  updateApiAccessPassword?: Maybe<ApiAccess>;
  updateCategory?: Maybe<AssetInOrganizationCategory>;
  updateCustomField?: Maybe<CustomFieldV2Type>;
  updateFaultAlert?: Maybe<Alert>;
  updateFleetSegment?: Maybe<FleetSegment>;
  updateGeofencingAlert?: Maybe<Alert>;
  updateHourmeterThresholdAlert?: Maybe<Alert>;
  updateIntegration?: Maybe<Integration>;
  updateInventory?: Maybe<UserFileType>;
  updateInvitationOrgRole?: Maybe<InvitationType>;
  updateInvitationWorkspace?: Maybe<InvitationType>;
  updateOdometerThresholdAlert?: Maybe<Alert>;
  updatePassword?: Maybe<User>;
  updateUnderuseAlert?: Maybe<Alert>;
  updateUser?: Maybe<User>;
  updateUserOrgRole?: Maybe<User>;
  updateUserRole?: Maybe<Site>;
  updateUserWorkspaceSummarySubscriptions?: Maybe<User>;
  updateWorkspace?: Maybe<Group>;
  updateWorkspaceUserRole?: Maybe<User>;
};

export type MutationAddActivityAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertActivityDataInput;
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
  timeRange: AlertTimeRangeInput;
};

export type MutationAddAddressCategoryArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddAssetToFavoritesArgs = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddAssetToWorkspaceArgs = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddCategoryArgs = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationAddFaultAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertFaultDataInput;
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddGeofencingAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertGeofencingDataInput;
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
  timeRange: AlertTimeRangeInput;
};

export type MutationAddHierarchizedInvitationArgs = {
  adminOfOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  workspacesAndRoles?: InputMaybe<Array<InputMaybe<WorkspaceAndRoleInput>>>;
};

export type MutationAddHourmeterThresholdAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertHourmeterThresholdDataInput;
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddOdometerThresholdAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertOdometerThresholdDataInput;
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddUnderuseAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertUnderuseDataInput;
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
  timeRange: AlertTimeRangeInput;
};

export type MutationAddUserRoleArgs = {
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddUserToWorkspaceArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddZoneInWorkspaceArgs = {
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationArchiveAssetArgs = {
  archiveComment: Scalars['String']['input'];
  assetId: Scalars['Float']['input'];
};

export type MutationAssignAssetInOrganizationIssueToUserArgs = {
  assetInOrganizationIssueId: Scalars['Int']['input'];
  assigneeUserId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAssignPublicUrlArgs = {
  assetId: Scalars['Int']['input'];
  url: Scalars['String']['input'];
};

export type MutationCancelExportArgs = {
  id: Scalars['Int']['input'];
};

export type MutationCreateApiAccessArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type MutationCreateCustomFieldArgs = {
  description: Scalars['String']['input'];
  format: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  workspaceIds?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type MutationCreateEnterpriseArgs = {
  name: Scalars['String']['input'];
};

export type MutationCreateFleetSegmentArgs = {
  filters: AssetFiltersInput;
  name: Scalars['String']['input'];
};

export type MutationCreateIntegrationArgs = {
  credentials?: InputMaybe<Scalars['JSON']['input']>;
  dataSourceCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateSharingArgs = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  beneficiaryEmail: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  internalReference: Scalars['String']['input'];
  optionalDataPackCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
};

export type MutationCreateWorkspaceArgs = {
  assets?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentWorkspaceId?: InputMaybe<Scalars['Int']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type MutationCreateZoneArgs = {
  area: FeatureCollectionInput;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  searchAddress?: InputMaybe<Scalars['String']['input']>;
  workspaceIds: Array<Scalars['Int']['input']>;
};

export type MutationDeleteAddressCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteAlertArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteApiAccessArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteAssetArgs = {
  assetId: Scalars['Float']['input'];
};

export type MutationDeleteAssetCustomFieldArgs = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  customFieldId?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationDeleteAssetInOrganizationFileArgs = {
  assetInOrganizationFileId: Scalars['Int']['input'];
};

export type MutationDeleteAssetInOrganizationIssueAttachmentArgs = {
  assetInOrganizationIssueAttachmentId: Scalars['Int']['input'];
};

export type MutationDeleteCategoryArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteCustomFieldArgs = {
  customFieldId?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationDeleteEnterpriseArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteFleetSegmentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteIntegrationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteInvitationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteInvitationWorkspaceArgs = {
  invitationId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteSharingArgs = {
  sharingId: Scalars['Int']['input'];
};

export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteUserRoleArgs = {
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteUserRolesArgs = {
  userId?: InputMaybe<Scalars['Float']['input']>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteWorkspaceArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationEditAssetCustomField2Args = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  customFieldId?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationEditAssetInOrganizationFileArgs = {
  assetInOrganizationFileId: Scalars['Int']['input'];
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
};

export type MutationEditAssetInOrganizationIssueArgs = {
  assetInOrganizationIssueId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  isCritical: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditAssetInOrganizationIssueDescriptionArgs = {
  assetInOrganizationIssueId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
};

export type MutationEditAssetInOrganizationIssueTitleArgs = {
  assetInOrganizationIssueId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditAssetWorkspaces2Args = {
  add?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationEditEnterpriseArgs = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type MutationEditSharingArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  internalReference: Scalars['String']['input'];
  optionalDataPackCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingId: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
};

export type MutationEditZoneArgs = {
  area: FeatureCollectionInput;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  searchAddress?: InputMaybe<Scalars['String']['input']>;
  workspaceIds: Array<Scalars['Int']['input']>;
};

export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['Int']['input'];
};

export type MutationPatchAssetArgs = {
  assetId: Scalars['Int']['input'];
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  energyTypeId?: InputMaybe<Scalars['Int']['input']>;
  enterprise?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRemoveAssetFromFavoritesArgs = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRemoveAssetFromWorkspaceArgs = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRemoveUserFromWorkspaceArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRemoveZoneArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResendInvitationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResendSharingInvitationArgs = {
  sharingId: Scalars['Int']['input'];
};

export type MutationRetryExportArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSetAssetInOrganizationIssueSeverityArgs = {
  assetInOrganizationIssueId: Scalars['Int']['input'];
  isCritical: Scalars['Boolean']['input'];
};

export type MutationSetAssetInOrganizationIssueStatusArgs = {
  assetInOrganizationIssueId: Scalars['Int']['input'];
  status: AssetInOrganizationIssueStatus;
};

export type MutationSetInvitationWorkspacesAndRolesArgs = {
  invitationId?: InputMaybe<Scalars['Int']['input']>;
  workspacesAndRoles?: InputMaybe<Array<InputMaybe<WorkspaceAndRoleInput>>>;
};

export type MutationSetParentWorkspaceArgs = {
  parentWorkspaceId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSetUserWorkspacesArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
  workspacesAndRoles?: InputMaybe<Array<InputMaybe<WorkspaceAndRoleInput>>>;
};

export type MutationStopSharingArgs = {
  sharingId: Scalars['Int']['input'];
};

export type MutationUnarchiveAssetArgs = {
  assetId: Scalars['Float']['input'];
};

export type MutationUpdateActivityAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertActivityDataInput;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
  timeRange: AlertTimeRangeInput;
};

export type MutationUpdateAddressCategoryArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateApiAccessPasswordArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateCategoryArgs = {
  color: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type MutationUpdateCustomFieldArgs = {
  description: Scalars['String']['input'];
  format: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Float']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  workspaceIds?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type MutationUpdateFaultAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertFaultDataInput;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateFleetSegmentArgs = {
  filters?: InputMaybe<AssetFiltersInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateGeofencingAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertGeofencingDataInput;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
  timeRange: AlertTimeRangeInput;
};

export type MutationUpdateHourmeterThresholdAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertHourmeterThresholdDataInput;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateIntegrationArgs = {
  credentials?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type MutationUpdateInventoryArgs = {
  assets?: InputMaybe<Array<InputMaybe<InventoryInputType>>>;
};

export type MutationUpdateInvitationOrgRoleArgs = {
  admin: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUpdateInvitationWorkspaceArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  invitationId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateOdometerThresholdAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertOdometerThresholdDataInput;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdatePasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUnderuseAlertArgs = {
  allAssets: Scalars['Boolean']['input'];
  assetIds: Array<InputMaybe<Scalars['Int']['input']>>;
  data: AlertUnderuseDataInput;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notify: AlertNotifyInput;
  segmentId?: InputMaybe<Scalars['Int']['input']>;
  timeRange: AlertTimeRangeInput;
};

export type MutationUpdateUserArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserOrgRoleArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateUserRoleArgs = {
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserWorkspaceSummarySubscriptionsArgs = {
  receiveMonthlySummary?: InputMaybe<Scalars['Boolean']['input']>;
  receiveWeeklySummary?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateWorkspaceArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateWorkspaceUserRoleArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type NearbyAsset = {
  __typename?: 'NearbyAsset';
  asset: Asset;
  distanceInMeters: Scalars['Float']['output'];
  durationInSeconds: Scalars['Float']['output'];
};

/** A notification for the user */
export type Notification = {
  __typename?: 'Notification';
  /** When the notification was created */
  createdAt: Scalars['DateTime']['output'];
  /** The identifier of the notification */
  id: Scalars['Int']['output'];
  /** The message of the notification */
  message: Scalars['String']['output'];
  /** When the notification was read */
  readAt?: Maybe<Scalars['DateTime']['output']>;
  /** The type of notification */
  type: NotificationType;
  /** The workspace identifier */
  workspaceId?: Maybe<Scalars['Int']['output']>;
};

/** The type of notification */
export enum NotificationType {
  /** Export completed */
  ExportCompleted = 'EXPORT_COMPLETED',
  /** Export failed */
  ExportFailed = 'EXPORT_FAILED',
}

/** Organization is the top of Workspaces */
export type Organization = {
  __typename?: 'Organization';
  addressCategories?: Maybe<Array<Maybe<AddressCategory>>>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  apiAccesses?: Maybe<Array<Maybe<ApiAccess>>>;
  assetsCount?: Maybe<Scalars['Int']['output']>;
  categories: Array<Category>;
  countAssetInOrganizationsForEnterprise: Scalars['Int']['output'];
  customFieldsV2?: Maybe<Array<Maybe<CustomFieldV2Type>>>;
  enterprise?: Maybe<Enterprise>;
  enterprises?: Maybe<PaginatedEnterprise>;
  hasOwnedAsset: Scalars['Boolean']['output'];
  hasSharedAsset: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
  integrationsCount?: Maybe<Scalars['Int']['output']>;
  invitations?: Maybe<Array<Maybe<InvitationType>>>;
  name?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
  usersNb?: Maybe<Scalars['Int']['output']>;
  workspace?: Maybe<Group>;
  workspaces?: Maybe<Array<Maybe<Group>>>;
  zones?: Maybe<Array<Maybe<Address>>>;
  zonesv2: Array<Zone>;
};

/** Organization is the top of Workspaces */
export type OrganizationEnterpriseArgs = {
  name: Scalars['String']['input'];
};

/** Organization is the top of Workspaces */
export type OrganizationEnterprisesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<EnterpriseSortBy>;
};

/** Organization is the top of Workspaces */
export type OrganizationWorkspaceArgs = {
  workspaceId: Scalars['Int']['input'];
};

export type PaginatedAssetFaultWithTranslationItemType = {
  __typename?: 'PaginatedAssetFaultWithTranslationItemType';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<AssetFaultWithTranslationItemType>>;
  total: Scalars['Int']['output'];
};

export type PaginatedAssetInOrganizationFileType = {
  __typename?: 'PaginatedAssetInOrganizationFileType';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<AssetInOrganizationFileType>>;
  total: Scalars['Int']['output'];
};

/** Wrapped AssetInOrganizationFleetData data */
export type PaginatedAssetInOrganizationFleetData = {
  __typename?: 'PaginatedAssetInOrganizationFleetData';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<AssetInOrganizationFleetData>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedAssetInOrganizationIssue = {
  __typename?: 'PaginatedAssetInOrganizationIssue';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<AssetInOrganizationIssue>>;
  total: Scalars['Int']['output'];
};

export type PaginatedAssetPosition = {
  __typename?: 'PaginatedAssetPosition';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<AssetPosition>>;
  total: Scalars['Int']['output'];
};

export type PaginatedAssets = {
  __typename?: 'PaginatedAssets';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Asset>;
  total: Scalars['Int']['output'];
};

/** Wrapped Co2AssetPerformance data */
export type PaginatedCo2AssetPerformance = {
  __typename?: 'PaginatedCo2AssetPerformance';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Co2AssetPerformance>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedEnterprise = {
  __typename?: 'PaginatedEnterprise';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<Enterprise>>;
  total: Scalars['Int']['output'];
};

/** Wrapped FaultWithTranslationItemType data */
export type PaginatedFaultWithTranslationItemType = {
  __typename?: 'PaginatedFaultWithTranslationItemType';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<FaultWithTranslationItemType>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Wrapped HourlyConsumptionAssetPerformanceRow data */
export type PaginatedHourlyConsumptionAssetPerformanceRow = {
  __typename?: 'PaginatedHourlyConsumptionAssetPerformanceRow';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<HourlyConsumptionAssetPerformanceRow>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Wrapped HourlyConsumptionEnterprisesPerformanceRow data */
export type PaginatedHourlyConsumptionEnterprisesPerformanceRow = {
  __typename?: 'PaginatedHourlyConsumptionEnterprisesPerformanceRow';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<HourlyConsumptionEnterprisesPerformanceRow>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Wrapped IdleRateAssetPerformanceRow data */
export type PaginatedIdleRateAssetPerformanceRow = {
  __typename?: 'PaginatedIdleRateAssetPerformanceRow';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<IdleRateAssetPerformanceRow>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Wrapped IdleRateEnterprisesPerformanceRow data */
export type PaginatedIdleRateEnterprisesPerformanceRow = {
  __typename?: 'PaginatedIdleRateEnterprisesPerformanceRow';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<IdleRateEnterprisesPerformanceRow>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedIssuedSharing = {
  __typename?: 'PaginatedIssuedSharing';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<IssuedSharing>>;
  total: Scalars['Int']['output'];
};

export type PaginatedReceivedSharing = {
  __typename?: 'PaginatedReceivedSharing';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<ReceivedSharing>>;
  total: Scalars['Int']['output'];
};

export type PaginatedTyreHistoryRowType = {
  __typename?: 'PaginatedTyreHistoryRowType';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<TyreHistoryRowType>>;
  total: Scalars['Int']['output'];
};

/** Wrapped UsageHoursAssetPerformance data */
export type PaginatedUsageHoursAssetPerformance = {
  __typename?: 'PaginatedUsageHoursAssetPerformance';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<UsageHoursAssetPerformance>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedZonePresence = {
  __typename?: 'PaginatedZonePresence';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<ZonePresence>>;
  total: Scalars['Int']['output'];
};

export type PaginatedZonePresenceForAsset = {
  __typename?: 'PaginatedZonePresenceForAsset';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  rows: Array<Maybe<ZonePresenceForAsset>>;
  total: Scalars['Int']['output'];
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type Period = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export enum PeriodScale {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR',
}

/** lat, long */
export type PointType = {
  __typename?: 'PointType';
  lat?: Maybe<Scalars['Float']['output']>;
  long?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  viewer?: Maybe<User>;
};

export type ReceivedSharing = {
  __typename?: 'ReceivedSharing';
  beneficiaryUser: ReceivedSharingBeneficiaryUser;
  dataPackCodes: Array<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalReference: Scalars['String']['output'];
  nbAssets: Scalars['Int']['output'];
  owner: ReceivedSharingOwner;
  sharedAssets: PaginatedAssets;
  startDate: Scalars['String']['output'];
  status: SharingStatus;
  updatedAt: Scalars['String']['output'];
};

export type ReceivedSharingSharedAssetsArgs = {
  pagination: Pagination;
};

export type ReceivedSharingBeneficiaryUser = {
  __typename?: 'ReceivedSharingBeneficiaryUser';
  apiAccount?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type ReceivedSharingDataFilters = {
  __typename?: 'ReceivedSharingDataFilters';
  assets: Array<SharingAsset>;
  internalReference: Array<Scalars['String']['output']>;
  ownerOrganization: Array<KnownOrganizationSharing>;
  ownerUser: Array<SharingUserDataFilter>;
  status: Array<SharingStatus>;
};

export type ReceivedSharingFilters = {
  /** Asset IDs of the assets shared in the received sharing */
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Internal references of the received sharings */
  internalReference?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Organization IDs of the organizations that the sharing is shared by */
  ownerOrganizationId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** User IDs of the users that created the received sharing */
  ownerUserId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Status of the received sharing */
  status?: InputMaybe<Array<SharingFilterStatus>>;
};

export type ReceivedSharingOwner = {
  __typename?: 'ReceivedSharingOwner';
  organizationId: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
  ownerUserApiAccount: Scalars['Boolean']['output'];
  ownerUserEmail: Scalars['String']['output'];
  ownerUserFullName: Scalars['String']['output'];
  ownerUserId: Scalars['Int']['output'];
};

export type ReceivedSharingSortBy = {
  field: ReceivedSharingSortField;
  order: SortDirection;
};

export enum ReceivedSharingSortField {
  InternalReference = 'internalReference',
  NbAssets = 'nbAssets',
  OwnerOrganization = 'ownerOrganization',
  OwnerUser = 'ownerUser',
  StartDate = 'startDate',
  Status = 'status',
}

export type ReceivedSharingSummaryByOrganization = {
  __typename?: 'ReceivedSharingSummaryByOrganization';
  countSharedAssets: Scalars['Int']['output'];
  countSharings: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
};

export type ReportAssetFilter = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  customfields?: InputMaybe<Array<InputMaybe<CustomFieldType>>>;
  enterprises?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  makers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sharedByOrganizations?: InputMaybe<Array<Scalars['Int']['input']>>;
  sharedWithOrganizations?: InputMaybe<Array<Scalars['Int']['input']>>;
  sharingInProgressStatus?: InputMaybe<Scalars['Boolean']['input']>;
  sharingReferences?: InputMaybe<Array<Scalars['String']['input']>>;
  sharingStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SharedAssetBeneficiaryOrganization = {
  __typename?: 'SharedAssetBeneficiaryOrganization';
  id: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
};

export type SharedAssetFileSource = {
  __typename?: 'SharedAssetFileSource';
  organizationName: Scalars['String']['output'];
};

export type SharedAssetOwnerOrganization = {
  __typename?: 'SharedAssetOwnerOrganization';
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
};

export type Sharing = {
  __typename?: 'Sharing';
  hasIssuedSharings: Scalars['Boolean']['output'];
  hasReceivedSharings: Scalars['Boolean']['output'];
  issuedSharingDataFilters: IssuedSharingDataFilters;
  issuedSharingDetails?: Maybe<IssuedSharing>;
  issuedSharings?: Maybe<PaginatedIssuedSharing>;
  knownBeneficiaryUsers: Array<Maybe<KnownBeneficiaryUserSharing>>;
  optionalDataPacks: Array<DataPack>;
  receivedSharingDataFilters: ReceivedSharingDataFilters;
  receivedSharingDetails?: Maybe<ReceivedSharing>;
  receivedSharingSummary?: Maybe<Array<ReceivedSharingSummaryByOrganization>>;
  receivedSharings?: Maybe<PaginatedReceivedSharing>;
  referenceIsAvailable?: Maybe<Scalars['Boolean']['output']>;
  shareableAssets: PaginatedAssets;
};

export type SharingIssuedSharingDetailsArgs = {
  id: Scalars['Int']['input'];
};

export type SharingIssuedSharingsArgs = {
  filters?: InputMaybe<IssuedSharingFilters>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  period: SharingPeriodFilter;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy: SharingSortBy;
};

export type SharingReceivedSharingDetailsArgs = {
  id: Scalars['Int']['input'];
};

export type SharingReceivedSharingSummaryArgs = {
  retainedOrganizationsCount?: InputMaybe<Scalars['Int']['input']>;
};

export type SharingReceivedSharingsArgs = {
  filters?: InputMaybe<ReceivedSharingFilters>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  period?: InputMaybe<SharingPeriodFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ReceivedSharingSortBy>;
};

export type SharingReferenceIsAvailableArgs = {
  internalReference: Scalars['String']['input'];
};

export type SharingShareableAssetsArgs = {
  assetFilters?: InputMaybe<GraphQlAssetFilter>;
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SharingAsset = {
  __typename?: 'SharingAsset';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum SharingFilterStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending',
  Planned = 'planned',
  Stopped = 'stopped',
}

export type SharingPeriodFilter = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type SharingSortBy = {
  field: IssuedSharingSortField;
  order: SortDirection;
};

export enum SharingStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending',
  Planned = 'planned',
  Stopped = 'stopped',
}

export type SharingUserDataFilter = {
  __typename?: 'SharingUserDataFilter';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type SinglePropertyValue = {
  __typename?: 'SinglePropertyValue';
  date: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

/** A site related to address. */
export type Site = {
  __typename?: 'Site';
  created_at?: Maybe<Scalars['String']['output']>;
  dateEnd?: Maybe<Scalars['String']['output']>;
  dateStart?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  linkedWorkspaces?: Maybe<Array<Maybe<Group>>>;
  userSites?: Maybe<Array<Maybe<UserSite>>>;
};

export type SortByField = {
  field: Scalars['String']['input'];
  order: SortOrder;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** SummarySubscriptionsType */
export type SummarySubscriptions = {
  __typename?: 'SummarySubscriptions';
  id?: Maybe<Scalars['String']['output']>;
  receiveMonthlySummary?: Maybe<Scalars['Boolean']['output']>;
  receiveWeeklySummary?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<Group>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type Trip = {
  __typename?: 'Trip';
  averageSpeedKmH?: Maybe<Scalars['Float']['output']>;
  dateStart?: Maybe<Scalars['String']['output']>;
  dateStop?: Maybe<Scalars['String']['output']>;
  distanceMeters?: Maybe<Scalars['Float']['output']>;
  firstPositionAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastPositionAddress?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Scalars['JSON']['output']>;
  simplifiedPositions?: Maybe<Scalars['JSON']['output']>;
};

/** Tyre Snapshot history row, grouped by date */
export type TyreHistoryRowType = {
  __typename?: 'TyreHistoryRowType';
  date?: Maybe<Scalars['String']['output']>;
  tyreSnapshots?: Maybe<Array<Maybe<TyreSnapshotItemType>>>;
};

export type TyreLastSnapshotType = {
  __typename?: 'TyreLastSnapshotType';
  date: Scalars['String']['output'];
  flags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['Int']['output'];
  position: TyreSnapshotPositionType;
  pressure: Scalars['Float']['output'];
  signalQuality?: Maybe<Scalars['String']['output']>;
  signalQualityScore?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
  tyrePositionIndex: Scalars['Int']['output'];
};

export type TyreSnapshotItemType = {
  __typename?: 'TyreSnapshotItemType';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  flags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['Int']['output'];
  position: TyreSnapshotPositionType;
  pressure: Scalars['Float']['output'];
  signalQuality?: Maybe<Scalars['String']['output']>;
  signalQualityScore?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
  tyrePositionIndex: Scalars['Int']['output'];
};

/** Tyre Position */
export type TyreSnapshotPositionType = {
  __typename?: 'TyreSnapshotPositionType';
  axleIndex?: Maybe<Scalars['Int']['output']>;
  isInner?: Maybe<Scalars['Boolean']['output']>;
  isLeft?: Maybe<Scalars['Boolean']['output']>;
};

export type UnarchiveAssetResult = {
  __typename?: 'UnarchiveAssetResult';
  id: Scalars['Int']['output'];
};

export type UsageHours = {
  __typename?: 'UsageHours';
  activeAssetsCount: Scalars['Int']['output'];
  averageActiveDaysPerUsedAssets: Scalars['Float']['output'];
  averageUsageHoursPerActiveDays: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  usedAssetsCount: Scalars['Int']['output'];
};

export type UsageHoursAsset = {
  __typename?: 'UsageHoursAsset';
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  averageUsageHoursPerActiveDays: Scalars['Float']['output'];
  make?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UsageHoursAssetPerformance = {
  __typename?: 'UsageHoursAssetPerformance';
  activeDays: Scalars['Int']['output'];
  assetId: Scalars['Int']['output'];
  assetInOrganizationId: Scalars['Int']['output'];
  averageUsageHoursPerActiveDays: Scalars['Float']['output'];
  categoryAvgUsageHoursPerActiveDays?: Maybe<Scalars['Float']['output']>;
  categoryAvgUsageHoursPerActiveDaysDelta?: Maybe<Scalars['Float']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  categoryUsedAssetsCount?: Maybe<Scalars['Int']['output']>;
  enterprise?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelAvgUsageHoursPerActiveDays?: Maybe<Scalars['Float']['output']>;
  modelAvgUsageHoursPerActiveDaysDelta?: Maybe<Scalars['Float']['output']>;
  modelUsedAssetsCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previousAvgUsageHoursPerActiveDays?: Maybe<Scalars['Float']['output']>;
  previousAvgUsageHoursPerActiveDaysDelta?: Maybe<Scalars['Float']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  sharingInformations?: Maybe<AssetSharingInformations>;
  source?: Maybe<Scalars['String']['output']>;
  usageHours: Scalars['Float']['output'];
};

export type UsageHoursCategoryItem = {
  __typename?: 'UsageHoursCategoryItem';
  activeAssetsCount: Scalars['Int']['output'];
  averageActiveDaysPerUsedAssets: Scalars['Float']['output'];
  averageUsageHoursPerActiveDays: Scalars['Float']['output'];
  categoryId?: Maybe<Scalars['Int']['output']>;
  usedAssetsCount: Scalars['Int']['output'];
};

export type UsageHoursHistoryItem = {
  __typename?: 'UsageHoursHistoryItem';
  activeAssetsCount: Scalars['Int']['output'];
  averageActiveDaysPerUsedAssets: Scalars['Float']['output'];
  averageUsageHoursPerActiveDays: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  usedAssetsCount: Scalars['Int']['output'];
};

export type UsageHoursOnPeriodItem = {
  __typename?: 'UsageHoursOnPeriodItem';
  activeAssetsCount: Scalars['Int']['output'];
  averageActiveDaysPerUsedAssets: Scalars['Float']['output'];
  averageUsageHoursPerActiveDays: Scalars['Float']['output'];
  /** @deprecated Use period instead */
  dateEnd: Scalars['Date']['output'];
  /** @deprecated Use period instead */
  dateStart: Scalars['Date']['output'];
  period: DatePeriod;
  usedAssetsCount: Scalars['Float']['output'];
};

export type UsageHoursOnPeriodItemPeriodComparison = {
  __typename?: 'UsageHoursOnPeriodItemPeriodComparison';
  current?: Maybe<UsageHoursOnPeriodItem>;
  previous?: Maybe<UsageHoursOnPeriodItem>;
};

export type UsageHoursPerformanceSortBy = {
  field: UsageHoursPerformanceSortField;
  order: SortOrder;
};

export enum UsageHoursPerformanceSortField {
  AverageUsageHoursPerActiveDays = 'averageUsageHoursPerActiveDays',
  Category = 'category',
  CategoryAvgUsageHoursPerActiveDaysDelta = 'categoryAvgUsageHoursPerActiveDaysDelta',
  CurrentActiveDays = 'currentActiveDays',
  CurrentUsageHours = 'currentUsageHours',
  Enterprise = 'enterprise',
  Make = 'make',
  Model = 'model',
  ModelAvgUsageHoursPerActiveDaysDelta = 'modelAvgUsageHoursPerActiveDaysDelta',
  Name = 'name',
  PreviousAvgUsageHoursPerActiveDaysDelta = 'previousAvgUsageHoursPerActiveDaysDelta',
}

export type UsageHoursReport = {
  __typename?: 'UsageHoursReport';
  assetList?: Maybe<Array<Maybe<UsageHoursAsset>>>;
  assetsPerformance?: Maybe<PaginatedUsageHoursAssetPerformance>;
  byCategory?: Maybe<Array<Maybe<UsageHoursCategoryItem>>>;
  currentMonth?: Maybe<UsageHoursOnPeriodItemPeriodComparison>;
  currentWeek?: Maybe<UsageHoursOnPeriodItemPeriodComparison>;
  currentYear?: Maybe<UsageHoursOnPeriodItemPeriodComparison>;
  history: Array<Maybe<UsageHoursHistoryItem>>;
  statisticsCustomPeriod?: Maybe<UsageHoursOnPeriodItemPeriodComparison>;
  usageHours?: Maybe<Array<Maybe<UsageHours>>>;
};

export type UsageHoursReportAssetListArgs = {
  assetFilters: ReportAssetFilter;
  dataSource: AssetListDataSource;
  period: Period;
};

export type UsageHoursReportAssetsPerformanceArgs = {
  assetFilters: ReportAssetFilter;
  pagination: Pagination;
  period: Period;
  sortBy: UsageHoursPerformanceSortBy;
};

export type UsageHoursReportByCategoryArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
};

export type UsageHoursReportCurrentMonthArgs = {
  assetFilters: ReportAssetFilter;
};

export type UsageHoursReportCurrentWeekArgs = {
  assetFilters: ReportAssetFilter;
};

export type UsageHoursReportCurrentYearArgs = {
  assetFilters: ReportAssetFilter;
};

export type UsageHoursReportHistoryArgs = {
  assetFilters: ReportAssetFilter;
  periodScale: PeriodScale;
};

export type UsageHoursReportStatisticsCustomPeriodArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
};

export type UsageHoursReportUsageHoursArgs = {
  assetFilters: ReportAssetFilter;
  period: Period;
  periodScale: PeriodScale;
};

/** Current user */
export type User = {
  __typename?: 'User';
  admin?: Maybe<Scalars['Boolean']['output']>;
  adminOfAtLeastOneWorkspace: Scalars['Boolean']['output'];
  adminWorkspaces?: Maybe<Array<Maybe<Group>>>;
  apiAccount?: Maybe<Scalars['Boolean']['output']>;
  /** CO2 Emission Report queries */
  co2Report: Co2Report;
  createdAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  faultsGroupedByAsset?: Maybe<GetFaultsGroupedByAssetType>;
  faultsGroupedByAssetByZone?: Maybe<ZoneFaultsGroupedByAsset>;
  faultsGroupedByAssetPaginated?: Maybe<GetFaultsGroupedByAssetPaginatedType>;
  faultsWithTranslation?: Maybe<PaginatedFaultWithTranslationItemType>;
  faultsWithTranslationStatistics?: Maybe<FaultWithTranslationStatisticsType>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** Fleet data queries */
  fleetData: FleetData;
  /** @deprecated Group will be replace by Workspace. Use workspace instead of group key */
  group?: Maybe<Group>;
  hourlyConsumptionAssetList?: Maybe<Array<Maybe<HourlyConsumptionAsset>>>;
  hourlyConsumptionAssetsPerformance?: Maybe<PaginatedHourlyConsumptionAssetPerformanceRow>;
  hourlyConsumptionAverage?: Maybe<HourlyConsumptionComparativeAverage>;
  hourlyConsumptionByCategory?: Maybe<
    Array<Maybe<HourlyConsumptionMultipleSimpleValueItem>>
  >;
  hourlyConsumptionCurrentMonth?: Maybe<HourlyConsumptionComparativePeriod>;
  hourlyConsumptionCurrentWeek?: Maybe<HourlyConsumptionComparativePeriod>;
  hourlyConsumptionCurrentYear?: Maybe<HourlyConsumptionComparativePeriod>;
  hourlyConsumptionCustomPeriod?: Maybe<
    Array<Maybe<HourlyConsumptionSerieResultItem>>
  >;
  hourlyConsumptionEnterprisesPerformance?: Maybe<PaginatedHourlyConsumptionEnterprisesPerformanceRow>;
  hourlyConsumptionHistory?: Maybe<Array<Maybe<HourlyConsumptionHistoryItem>>>;
  id: Scalars['String']['output'];
  idleRateAssetList?: Maybe<Array<Maybe<IdleRateAsset>>>;
  idleRateAssetsPerformance?: Maybe<PaginatedIdleRateAssetPerformanceRow>;
  idleRateEnterprisesPerformance?: Maybe<PaginatedIdleRateEnterprisesPerformanceRow>;
  idleRateReportCurrentMonth?: Maybe<CurrentMonthIdleRate>;
  idleRateReportCurrentWeek?: Maybe<CurrentWeekIdleRate>;
  idleRateReportCurrentYear?: Maybe<CurrentYearIdleRate>;
  idleRateReportLastDays?: Maybe<LastDaysIdleRate>;
  idleReportDailyIdleRates?: Maybe<Array<Maybe<GetDailyIdleRateSerieResult>>>;
  idleReportIdleRateHistory?: Maybe<
    Array<Maybe<GetIdleRateHistorySerieResult>>
  >;
  idleReportIdleRatesByCategory?: Maybe<
    Array<Maybe<GetIdleRateByCategoryMultipleSimpleValue>>
  >;
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastSeen?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notifications?: Maybe<Array<Notification>>;
  organization?: Maybe<Organization>;
  pendingInvitations?: Maybe<Array<Maybe<InvitationType>>>;
  sharing: Sharing;
  unit?: Maybe<Scalars['String']['output']>;
  /** Usage Hours Report queries */
  usageHoursReport: UsageHoursReport;
  userFile?: Maybe<UserFileType>;
  usersAndRolesFromWorkspace?: Maybe<
    Array<Maybe<UserAndRoleFromWorkspaceType>>
  >;
  workspace?: Maybe<Group>;
  workspaceRoles?: Maybe<Array<Maybe<UserWorkspaceRoleType>>>;
  workspaceRoot?: Maybe<Group>;
  workspaceSummarySubscriptions?: Maybe<SummarySubscriptions>;
  workspaces?: Maybe<Array<Maybe<Group>>>;
  workspacesNoRoot?: Maybe<Array<Maybe<Group>>>;
};

/** Current user */
export type UserFaultsGroupedByAssetArgs = {
  filters?: InputMaybe<FaultsFilters>;
  period?: InputMaybe<FaultPeriod>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserFaultsGroupedByAssetByZoneArgs = {
  filters?: InputMaybe<ZoneFaultsGroupedByAssetFilters>;
  id: Scalars['String']['input'];
  period: Period;
};

/** Current user */
export type UserFaultsGroupedByAssetPaginatedArgs = {
  filters?: InputMaybe<FaultsFilters>;
  pagination: Pagination;
  period?: InputMaybe<FaultPeriod>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserFaultsWithTranslationArgs = {
  filters?: InputMaybe<FaultsFilters>;
  pagination?: InputMaybe<Pagination>;
  period?: InputMaybe<FaultPeriod>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Array<InputMaybe<SortByField>>>;
};

/** Current user */
export type UserFaultsWithTranslationStatisticsArgs = {
  filters: FaultsFilters;
  period: FaultPeriod;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserGroupArgs = {
  currentWorkspaceId?: InputMaybe<Scalars['Float']['input']>;
};

/** Current user */
export type UserHourlyConsumptionAssetListArgs = {
  assetFilters: ReportAssetFilter;
  dataSource: AssetListDataSource;
  period: Period;
};

/** Current user */
export type UserHourlyConsumptionAssetsPerformanceArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  sortBy: Array<HourlyConsumptionAssetsPerformanceSort>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserHourlyConsumptionAverageArgs = {
  assetFilters: ReportAssetFilter;
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

/** Current user */
export type UserHourlyConsumptionByCategoryArgs = {
  assetFilters: ReportAssetFilter;
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

/** Current user */
export type UserHourlyConsumptionCurrentMonthArgs = {
  assetFilters: ReportAssetFilter;
};

/** Current user */
export type UserHourlyConsumptionCurrentWeekArgs = {
  assetFilters: ReportAssetFilter;
};

/** Current user */
export type UserHourlyConsumptionCurrentYearArgs = {
  assetFilters: ReportAssetFilter;
};

/** Current user */
export type UserHourlyConsumptionCustomPeriodArgs = {
  assetFilters: ReportAssetFilter;
  periodScale: PeriodScale;
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

/** Current user */
export type UserHourlyConsumptionEnterprisesPerformanceArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  sortBy: Array<HourlyConsumptionEnterprisesPerformanceSort>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserHourlyConsumptionHistoryArgs = {
  assetFilters: ReportAssetFilter;
  periodScale: PeriodScale;
};

/** Current user */
export type UserIdleRateAssetListArgs = {
  assetFilters: ReportAssetFilter;
  dataSource: AssetListDataSource;
  period: Period;
};

/** Current user */
export type UserIdleRateAssetsPerformanceArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  sortBy: Array<IdleRateAssetsPerformanceSort>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserIdleRateEnterprisesPerformanceArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['String']['input']>;
  sortBy: Array<IdleRateEnterprisesPerformanceSort>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserIdleRateReportCurrentMonthArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
};

/** Current user */
export type UserIdleRateReportCurrentWeekArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
};

/** Current user */
export type UserIdleRateReportCurrentYearArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
};

/** Current user */
export type UserIdleRateReportLastDaysArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserIdleReportDailyIdleRatesArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  periodScale?: InputMaybe<PeriodScale>;
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserIdleReportIdleRateHistoryArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  periodScale?: InputMaybe<PeriodScale>;
};

/** Current user */
export type UserIdleReportIdleRatesByCategoryArgs = {
  assetFilters?: InputMaybe<ReportAssetFilter>;
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};

/** Current user */
export type UserNotificationsArgs = {
  currentWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  since?: InputMaybe<Scalars['DateTime']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Current user */
export type UserUserFileArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
};

/** Current user */
export type UserUsersAndRolesFromWorkspaceArgs = {
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

/** Current user */
export type UserWorkspaceArgs = {
  currentWorkspaceId?: InputMaybe<Scalars['Float']['input']>;
};

/** Current user */
export type UserWorkspaceRolesArgs = {
  currentWorkspaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** User and Role from workspace */
export type UserAndRoleFromWorkspaceType = {
  __typename?: 'UserAndRoleFromWorkspaceType';
  role?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** UserFile object */
export type UserFileType = {
  __typename?: 'UserFileType';
  comment?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** link between users and site. */
export type UserSite = {
  __typename?: 'UserSite';
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** User and workspace relation with role */
export type UserWorkspaceRoleType = {
  __typename?: 'UserWorkspaceRoleType';
  admin?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  infoOnParentWorkspace?: Maybe<GetInfoOnParentWorkspaceType>;
  order?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<Group>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Value and assets count */
export type ValueAndAssetsCount = {
  __typename?: 'ValueAndAssetsCount';
  assetsCount: Scalars['Int']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type Values = MultiplePropertiesValue | SinglePropertyValue;

export type WorkspaceAndRoleInput = {
  role?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspaceAssetFilters = {
  __typename?: 'WorkspaceAssetFilters';
  categories: Array<Category>;
  enterprises: Array<Enterprise>;
  makers: Array<Scalars['String']['output']>;
  models: Array<Scalars['String']['output']>;
  names: Array<Scalars['String']['output']>;
  sharing: AssetSharingDataFilters;
  sources: Array<Scalars['String']['output']>;
};

export type WorkspaceTyreConditionType = {
  __typename?: 'WorkspaceTyreConditionType';
  assetLastTyreSnapshots?: Maybe<Array<Maybe<AssetTyreLastSnapshotsType>>>;
  hasTyreSnapshot: Scalars['Boolean']['output'];
  lastRiskyTyreSnapshots?: Maybe<Array<Maybe<AssetTyreLastSnapshotsType>>>;
  riskyTyreCount: Scalars['Int']['output'];
  totalTyreCount: Scalars['Int']['output'];
};

export type Zone = {
  __typename?: 'Zone';
  area?: Maybe<FeatureCollection>;
  category?: Maybe<ZoneCategory>;
  center?: Maybe<PointType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  searchedAddress?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

/** A zone category */
export type ZoneCategory = {
  __typename?: 'ZoneCategory';
  color: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** A zone category search result object type */
export type ZoneCategorySearchResult = {
  __typename?: 'ZoneCategorySearchResult';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Faults list for a zone */
export type ZoneFaults = {
  __typename?: 'ZoneFaults';
  faultsWithTranslation?: Maybe<Array<Maybe<FaultsWithTranslation>>>;
  faultsWithTranslationStatistics?: Maybe<FaultWithTranslationStatisticsType>;
};

export type ZoneFaultsFilters = {
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  enterprises?: InputMaybe<Array<Scalars['String']['input']>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Array<Scalars['Int']['input']>>;
  makers?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Faults list grouped by asset for a zone */
export type ZoneFaultsGroupedByAsset = {
  __typename?: 'ZoneFaultsGroupedByAsset';
  faultSeverityCounts: Array<AssetFaultSeverityCountsItemType>;
  faultsWithTranslationStatistics?: Maybe<FaultWithTranslationStatisticsType>;
};

export type ZoneFaultsGroupedByAssetFilters = {
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  enterprises?: InputMaybe<Array<Scalars['String']['input']>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Array<Scalars['Int']['input']>>;
  makers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ZoneFilters = {
  zoneCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An asset presence range in a zone */
export type ZonePresence = {
  __typename?: 'ZonePresence';
  asset: Asset;
  entryDate: Scalars['DateTime']['output'];
  exitDate?: Maybe<Scalars['DateTime']['output']>;
  fuel?: Maybe<FuelSum>;
  id: Scalars['ID']['output'];
  usage?: Maybe<DailyUsageStatisticsType>;
  zoneId: Scalars['ID']['output'];
};

export type ZonePresenceFilters = {
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  enterprises?: InputMaybe<Array<Scalars['String']['input']>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  makers?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A zone presence range for a specific asset */
export type ZonePresenceForAsset = {
  __typename?: 'ZonePresenceForAsset';
  entryDate: Scalars['DateTime']['output'];
  exitDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  usage?: Maybe<DailyUsageStatisticsType>;
  zone?: Maybe<Zone>;
};

/** result of zone search by keyword */
export type ZoneSearchResult = {
  __typename?: 'ZoneSearchResult';
  category?: Maybe<ZoneCategorySearchResult>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};
