import { BookIcon, InlineLink } from 'modules/common-ui';
import type React from 'react';
import { LinkText, SubHeaderContainer } from './SubHeaderLink.css';

type BackLink = {
  label: string;
  url: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type SubHeaderLinkProps = {
  text: string;
  url: string;
  backLink?: BackLink;
};

export const SubHeaderLink = ({ text, url, backLink }: SubHeaderLinkProps) => {
  return (
    <SubHeaderContainer>
      <InlineLink to={url}>
        <BookIcon size={16} style={{ flexShrink: 0 }} />
        <LinkText>{text}</LinkText>
      </InlineLink>
      {backLink && (
        <InlineLink onClick={backLink.onClick} target="_self" to={backLink.url}>
          {backLink.icon}
          <span style={{ marginLeft: '8px' }}>{backLink.label}</span>
        </InlineLink>
      )}
    </SubHeaderContainer>
  );
};
