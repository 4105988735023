import colors from 'constants/colors';
import styled from 'styled-components';

export const DeleteBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  button {
    margin-top: 8px;
  }
`;

export const DeleteMessage = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${colors.gray850};
  width: 100%;
`;

export const DeleteSeparator = styled.div`
  margin-top: 16px;
`;
