import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type HourlyConsumptionCellProps = {
  hourlyConsumption: number | null;
};

const Container = styled.div`
  display: flex;
`;

export const HourlyConsumptionCell = ({
  hourlyConsumption,
}: HourlyConsumptionCellProps) => {
  const { t } = useTranslation('analytics');

  const value =
    hourlyConsumption === null
      ? '-'
      : t('reports.hourlyConsumptionValue', {
          value: Math.round(hourlyConsumption * 10) / 10,
        });
  return <Container>{value}</Container>;
};
