import moment from 'moment';
import type { PeriodScale } from 'utils/time';
import type { LineChartData } from '../components/Dataviz/LineChart/LineChart';

const createMissingPoint = (date: Date): LineChartData => {
  return {
    date,
    value: 0,
    missing: true,
  };
};

const findPointWithDate = (
  orderedData: LineChartData[],
  date: Date,
  periodGranularity: PeriodScale,
) => {
  return orderedData.find((point) => {
    return moment(point.date).isSame(date, periodGranularity);
  });
};

export const fillEmptyData = (
  orderedData: LineChartData[],
  period: { since: Date; until: Date },
  periodGranularity: PeriodScale,
): LineChartData[] => {
  const filledData: LineChartData[] = [];
  const { since, until } = period;
  if (until <= since) {
    return orderedData;
  }

  let date: Date = since;
  while (moment(date).isSameOrBefore(until, periodGranularity)) {
    const dataPoint = findPointWithDate(orderedData, date, periodGranularity);

    filledData.push(dataPoint ? dataPoint : createMissingPoint(date));
    date = moment(date).add(1, periodGranularity).toDate();
  }

  return filledData;
};
