import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 0;
  border-radius: 5px;
  margin-top: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: bottom;
  justify-content: space-between;
  padding-bottom: 8px;
  gap: 5px;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 10px;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    justify-content: end;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.gray800};
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.gray600};
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;
