import * as Sentry from '@sentry/browser';

import { Button, CircularSpinner, HModal } from 'modules/common-ui';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import type { Store } from 'reducers';
import type { FleetSegment } from '../../../../../common-ui/models/FleetSegment';
import { useDeleteFleetSegmentMutation } from '../../gql.generated';
import { DeleteBody, DeleteMessage, DeleteSeparator } from './styles.css';

type SegmentDeleteProps = {
  fleetSegment: FleetSegment;
  onCancel: () => void;
  onSegmentDeleted: (fleetSegment: FleetSegment) => void;
};

export const SegmentDelete = ({
  fleetSegment,
  onCancel,
  onSegmentDeleted,
}: SegmentDeleteProps) => {
  const { t } = useTranslation('fleetSegment');
  const [deleteFleetSegmentLoading, setDeleteFleetSegmentLoading] =
    useState(false);
  const [deleteFleetSegment] = useDeleteFleetSegmentMutation();

  const { id, name } = fleetSegment;
  const workspaceName = useSelector<Store, string | null>(
    (state) => state.user?.group || 'N/A',
  );

  const handleConfirmDeleteSegment = async () => {
    try {
      setDeleteFleetSegmentLoading(true);
      await deleteFleetSegment({
        variables: {
          id,
        },
      });
      onSegmentDeleted(fleetSegment);
      toast.success(t('toast.delete.success', { name }), {
        position: 'top-right',
      });
    } catch (error) {
      toast.error(t('toast.delete.error'), {
        position: 'top-right',
      });
      Sentry.captureException(error);
    }
    setDeleteFleetSegmentLoading(false);
  };

  return (
    <>
      <HModal
        onRequestClose={onCancel}
        isOpen={true}
        style={{ width: '302px', minWidth: '302px' }}
      >
        <DeleteBody>
          <DeleteMessage>
            <Trans
              i18nKey="fleetSegment:modal.delete.message.workspace"
              components={{ 1: <strong /> }}
              values={{ workspaceName }}
            />
            <DeleteSeparator />
            <Trans
              i18nKey="fleetSegment:modal.delete.message.segment"
              components={{ 1: <strong /> }}
              values={{ name }}
            />
          </DeleteMessage>
          <Button
            block
            disabled={deleteFleetSegmentLoading}
            variant="outline-danger"
            onClick={handleConfirmDeleteSegment}
          >
            {deleteFleetSegmentLoading ? (
              <CircularSpinner />
            ) : (
              t('modal.delete.confirm')
            )}
          </Button>
          <Button block onClick={onCancel} disabled={deleteFleetSegmentLoading}>
            {t('modal.delete.cancel')}
          </Button>
        </DeleteBody>
      </HModal>
    </>
  );
};
