import type { TFunction } from 'i18next';
import { segmentToFiltersAdapter } from 'modules/common-ui';
import type { FilterObject } from 'modules/common-ui/components/Filter/FilterObject.type';
import { FleetSegment } from '../../../../../common-ui/models/FleetSegment';
import type { FleetSegmentGqlFragment } from '../fleetSegmentList/gql.generated';

export const fleetSegmentGQLListToEntities = (
  fleetSegmentGQLList: Array<FleetSegmentGqlFragment>,
  filterOptions: FilterObject[],
  t: TFunction<'filter', undefined>,
): Array<FleetSegment> => {
  const fleetSegmentList = fleetSegmentGQLList.map((segment) => {
    const { id, assetCount, kind } = segment;
    const name = kind !== 'custom' ? t(segment.name) : segment.name;
    const filters = segmentToFiltersAdapter(filterOptions, segment);

    return new FleetSegment(id, name, assetCount, filters, kind);
  });
  return fleetSegmentList;
};
