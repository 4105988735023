import layout from 'modules/layout';
import { StickyHeaderLayout } from 'modules/layout/sticky-header/StickyHeader.layout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { Store } from 'reducers';
import { BodyContent } from './components/BodyContent';

export const Home = () => {
  const user = useSelector((state: Store) => state.user);

  const { t } = useTranslation('newHome');

  return (
    <StickyHeaderLayout
      title={user.group || 'N/A'}
      isNew
      actionContent={null}
      subheaderContent={t('header.welcome', { firstname: user.firstName })}
      displaySubheader={true}
      bodyContent={
        <layout.Section vertical>
          <BodyContent />
        </layout.Section>
      }
      fullWidth={true}
      noBottomPadding
    />
  );
};
