import { MissingValueWrapper } from 'modules/analytics/components/common/table/cells/MissingValueWrapper';
import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';

export const DataCell = ({
  value,
  unit,
  title,
}: {
  value?: number | null;
  title: string | null;
  unit: Unit;
}) => {
  const { t } = useTranslation('analytics');
  return (
    <MissingValueWrapper value={value} title={title}>
      <span>{displayValueAndUnit(value || 0, unit, t)}</span>
    </MissingValueWrapper>
  );
};
