import type { SelectedFilter } from '../components/Filter/SelectedFilter.type';

export class FleetSegment {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly assetCount: number,
    public readonly filters: SelectedFilter[],
    public readonly kind: string,
  ) {}

  get isHiboo(): boolean {
    return this.kind !== 'custom';
  }

  get isDefault(): boolean {
    return this.kind === 'default';
  }
}
