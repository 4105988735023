import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import { CustomTooltip } from '../../CustomTooltip/CustomTooltip';
import { ArrowRoundIcon } from '../../icons/ArrowRoundIcon';

export type DeltaCellProps = {
  delta: number;
  previousPeriodValue?: number | null;
  unit: Unit;
  deltaUnit: Unit;
  hideArrow?: boolean;
  tooltipInfos?: {
    name: string;
    id: string;
    assetsCount: number;
  };
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Value = styled.div`
  font-size: 14px;
  color: ${colors.gray900};
`;

const PreviousValue = styled.div`
  font-size: 12px;
  color: ${colors.gray700};
`;

const TooltipTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: normal;
`;

const TooltipContent = styled.div`
  font-size: 11px;
  line-height: normal;
`;

export const DeltaCell = ({
  delta,
  deltaUnit,
  previousPeriodValue,
  unit,
  hideArrow = false,
  tooltipInfos,
}: DeltaCellProps) => {
  const { t } = useTranslation('analytics');

  const renderCell = () => {
    const iconDirection = delta === 0 ? 'flat' : delta > 0 ? 'up' : 'down';
    const iconColor = delta >= 0 ? 'red' : 'green';

    const value =
      delta > 0
        ? `+${displayValueAndUnit(delta, deltaUnit, t)}`
        : `${displayValueAndUnit(delta, deltaUnit, t)}`;
    return (
      <>
        {tooltipInfos && (
          <CustomTooltip
            place={'right'}
            tooltipId={`tooltip-${tooltipInfos.id}`}
            tooltip={
              <>
                <TooltipTitle>{tooltipInfos.name}</TooltipTitle>
                <TooltipContent>
                  {t('reports.basedOn', {
                    assetsCount: tooltipInfos.assetsCount,
                  })}
                </TooltipContent>
              </>
            }
          />
        )}
        {!hideArrow && (
          <ArrowRoundIcon color={iconColor} direction={iconDirection} />
        )}
        <ValueContainer
          data-for={tooltipInfos && `tooltip-${tooltipInfos.id}`}
          data-tip={tooltipInfos && `tooltip-${tooltipInfos.id}`}
        >
          <Value>{value}</Value>
          {previousPeriodValue ? (
            <PreviousValue>
              {displayValueAndUnit(previousPeriodValue, unit, t)}
            </PreviousValue>
          ) : (
            '-'
          )}
        </ValueContainer>
      </>
    );
  };

  return <Container>{renderCell()}</Container>;
};
