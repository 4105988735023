import type React from 'react';
import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import { ClickToViewDrawer } from '../ClickToViewDrawer/ClickToViewDrawer';
import {
  TooltipContainer,
  TooltipDate,
  TooltipDetail,
  TooltipLabel,
  TooltipSection,
  TooltipValue,
  TootlTipDiv,
  TootlTipPreviousPeriod,
} from './styles.css';

export const TooltipContent = ({
  currentPeriodLabel,
  currentValue,
  currentValueDetails,
  previousPeriodLabel,
  previousValue,
  previousValueDetails,
  businessDayInfo,
  unit,
  label,
  hideDrawerClickAction = false,
}: {
  currentPeriodLabel: string;
  currentValue: number | null;
  currentValueDetails?: string | React.ReactChild;
  previousPeriodLabel: string;
  previousValue: number | null;
  previousValueDetails?: string | React.ReactChild;
  businessDayInfo?: React.ReactChild;
  unit: Unit;
  label: string;
  hideDrawerClickAction?: boolean;
}) => {
  const { t } = useTranslation('analytics');
  return (
    <TooltipContainer>
      <TootlTipDiv>
        <TooltipDate>{currentPeriodLabel}</TooltipDate>
        <TooltipSection>
          <TooltipLabel>{label}</TooltipLabel>
          <TooltipValue>
            {currentValue === null
              ? '-'
              : displayValueAndUnit(currentValue, unit, t)}
          </TooltipValue>
        </TooltipSection>
        {currentValueDetails && (
          <TooltipSection>
            <TooltipLabel>{t('reports.common.usedAssets')}</TooltipLabel>
            <TooltipDetail>{currentValueDetails}</TooltipDetail>
          </TooltipSection>
        )}
        {businessDayInfo && (
          <TooltipSection>
            <TooltipLabel>{t('reports.common.businessDays')}</TooltipLabel>
            <TooltipDetail>{businessDayInfo}</TooltipDetail>
          </TooltipSection>
        )}
      </TootlTipDiv>

      {!hideDrawerClickAction && (
        <TootlTipDiv
          dangerouslySetInnerHTML={{ __html: ClickToViewDrawer(t) }}
        />
      )}

      <TootlTipPreviousPeriod>
        <TooltipDate>{previousPeriodLabel}</TooltipDate>
        <TooltipSection>
          <TooltipLabel>{label}</TooltipLabel>
          <TooltipValue>
            {previousValue === null
              ? '-'
              : displayValueAndUnit(previousValue, unit, t)}
          </TooltipValue>
        </TooltipSection>
        {previousValueDetails && (
          <TooltipSection>
            <TooltipLabel>{t('reports.common.usedAssets')}</TooltipLabel>
            <TooltipDetail>{previousValueDetails}</TooltipDetail>
          </TooltipSection>
        )}
      </TootlTipPreviousPeriod>
    </TooltipContainer>
  );
};
