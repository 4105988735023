import colors from 'constants/colors';
import styled from 'styled-components';

export const AdditionalInfosContainer = styled.div`
  display: flex;
  background-color: ${colors.lightGrey};
  padding: 14px 16px;
  width: 287px;
  margin-bottom: 22px;
`;

export const AdditionalInfos = styled.p`
  margin-left: 13px;
  margin-bottom: 0;
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

export const TitleInfo = styled.div``;

export const TitleInfoLink = styled.a`
  color: ${colors.blue100};

  &:hover {
    color: ${colors.blue300};
  }
  font-weight: 500;
`;

export const TooltipContainer = styled.div`
  max-width: 182px;
`;
