import * as Sentry from '@sentry/browser';
import { getGraphQLCodeError } from 'graphql/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { FleetSegment } from '../../../../../common-ui/models/FleetSegment';
import { useUpdateFleetSegmentMutation } from '../../gql.generated';
import type {
  AssetFilterOptions,
  FleetSegmentForm,
  SegmentErrors,
} from '../../types';
import {
  selectedFiltersToObject,
  transformFiltersToGqlFilters,
} from '../../utils';
import { SegmentMutateModal } from '../SegmentMutateModal';

type SegmentEditProps = {
  onSegmentEdited: (
    fleetSegmentId: number,
    filters: AssetFilterOptions,
  ) => void;
  onCancel: () => void;
  initialFleetSegment: FleetSegment;
};

export const SegmentEdit = ({
  onSegmentEdited,
  onCancel,
  initialFleetSegment,
}: SegmentEditProps) => {
  const { t } = useTranslation('filter');
  const [segmentErrors, setSegmentErrors] = useState<SegmentErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [updateFleetSegment] = useUpdateFleetSegmentMutation();

  const onSegmentEdit = async (fleetSegment: FleetSegmentForm) => {
    if (!fleetSegment) return;
    try {
      setIsLoading(true);
      const response = await updateFleetSegment({
        variables: {
          id: fleetSegment.id,
          name: fleetSegment.name,
          filters: transformFiltersToGqlFilters(
            selectedFiltersToObject(fleetSegment.filters || []),
          ),
        },
      });
      const updatedFleetSegment = response.data!.updateFleetSegment;

      if (!updatedFleetSegment) {
        throw new Error();
      }

      onSegmentEdited(
        updatedFleetSegment.id,
        selectedFiltersToObject(fleetSegment.filters),
      );
      toast.success(
        t('fleetSegment:toast.edit.success', { name: fleetSegment.name }),
        {
          position: 'top-right',
        },
      );
    } catch (error: any) {
      const errorCode = getGraphQLCodeError(error);
      switch (errorCode) {
        case 'NAME_ALREADY_EXISTS': {
          setSegmentErrors({
            name: [t('fleetSegment:form.fields.name.name_already_exists')],
          });
          break;
        }
        default:
          toast.error(
            t('fleetSegment:toast.edit.error', { name: fleetSegment.name }),
            {
              position: 'top-right',
            },
          );
          Sentry.captureException(error);
          break;
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <SegmentMutateModal
        isOpen={true}
        isLoading={isLoading}
        segmentErrors={segmentErrors}
        onCancel={onCancel}
        onOutsideClick={() => {}}
        onEdit={onSegmentEdit}
        resetSegmentErrors={() => setSegmentErrors({})}
        initialFleetSegment={{ ...initialFleetSegment, isValid: true }}
      />
    </>
  );
};
