import { convertFactorToRoundedPercent } from 'modules/analytics/reports/idleRate/lib/utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { displayValueAndUnit } from 'utils/unit';

type RateCellProps = {
  rate: number | null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RateCell = ({ rate }: RateCellProps) => {
  const { t } = useTranslation('analytics');
  const value =
    rate !== null
      ? displayValueAndUnit(convertFactorToRoundedPercent(rate), '%', t)
      : '-';
  return <Container>{value}</Container>;
};
