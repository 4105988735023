import {
  FAVORITES_FILTER_VALUES,
  FILTER_TYPES,
  type FilterTypeKey,
  SHARING_IN_PROGRESS_STATUS_FILTER_VALUES,
} from 'constants/filter';
import type { FilterObject } from 'modules/common-ui/components/Filter/FilterObject.type';
import type { SelectedFilter } from 'modules/common-ui/components/Filter/SelectedFilter.type';
import type {
  ActiveAssetFilters,
  AssetExtraFilterKey,
  AssetExtraFilterResult,
  AssetFilterGQL,
  AssetFilterOptions,
} from './types';

export const selectedFiltersToObject = (filters: SelectedFilter[]) => {
  return filters.reduce((acc, filter) => {
    return Object.assign(acc, { [filter.type]: filter.value });
  }, {});
};

export const transformFiltersToGqlFilters = (
  assetFilters: AssetFilterOptions,
) => {
  const filters: AssetFilterGQL = {};
  Object.keys(assetFilters).forEach((key) => {
    if (
      !assetFilters[key as FilterTypeKey] ||
      assetFilters[key as FilterTypeKey]?.length === 0
    )
      return;
    switch (key) {
      case FILTER_TYPES.ASSET_NAME:
        filters.names = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.BRAND:
        filters.makers = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.CATEGORY:
        filters.categories = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.ENTERPRISE:
        filters.enterprises = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.MODEL:
        filters.models = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.SOURCE:
        filters.sources = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.SHARING_REFERENCE:
        filters.sharingReferences = assetFilters[key]!.map(
          (item) => item.value,
        );
        break;
      case FILTER_TYPES.SHARING_IN_PROGRESS_STATUS:
        filters.sharingInProgressStatus =
          assetFilters[key]! === SHARING_IN_PROGRESS_STATUS_FILTER_VALUES.YES;
        break;
      case FILTER_TYPES.SHARED_BY_ORGANIZATION:
        filters.sharedByOrganizations = assetFilters[key]!.map((item) =>
          Number(item.value),
        );
        break;
      case FILTER_TYPES.SHARED_WITH_ORGANIZATION:
        filters.sharedWithOrganizations = assetFilters[key]!.map((item) =>
          Number(item.value),
        );
        break;
      case FILTER_TYPES.FAVORITE:
        filters.favorite = assetFilters[key]! === FAVORITES_FILTER_VALUES.YES;
        break;
      case FILTER_TYPES.ZONE:
        filters.zones = assetFilters[key]!.map((item) => item.value);
        break;
      case FILTER_TYPES.FAULT_SEVERITY:
        break;
      // custom fields
      default:
        const customValues = (assetFilters as any)[key];
        if (
          customValues === undefined ||
          !customValues.length ||
          !key.includes(FILTER_TYPES.CUSTOM_FIELD_V2)
        )
          return;
        if (filters.customfields === undefined) filters.customfields = [];
        const splitKey = key.split('_');
        filters.customfields.push({
          id: Number(splitKey[splitKey.length - 1]),
          values: customValues.map((item: any) => item.value),
        });
    }
  });
  return filters;
};

export const computeActiveFilters = (
  appliedFilters: AssetFilterOptions,
  filterOptions: FilterObject[],
): ActiveAssetFilters => {
  const activeAssetFilters: ActiveAssetFilters = [];
  Object.keys(appliedFilters).forEach((filterKey) => {
    const filterOption = filterOptions.find((f) => f.type === filterKey);
    if (!filterOption) return;
    const appliedFilter = appliedFilters[filterKey as FilterTypeKey];
    if (!appliedFilter || appliedFilter.length === 0) return;
    const isBooleanFilter = typeof appliedFilter === 'string';
    const options = filterOption.options || [];
    if (isBooleanFilter) {
      const selectedOption = options.find(
        (option) => option.value === appliedFilter,
      );
      const label = selectedOption?.label || appliedFilter;
      activeAssetFilters.push({
        label: '',
        allOptionsSelected: false,
        values: [label],
      });
      return;
    }
    activeAssetFilters.push({
      label: filterOption.label,
      allOptionsSelected: options.length === appliedFilter.length,
      values: appliedFilter.map((item) => item.label),
    });
  });
  return activeAssetFilters;
};

export const computeExtraFilterValues = (
  appliedFilters: AssetFilterOptions,
  extraFilterOptions: AssetExtraFilterKey[] = [],
) => {
  const extraValues: Partial<AssetExtraFilterResult> = {};
  for (const extraFilterKey of extraFilterOptions) {
    extraValues[extraFilterKey] = appliedFilters[extraFilterKey] || [];
  }
  return extraValues;
};
