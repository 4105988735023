import isEmpty from 'lodash/isEmpty';
import { Field, TextInput, Tooltip } from 'modules/common-ui';
import {
  type CalloutOnFilter,
  FilterSelector,
} from 'modules/common-ui/components/Filter/FilterSelector';
import type { SelectedFilter } from 'modules/common-ui/components/Filter/SelectedFilter.type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkXLSXInjection } from 'utils/validate';
import type { FleetSegmentForm, SegmentErrors } from '../../types';
import { useFilters } from './FiltersDataLoading';
import { Container, InvalidFeedback, Label, Title } from './styles.css';

type SegmentEditorProps = {
  fleetSegment: FleetSegmentForm;
  segmentErrors: SegmentErrors;
  onChange: (fleetSegment: FleetSegmentForm) => void;
  resetSegmentErrors: () => void;
};
const NAME_MAX_LENGTH = 40;

export const SegmentEditor = ({
  fleetSegment,
  segmentErrors,
  onChange,
  resetSegmentErrors,
}: SegmentEditorProps) => {
  const { t } = useTranslation('fleetSegment');
  const filters = useFilters();
  const [fieldsTouched, setFieldsTouched] = useState({
    nameTouched: false,
    filtersTouched: false,
  });

  const validateName = (name: string) => {
    if (!name || checkXLSXInjection(name)) {
      return t('form.fields.name.validation');
    }

    if (name.length > NAME_MAX_LENGTH) {
      return t('form.fields.name.badLength', { maxLength: NAME_MAX_LENGTH });
    }
  };
  const validateFilters = (filters: SelectedFilter[]) => {
    const isFiltersValid =
      filters.filter((item) => item.value && item.value.length > 0).length > 0;
    if (!isFiltersValid) {
      return t('form.fields.filters.validation');
    }
  };

  const handleNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setFieldsTouched((prev) => ({ ...prev, nameTouched: true }));
    onChange({
      ...fleetSegment,
      name,
      isValid:
        isEmpty(validateName(name)) &&
        isEmpty(validateFilters(fleetSegment.filters)),
    });
    resetSegmentErrors();
  };

  const nameErrors = validateName(fleetSegment.name);
  const filtersErrors = validateFilters(fleetSegment.filters);

  const calloutOnFilter: CalloutOnFilter = {
    filterType: 'zone',
    text: t('form.fields.filters.zoneCallOut'),
  };

  return (
    <Container>
      <Field>
        <Title>{t('form.fields.name.title')}</Title>
        <Label>
          <TextInput
            id="name"
            placeholder={t('form.fields.name.placeholder', {
              maxLength: NAME_MAX_LENGTH,
            })}
            valid={
              !fieldsTouched.nameTouched ||
              (isEmpty(nameErrors) &&
                segmentErrors.name &&
                segmentErrors.name.length === 0)
            }
            value={fleetSegment.name}
            onChange={handleNameChange}
            fullWidth
            variant={'secondary'}
          />
          {fieldsTouched.nameTouched && !isEmpty(nameErrors) && (
            <InvalidFeedback>{nameErrors}</InvalidFeedback>
          )}
          {segmentErrors.name?.map((error, index) => (
            <InvalidFeedback key={index}>{error}</InvalidFeedback>
          ))}
        </Label>
      </Field>
      <Field>
        <Title>{t('form.fields.filters.title')}</Title>
        <Label>
          <FilterSelector
            addFilter={async () => {
              const currentFilters = [...fleetSegment.filters];
              const newFilters = [...currentFilters, { type: '' }];
              onChange({
                ...fleetSegment,
                filters: newFilters,
                isValid:
                  isEmpty(validateName(fleetSegment.name)) &&
                  isEmpty(validateFilters(newFilters)),
              });
            }}
            deleteFilter={async (index: number) => {
              if (index > -1) {
                let newFilters = [...fleetSegment.filters];
                newFilters.splice(index, 1);
                if (newFilters.length === 0) {
                  newFilters = [{ type: '' }];
                }
                setFieldsTouched((prev) => ({ ...prev, filtersTouched: true }));
                onChange({
                  ...fleetSegment,
                  filters: newFilters,
                  isValid:
                    isEmpty(validateName(fleetSegment.name)) &&
                    isEmpty(validateFilters(newFilters)),
                });
              }

              Tooltip.hide();
            }}
            filters={filters}
            selectedFilters={fleetSegment.filters}
            updateFilterType={async (index: number, type: string) => {
              if (index > -1) {
                const currentFilters = [...fleetSegment.filters];
                const newFilters = [...currentFilters];
                newFilters[index] = { type };
                onChange({
                  ...fleetSegment,
                  filters: newFilters,
                  isValid:
                    isEmpty(validateName(fleetSegment.name)) &&
                    isEmpty(validateFilters(newFilters)),
                });
              }
            }}
            updateFilterValue={async (index: number, value: any) => {
              if (index != null && index > -1 && value != null) {
                const currentFilters = [...fleetSegment.filters];
                const newFilters = [...currentFilters];
                newFilters[index].value = value;
                setFieldsTouched((prev) => ({ ...prev, filtersTouched: true }));
                onChange({
                  ...fleetSegment,
                  filters: newFilters,
                  isValid:
                    isEmpty(validateName(fleetSegment.name)) &&
                    isEmpty(validateFilters(newFilters)),
                });
              }
            }}
            alignRight={false}
            horizontalPadding={false}
            calloutOnFilter={calloutOnFilter}
          />
          {fieldsTouched.filtersTouched && !isEmpty(filtersErrors) && (
            <InvalidFeedback>{filtersErrors}</InvalidFeedback>
          )}
          {segmentErrors.filters?.map((error, index) => (
            <InvalidFeedback key={index}>{error}</InvalidFeedback>
          ))}
        </Label>
      </Field>
    </Container>
  );
};
