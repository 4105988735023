import type { FilterObject } from 'modules/common-ui/components/Filter/FilterObject.type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FleetSegment } from '../../../../../common-ui/models/FleetSegment';
import { fleetSegmentGQLListToEntities } from '../dto/fleetSegmentDataListToFleetSegment';
import { useFleetSegmentsQuery } from './gql.generated';

export const useFleetSegmentList = (filterOptions: FilterObject[]) => {
  const { t } = useTranslation('filter');
  const [fleetSegments, setFleetSegments] = useState<FleetSegment[]>([]);

  const { loading, data, refetch } = useFleetSegmentsQuery();

  useEffect(() => {
    if (!data) {
      return;
    }
    const fleetSegmentGQLList = data?.viewer?.workspace?.fleetSegments ?? [];
    setFleetSegments(
      fleetSegmentGQLListToEntities(fleetSegmentGQLList, filterOptions, t),
    );
  }, [loading, data, filterOptions, t]);

  return {
    segments: fleetSegments,
    loading,
    refetch,
  };
};
