import colors from 'constants/colors';
import {
  SimpleValueWithInfos,
  type SimpleValueWithInfosProps,
} from '../../Dataviz/SimpleValue/SimpleValueWithInfos';
import { type CardProps, TextCard } from '../../common/layout/Card';

type SimpleValueWithInfosCardProps = CardProps<SimpleValueWithInfosProps>;

export const SimpleValueWithInfosCard = ({
  title,
  titleInfo,
  subtitle,
  ...rest
}: SimpleValueWithInfosCardProps) => {
  return (
    <TextCard
      title={title}
      titleInfo={titleInfo}
      subtitle={subtitle}
      backgroundColor={colors.white}
    >
      <SimpleValueWithInfos {...rest} />
    </TextCard>
  );
};
