import { useTranslation } from 'react-i18next';

import colors from 'constants/colors';
import fontSizes from 'constants/fontSizes';
import { DateTime } from 'luxon';
import type Highcharts from 'modules/analytics/lib/highcharts';
import { HCDefaultXAxisOptions } from 'modules/analytics/lib/highcharts';
import ContentLoader from 'react-content-loader';
import { type PeriodScale, formatDateWithPeriodScale } from 'utils/time';
import type { LineChartData } from './LineChart';
import { ChartContainer, MessageHelper } from './styles.css';

export const NoDataComponent = () => {
  const { t } = useTranslation('analytics');
  return (
    <ChartContainer>
      <MessageHelper>{t('chart.noData')}</MessageHelper>
    </ChartContainer>
  );
};

export const TooManyDataComponent = () => {
  const { t } = useTranslation('analytics');
  return (
    <ChartContainer>
      <MessageHelper>{t('chart.tooManyData')}</MessageHelper>
    </ChartContainer>
  );
};

export const LoadingComponent = () => {
  return (
    <ChartContainer>
      <ContentLoader
        width="100%"
        height="100%"
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title="Loading"
      >
        <rect x="9" y="11" rx="4" ry="4" width="100%" height="90%" />
      </ContentLoader>
    </ChartContainer>
  );
};

export type ChartValueCustomData = {
  tooltipDetails?: string;
  missing?: boolean;
  originalData?: any;
};

export type ChartValue = {
  // see https://api.highcharts.com/highcharts/series.column.data
  x: number;
  y: number;
  custom: ChartValueCustomData;
};

// UTILS

export const computeYMin = (data: { value: number }[]) => {
  if (!data.length) return 0;
  const min = data.reduce(
    (acc, item) => (item.value < acc ? item.value : acc),
    Number.POSITIVE_INFINITY,
  );
  if (min === Number.POSITIVE_INFINITY) return 0;
  const coeff = 10 ** Math.floor(Math.log10(Math.abs(min)));
  // if coeff is 0, there is no question to ask => min = 0
  if (coeff === 0) return 0;
  return Math.max(Math.floor(min / coeff) * coeff, min);
};

export const computeYMax = (data: { value: number }[]) => {
  if (!data.length) return 0;
  const max = data.reduce(
    (acc, item) => (item.value > acc ? item.value : acc),
    Number.NEGATIVE_INFINITY,
  );
  if (max === Number.NEGATIVE_INFINITY) return 0;
  return max;
};

/* this method is used to reduce the number of x axis ticks
 * when we have a large number of values so that the chart is readable.
 * It returns the number of milliseconds required between each tick.
 */
const getTickInterval = (data: LineChartData[]) => {
  if (!data[0]?.date || !data[data.length - 1]?.date) {
    return undefined;
  }
  const monthDiff = DateTime.fromJSDate(data[data.length - 1].date)
    .diff(DateTime.fromJSDate(data[0].date))
    .as('months');

  if (monthDiff > 12) {
    return 30 * 24 * 3600 * 1000;
  }
  if (monthDiff > 3) {
    return 14 * 24 * 3600 * 1000;
  }
  if (monthDiff > 1) {
    return 5 * 24 * 3600 * 1000;
  }
  const daysDiff = Math.abs(
    DateTime.fromJSDate(data[data.length - 1].date)
      .diff(DateTime.fromJSDate(data[0].date))
      .as('days'),
  );

  if (daysDiff <= 1) {
    return 3600 * 1000;
  }

  return 24 * 3600 * 1000;
};

export const generateXaxisOptions = (
  data: LineChartData[],
  periodScale?: PeriodScale,
  xMin?: number,
  xMax?: number,
): Highcharts.XAxisOptions => {
  return {
    ...HCDefaultXAxisOptions,
    type: 'datetime',
    tickPosition: 'outside',
    lineWidth: 0,
    grid: {
      enabled: false,
    },
    minorGridLineWidth: 0,
    gridLineWidth: 0,
    minorTickColor: colors.gray300,
    // don't display minor ticks when there are few data points
    minorTicks: periodScale === 'day' && data.length > 7,
    minorTickWidth: 1,
    minorTickLength: 5,
    tickColor: colors.gray400,
    tickInterval: periodScale === 'month' ? undefined : getTickInterval(data),
    labels: {
      formatter: function () {
        // use day date format for week scale for better readability
        const scale: PeriodScale =
          periodScale === 'week' || !periodScale ? 'day' : periodScale;
        return formatDateWithPeriodScale(this.value, scale);
      },
      style: {
        color: colors.gray700,
        fontSize: fontSizes.other.chartXYAxis,
      },
      autoRotationLimit: 45,
    },
    crosshair: {
      color: colors.gray200,
      width: 1,
    },
    min: xMin ?? data[0]?.date.getTime(),
    max: xMax ?? data[data.length - 1]?.date.getTime(),
  };
};
