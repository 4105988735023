import * as Sentry from '@sentry/browser';
import { getGraphQLCodeError } from 'graphql/utils';
import { ConfirmModal } from 'modules/common-ui';
import type { SelectedFilter } from 'modules/common-ui/components/Filter/SelectedFilter.type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import type { Store } from 'reducers';
import { useCreateFleetSegmentMutation } from '../../gql.generated';
import type {
  AssetFilterOptions,
  FleetSegmentForm,
  SegmentErrors,
} from '../../types';
import {
  selectedFiltersToObject,
  transformFiltersToGqlFilters,
} from '../../utils';
import { SegmentMutateModal } from '../SegmentMutateModal';

type SegmentCreateProps = {
  onSegmentCreated: (
    fleetSegmentId: number,
    filters: AssetFilterOptions,
    fleetSegmentName: string,
  ) => void;
  onCancel: () => void;
  initialSelectedFilters?: SelectedFilter[];
};

export const SegmentCreate = ({
  onSegmentCreated,
  onCancel,
  initialSelectedFilters,
}: SegmentCreateProps) => {
  const { t } = useTranslation('filter');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [segmentErrors, setSegmentErrors] = useState<SegmentErrors>({});
  const [fleetSegment, setFleetSegment] = useState<FleetSegmentForm | null>(
    null,
  );
  const [createFleetSegmentLoading, setCreateFleetSegmentLoading] =
    useState(false);
  const [createFleetSegment] = useCreateFleetSegmentMutation();

  const currentWorkspaceName = useSelector<Store, string | null>(
    (state) => state.user?.group,
  );

  const handleModalSegmentCreate = (fleetSegment: FleetSegmentForm) => {
    setFleetSegment(fleetSegment);
    setOpenConfirmModal(true);
  };

  const handleConfirmSegmentCreate = async () => {
    if (!fleetSegment) return;
    try {
      setCreateFleetSegmentLoading(true);
      const response = await createFleetSegment({
        variables: {
          name: fleetSegment.name,
          filters: transformFiltersToGqlFilters(
            selectedFiltersToObject(fleetSegment.filters || []),
          ),
        },
      });
      const createdFleetSegment = response.data!.createFleetSegment;

      if (!createdFleetSegment) {
        throw new Error();
      }

      onSegmentCreated(
        createdFleetSegment.id,
        selectedFiltersToObject(fleetSegment.filters),
        fleetSegment.name,
      );
      toast.success(
        t('fleetSegment:toast.create.success', { name: fleetSegment.name }),
        {
          position: 'top-right',
        },
      );
    } catch (error: any) {
      const errorCode = getGraphQLCodeError(error);
      switch (errorCode) {
        case 'NAME_ALREADY_EXISTS': {
          setSegmentErrors({
            name: [t('fleetSegment:form.fields.name.name_already_exists')],
          });
          break;
        }
        default:
          toast.error(
            t('fleetSegment:toast.create.error', { name: fleetSegment.name }),
            {
              position: 'top-right',
            },
          );
          Sentry.captureException(error);
          break;
      }
    }
    setCreateFleetSegmentLoading(false);
    setOpenConfirmModal(false);
  };

  return (
    <>
      <SegmentMutateModal
        isOpen={true}
        segmentErrors={segmentErrors}
        onCancel={onCancel}
        onCreate={handleModalSegmentCreate}
        resetSegmentErrors={() => setSegmentErrors({})}
        onOutsideClick={() => {}}
        initialFleetSegment={{
          filters: initialSelectedFilters || [],
          name: '',
          isValid: false,
        }}
      />
      {openConfirmModal && (
        <ConfirmModal
          isOpen={true}
          isLoading={createFleetSegmentLoading}
          submitLabel={t('fleetSegment:modal.confirmCreate.submit')}
          headerLabel=""
          message={t('fleetSegment:modal.confirmCreate.title', {
            workspaceName: currentWorkspaceName || 'N/A',
          })}
          submitBtnVariant="primary-light"
          onCancel={() => setOpenConfirmModal(false)}
          onSubmit={handleConfirmSegmentCreate}
        />
      )}
    </>
  );
};
