import {
  type Page,
  type Section,
  trackColumnSorted,
  trackColumnUpdated,
  trackNumberOfResultChanged,
} from 'modules/analytics/lib/tracking';
import { Table } from 'modules/tableV7';
import { useTracking } from 'modules/utils/analytics';

import type { TableProps } from 'modules/tableV7';

interface TrackedTableProps
  extends Omit<
    TableProps,
    'onColumnVisibilityChange' | 'onSortChange' | 'onPageSizeChange'
  > {
  trackingProps: {
    page: Page;
    section: Section;
  };
}

export const TrackedTable = ({
  trackingProps,
  columns,
  data,
  ...tableProps
}: TrackedTableProps) => {
  const { track } = useTracking();
  return (
    <Table
      onColumnVisibilityChange={(column, enabled): void => {
        trackColumnUpdated(track, {
          page: trackingProps.page,
          section: trackingProps.section,
          column,
          enabled,
        });
      }}
      onSortChange={(column, descending): void => {
        trackColumnSorted(track, {
          page: trackingProps.page,
          section: trackingProps.section,
          column,
          order: descending ? 'desc' : 'asc',
        });
      }}
      onPageSizeChange={(pageSize): void => {
        trackNumberOfResultChanged(track, {
          page: trackingProps.page,
          section: trackingProps.section,
          number: pageSize,
        });
      }}
      columns={columns}
      data={data}
      {...tableProps}
    />
  );
};
