import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { SimpleValueWithInfosCard } from 'modules/analytics/components/Cards/SimpleValueCard/SimpleValueCardWithInfos';
import { AssetListDrawer } from 'modules/analytics/components/common/AssetListDrawer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type PeriodScale,
  formatDateWithPeriodScale,
  getPeriodLabel,
} from 'utils/time';
import { AssetsListTable } from '../../../components/AssetsListTable';
import { useAssetListExport } from '../../../lib/exportAssetListHook';

type EvolutionDrawerProps = {
  selectedPeriod: { since: Date; until: Date };
  granularityScale: PeriodScale;
  open: boolean;
  assetFilters: AssetFilterGQL;
  onClose: () => void;
  usedAssetsCount: number;
  activeAssetsCount: number;
  averageUsageHoursPerActiveDays: number;
};

export const EvolutionDrawer = ({
  selectedPeriod,
  granularityScale,
  assetFilters,
  open,
  onClose,
  usedAssetsCount,
  activeAssetsCount,
  averageUsageHoursPerActiveDays,
}: EvolutionDrawerProps) => {
  const { t } = useTranslation('analytics');
  const { triggerAssetListExport, exportLoading } = useAssetListExport();

  const assetListPeriod = useMemo(() => {
    if (!(selectedPeriod?.since && selectedPeriod?.until)) return null;
    return { start: selectedPeriod.since, end: selectedPeriod.until };
  }, [selectedPeriod]);

  const handleAssetListExport = useCallback(() => {
    if (!assetListPeriod) return;
    triggerAssetListExport(
      assetFilters,
      assetListPeriod.start,
      assetListPeriod.end,
      'daily',
    );
  }, [assetFilters, assetListPeriod, triggerAssetListExport]);

  const header = () => {
    if (!selectedPeriod) return null;
    const title =
      granularityScale === 'day'
        ? formatDateWithPeriodScale(selectedPeriod.since, granularityScale)
        : getPeriodLabel(selectedPeriod.since, selectedPeriod.until);
    return (
      <SimpleValueWithInfosCard
        title={title}
        infos={t('reports.common.assetsCountOverTotal', {
          count: usedAssetsCount,
          total: activeAssetsCount,
        })}
        unit="h"
        value={averageUsageHoursPerActiveDays}
      />
    );
  };

  const mainDrawerContent = () => {
    if (!selectedPeriod || !assetListPeriod) return null;
    return (
      <AssetsListTable
        assetFilters={assetFilters}
        assetListDataSource="daily"
        since={assetListPeriod.start}
        until={assetListPeriod.end}
        onExportClick={handleAssetListExport}
        exportLoading={exportLoading}
      />
    );
  };

  return (
    <AssetListDrawer
      open={open}
      title={t('reports.common.assetsUsed')}
      header={header()}
      mainContent={mainDrawerContent()}
      onClose={onClose}
      trackingProps={{
        page: 'usageHours',
        section: 'analysis',
        source: 'evolutionChart',
      }}
    />
  );
};
