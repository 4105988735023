import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import {
  type Page,
  type Section,
  trackReportExported,
} from 'modules/analytics/lib/tracking';
import {
  Beta,
  type DropdownOption,
  HibooLogoLarge,
  InfoIcon,
  Tooltip,
} from 'modules/common-ui';
import { useTracking } from 'modules/utils';
import { type PropsWithChildren, type ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CustomTooltip } from '../../CustomTooltip/CustomTooltip';
import { CardOptions } from './CardOptions';
import { buildExportPdfFunction } from './exportDocumentFactory';
import {
  buildExportImageFunction,
  type exportImageFormat,
} from './exportImageFactory';
import {
  Container,
  CustomSpinner,
  Header,
  InfoIconContainer,
  Left,
  Right,
  Subtitle,
  TextContainer,
  TextContent,
  Title,
  TitleContainer,
  Watermark,
} from './styles.css';

// Helper to be used by other components to generate quickly the proper type
export type CardProps<DatavizProps> = DatavizProps & InternalCardProps;
export type TextCardProps<DatavizProps> = DatavizProps & InternalTextCardProps;

type CardMenuOption = DropdownOption & {
  handler: () => void;
};

type InternalCardProps = {
  id?: string;
  title?: string;
  titleInfo?: string | ReactNode;
  subtitle?: string;
  beta?: boolean;
  containerStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  menuOptions?: CardMenuOption[];
  disableMenu?: boolean;
  disabledMenuTooltipInfo?: string;
  disableImageExport?: boolean;
  actionElement?: ReactNode;
  selectedPeriodComponent?: ReactNode;
  disableImageExportWatermark?: boolean;
  description?: ReactNode;
  trackingProps?: {
    page: Page;
    section: Section;
  };
  onClick?: () => void;
};

const AVAILABLE_IMAGE_FORMATS: exportImageFormat[] = ['jpg'];

export const Card = ({
  children,
  title,
  titleInfo,
  subtitle,
  beta,
  containerStyle,
  headerStyle,
  menuOptions = [],
  disableMenu = false,
  disabledMenuTooltipInfo,
  actionElement,
  selectedPeriodComponent,
  disableImageExport = false,
  disableImageExportWatermark = false,
  description,
  trackingProps,
  onClick,
  id,
}: PropsWithChildren<InternalCardProps>) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });
  const watermarkRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('analytics');
  const ref = useRef<HTMLDivElement>(null);
  const { track } = useTracking();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const hasTitle = title && title.length > 0;
  const hasSubtitle = subtitle && subtitle.length > 0;

  const trackExport = (format: string) => {
    if (trackingProps) {
      trackReportExported(
        track,
        trackingProps.page,
        trackingProps.section,
        format,
      );
    }
  };

  const options: CardMenuOption[] = menuOptions;
  if (!disableImageExport) {
    options.push(
      ...AVAILABLE_IMAGE_FORMATS.map((format) => ({
        label: t('card.menu.exportFormat', {
          replace: { format: format.toUpperCase() },
        }),
        value: format,
        handler: buildExportImageFunction(format, ref, {
          onExportStart: () => {
            trackExport(format);
            if (!disableImageExportWatermark) setWatermarkVisibility(true);
          },
          onExportEnd: () => {
            // just to not make the watermark disappear too brutally
            setTimeout(() => {
              if (!disableImageExportWatermark) setWatermarkVisibility(false);
            }, 1000);
          },
          skipClassName: 'loader',
        }),
      })),
      {
        label: t('card.menu.exportFormat', {
          replace: { format: 'PDF' },
        }),
        value: 'pdf',
        handler: buildExportPdfFunction(ref, {
          onExportStart: () => {
            trackExport('pdf');
            if (!disableImageExportWatermark) setWatermarkVisibility(true);
          },
          onExportEnd: () => {
            // just to not make the watermark disappear too brutally
            setTimeout(() => {
              if (!disableImageExportWatermark) setWatermarkVisibility(false);
            }, 1000);
          },
          ignoreElements: [dropdownRef],
        }),
      },
    );
  }

  const setWatermarkVisibility = (visible: boolean) => {
    if (watermarkRef.current) {
      watermarkRef.current.style.visibility = visible ? 'visible' : 'hidden';
    }
  };

  return (
    <Container ref={ref} onClick={onClick} style={containerStyle} id={id}>
      <Header alignStart={isMobile} columnView={isMobile} style={headerStyle}>
        <Left>
          <TitleContainer>
            {hasTitle && (
              <Title>
                {title}
                {titleInfo && (
                  <InfoIconContainer>
                    <InfoIcon dataFor={`tooltip-title-info-${title}`} />
                    <Tooltip id={`tooltip-title-info-${title}`} place="bottom">
                      {titleInfo}
                    </Tooltip>
                  </InfoIconContainer>
                )}
                {beta && <Beta />}
              </Title>
            )}
            {hasSubtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleContainer>
        </Left>
        <Right alignStart={isMobile}>
          {selectedPeriodComponent}
          {actionElement}
          {options.length > 0 && (
            <CardOptions
              forwardRef={dropdownRef}
              options={options}
              disabled={disableMenu}
              disabledTooltipInfo={disabledMenuTooltipInfo}
            />
          )}
        </Right>
      </Header>
      {description}

      <Watermark ref={watermarkRef}>
        <CustomSpinner className="loader" />
        <HibooLogoLarge height={16.71} width={60} />
      </Watermark>
      {children}
    </Container>
  );
};

type InternalTextCardProps = {
  title?: string;
  titleInfo?: string | ReactNode;
  subtitle?: string;
  description?: ReactNode;
  tooltipId?: string;
  backgroundColor?: string;
  onClick?: () => void;
};

export const TextCard = ({
  children,
  title,
  titleInfo,
  subtitle,
  description,
  tooltipId,
  backgroundColor,
  onClick,
}: PropsWithChildren<InternalTextCardProps>) => {
  const ref = useRef<HTMLDivElement>(null);

  const hasTitle = title && title.length > 0;
  const hasSubtitle = subtitle && subtitle.length > 0;

  return (
    <TextContainer
      backgroundColor={backgroundColor}
      isClickable={typeof onClick === 'function'}
      onClick={onClick}
    >
      <TextContent ref={ref} data-for={tooltipId} data-tip="">
        <Header>
          <TitleContainer>
            {hasTitle && (
              <Title>
                {title}
                {titleInfo && (
                  <InfoIconContainer>
                    <span data-for={`tooltip-title-info-${title}`} data-tip>
                      <InfoIcon />
                      <CustomTooltip
                        tooltipId={`tooltip-title-info-${title}`}
                        place="bottom"
                        // those 2 event handlers are here to avoid displaying both the tooltip on the <InfoIcon /> ⇧ and in the card.
                        afterShow={() => {
                          if (tooltipId) {
                            Tooltip.hide(ref.current!);
                          }
                        }}
                        afterHide={() => {
                          if (tooltipId) {
                            Tooltip.show(ref.current!);
                          }
                        }}
                        tooltip={titleInfo}
                        multilineHtml
                      />
                    </span>
                  </InfoIconContainer>
                )}
              </Title>
            )}
            {hasSubtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleContainer>
        </Header>
        {description}

        {children}
      </TextContent>
    </TextContainer>
  );
};
