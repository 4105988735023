import colors from 'constants/colors';
import { FLAGS } from 'constants/flags';
import { ClockHour3 } from 'modules/common-ui';
import { useFeature } from 'modules/utils';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const CustomLink = styled.span`
  text-decoration: underline;
  color: inherit;
  margin-left: 2px;
`;

export const useExportInProgressToast = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const [exportCenterEnabled] = useFeature<boolean>(
    FLAGS.ANALYTICS_EXPORT_CENTER,
  );

  const displayToast = () => {
    if (exportCenterEnabled) {
      toast.success(
        <Trans
          i18nKey="common.exportRequestedV2"
          components={{
            CustomLink: (
              <CustomLink
                aria-label="link to exports page"
                onClick={() => navigate('/exports')}
              />
            ),
          }}
        />,
        {
          position: 'top-right',
          autoClose: false,
          icon: <ClockHour3 color={colors.white} />,
          style: {
            width: 'fit-content',
          },
        },
      );
    } else {
      toast.success(t('common.exportRequested'), {
        position: 'top-right',
      });
    }
  };

  return displayToast;
};
