import { New } from '../Badge';
import {
  SubHeaderLink,
  type SubHeaderLinkProps,
} from './components/SubHeaderLink/SubHeaderLink';
import {
  ActionContainer,
  Header,
  HeaderContainer,
  HeaderWrapper,
  SubHeader,
  SubHeaderContainer,
  Title,
  TitleContainer,
  TitleText,
} from './style.css';

export type PageHeaderProps = {
  sticky?: boolean;
  displaySubheader?: boolean;
  fullWidth?: boolean;
  isNew?: boolean;

  title: string;
  titleIcon?: React.ReactNode;
  titleIconLayout?: 'row' | 'column';
  actionContent: React.ReactNode;
  subheaderLink?: SubHeaderLinkProps;
  subheaderContent: React.ReactNode;
  noBottomPadding?: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  sticky = false,
  displaySubheader = false,
  fullWidth = true,
  isNew = false,

  title,
  titleIcon = null,
  titleIconLayout = 'row',
  actionContent,
  subheaderLink,
  subheaderContent,
  noBottomPadding = false,
}) => {
  const hasSubheaderLink = subheaderLink !== undefined;

  return (
    <HeaderWrapper sticky={sticky}>
      <Header sticky={sticky} noBottomPadding={noBottomPadding}>
        <HeaderContainer fullWidth={fullWidth}>
          <TitleContainer>
            <Title titleIconLayout={titleIconLayout}>
              {titleIcon}
              <TitleText>
                <span role="heading" aria-level={1}>
                  {title}
                </span>
                {isNew && <New />}
              </TitleText>
            </Title>
            {hasSubheaderLink && <SubHeaderLink {...subheaderLink} />}
          </TitleContainer>

          <ActionContainer>{actionContent}</ActionContainer>
        </HeaderContainer>
      </Header>
      {displaySubheader && (
        <SubHeader sticky={sticky}>
          <SubHeaderContainer fullWidth={fullWidth}>
            {subheaderContent}
          </SubHeaderContainer>
        </SubHeader>
      )}
    </HeaderWrapper>
  );
};
