import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import colors from 'constants/colors';
import {
  LineChart,
  type LineChartData,
} from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValue } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValue';
import { Card } from 'modules/analytics/components/common/layout/Card';
import type { UsageHoursByPeriod } from 'modules/analytics/reports/usageHours/gql';
import type { useUsageHoursStatistics } from 'modules/analytics/reports/usageHours/sections/UsageHoursAnalysis/cards/UsageHoursStatistics';
import { Button } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import type { PeriodScale } from 'utils/time';

const CardContainer = styled.div`
  flex: 1 1 calc(50% - 16px); /* Two items per row with gap adjustment */
  max-width: calc(50% - 16px);

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    flex: 1 1 100%; /* Full width for smaller screens */
    max-width: 100%;
  }
`;

const AssetsCount = styled.div`
  color: ${colors.gray500};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
`;

type UsageHoursComponentProps = {
  statisticData: ReturnType<Awaited<typeof useUsageHoursStatistics>>['data'];
  averageUsageHoursPerActiveDays: number | null;
  previousAverageUsageHoursPerActiveDays: number | null;
  data: LineChartData[];
  setSelectedDataPoint: (period: UsageHoursByPeriod | null) => void;
  setGranularityScale: (scale: PeriodScale) => void;
};

export const UsageHoursComponent = ({
  statisticData,
  data,
  setSelectedDataPoint,
  setGranularityScale,
}: UsageHoursComponentProps) => {
  const { t } = useTranslation('newHome');
  const navigate = useNavigate();

  return (
    <>
      <CardContainer>
        <Card
          title={t('analytics.kpi.usageHours')}
          titleInfo={t('analytics.kpi.help')}
          actionElement={
            <>
              <Button
                onClick={() => {
                  setGranularityScale('week');
                  setSelectedDataPoint(
                    statisticData
                      ? {
                          activeAssetsCount:
                            statisticData.activeAssetsCount.current || 0,
                          averageUsageHoursPerActiveDays:
                            statisticData.averageUsageHoursPerActiveDays
                              .current || 0,
                          // date is not important in that case
                          date: new Date(),
                          usedAssetsCount:
                            statisticData.usedAssetsCount.current || 0,
                          averageActiveDaysPerUsedAssets:
                            statisticData.averageActiveDaysPerUsedAssets
                              .current || 0,
                        }
                      : null,
                  );
                }}
              >
                {t('analytics.kpi.viewAssets')}
              </Button>
              <Button onClick={() => navigate('/analytics/usage-hours')}>
                {t('analytics.kpi.analyze')}
              </Button>
            </>
          }
          disableImageExport
        >
          <SimpleValue
            value={
              statisticData?.averageUsageHoursPerActiveDays.current || null
            }
            unit={'h'}
            comparativeValue={
              statisticData?.averageUsageHoursPerActiveDays.previous || null
            }
            orientation="row"
          />
          <AssetsCount>
            {t('analytics.kpi.assetsCount', {
              assetsCount: statisticData?.activeAssetsCount.current,
            })}
          </AssetsCount>
          <LineChart
            data={data}
            unit={'h'}
            tooltipV2
            periodScale="day"
            displayBars
            onItemClicked={(item: UsageHoursByPeriod) => {
              setGranularityScale('day');
              setSelectedDataPoint(item);
            }}
          />
        </Card>
      </CardContainer>
    </>
  );
};
