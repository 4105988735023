import { useTranslation } from 'react-i18next';
import { EmptyValue, NoData } from './styles.css';

export const NoValue = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <EmptyValue>-</EmptyValue>
      <NoData>{t('simpleValue.noData')}</NoData>
    </>
  );
};
