export const checkXLSXInjection = (name: string) => {
  if (
    name.startsWith('@') ||
    name.startsWith('+') ||
    name.startsWith('-') ||
    name.startsWith('=') ||
    name.startsWith('0x09') ||
    name.startsWith('0x0D')
  ) {
    return true;
  }

  return false;
};
