import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { PAGE_LATERAL_MARGIN, REGULAR_WIDTH } from 'modules/layout';
import styled from 'styled-components';

export const BodyContainer = styled.div<{ marginBottom?: number }>`
  overflow-y: auto;
  height: 100%;

  padding: ${PAGE_LATERAL_MARGIN} 0px 0px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  @media (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    border-top: 1px solid #e5e8eb;
  }
`;

export const BodyContent = styled.div<{ fullWidth: boolean }>`
  max-width: ${(props) => (props.fullWidth ? '100%' : REGULAR_WIDTH)};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${(props) => !props.fullWidth && 'padding: 0 8px 8px 8px;'}
`;
