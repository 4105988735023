import { Tooltip } from 'modules/common-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TooltipTitle = styled.div`
  font-weight: bold;
`;

const TooltipContent = styled.div``;

const Value = styled.div`
  margin-left: 4px;
`;

export const MissingValueWrapper = ({
  title,
  value,
  children,
}: {
  title: string | null;
  value: any;
  children: JSX.Element;
}) => {
  const { t } = useTranslation('analytics');
  const [uniqToolTipId] = useState(
    `tooltip-${Math.random().toString(36).substring(7)}`,
  );

  if (value === null || value === undefined) {
    return (
      <>
        <Tooltip place={'right'} id={uniqToolTipId}>
          <TooltipTitle>{title}</TooltipTitle>
          <TooltipContent>{t('reports.missingValue')}</TooltipContent>
        </Tooltip>
        <Value data-for={uniqToolTipId} data-tip={uniqToolTipId}>
          -
        </Value>
      </>
    );
  }
  return children;
};
