import moment from 'moment';

export const getLast7DaysPeriod = () => {
  const until = moment().subtract(1, 'day').endOf('day');
  const since = moment(until).subtract(6, 'days').startOf('day');

  return {
    since: since.toDate(),
    until: until.toDate(),
  };
};
