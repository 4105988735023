import {
  PageHeader,
  type PageHeaderProps,
  type SubHeaderLinkProps,
} from 'modules/common-ui/components/PageHeader';
import Layout from 'modules/layout';
import { type SetStateAction, useState } from 'react';
import { BodyContainer, BodyContent } from './styles.css';

type StickyHeaderLayoutProps = {
  title: string;
  titleIcon?: React.ReactNode;
  titleIconLayout?: PageHeaderProps['titleIconLayout'];
  isNew?: boolean;

  actionContent: React.ReactNode;
  subheaderContent: React.ReactNode;
  subheaderLink?: SubHeaderLinkProps;
  displaySubheader: boolean;
  bodyContent: React.ReactNode;
  fullWidth: boolean;
  noBottomPadding?: boolean;
};

export const StickyHeaderLayout = ({
  title,
  titleIcon,
  titleIconLayout,
  actionContent,
  subheaderContent,
  subheaderLink,
  bodyContent,
  displaySubheader,
  fullWidth,
  isNew,
  noBottomPadding = false,
}: StickyHeaderLayoutProps) => {
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = (event: {
    currentTarget: { scrollTop: SetStateAction<number> };
  }) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  const sticky = scrollTop > 0;

  return (
    <Layout.Page>
      <PageHeader
        actionContent={actionContent}
        sticky={sticky}
        displaySubheader={displaySubheader}
        subheaderContent={subheaderContent}
        subheaderLink={subheaderLink}
        fullWidth={fullWidth}
        title={title}
        titleIcon={titleIcon}
        titleIconLayout={titleIconLayout}
        isNew={isNew}
        noBottomPadding={noBottomPadding}
      />
      <BodyContainer marginBottom={16} onScroll={handleScroll}>
        <BodyContent fullWidth={fullWidth}>{bodyContent}</BodyContent>
      </BodyContainer>
    </Layout.Page>
  );
};
