import colors from 'constants/colors';
import styled from 'styled-components';

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  & > div {
    width: 100%;
  }
`;

export const MessageHelper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.gray500};
`;
