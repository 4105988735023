import { useQuery } from '@apollo/client';
import { SimpleValueCard } from 'modules/analytics/components/Cards/SimpleValueCard';
import { SimpleValueWithInfosCard } from 'modules/analytics/components/Cards/SimpleValueCard/SimpleValueCardWithInfos';
import { AssetListDrawer } from 'modules/analytics/components/common/AssetListDrawer';
import { TooltipContent } from 'modules/analytics/components/common/Tooltip/Tooltip';
import {
  TitleInfo,
  TitleInfoLink,
  TooltipContainer,
} from 'modules/analytics/reports/common/style.css';
import { Bold } from 'modules/common-ui';
import moment, { type Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  computeBusinessDays,
  formatDateShort,
  getPeriodLabel,
  getUTCEndOfDayDate,
  getUTCStartOfDayDate,
} from 'utils/time';
import type { Unit } from 'utils/unit';
import { AssetsListTable } from '../../../components/AssetsListTable';
import {
  UsageHoursStatisticsQuery,
  type UsageHoursStatisticsResponse,
} from '../../../gql';
import { useAssetListExport } from '../../../lib/exportAssetListHook';
import type { UsageHoursAnalysisCardProps } from './types';

type Period = {
  startDate: Moment;
  endDate: Moment;
};

const getBusinessOntotalDays = ({ startDate, endDate }: Period) => {
  const totalDays = endDate.diff(startDate, 'days') + 1;
  const businessDays = computeBusinessDays(startDate, endDate);

  return { businessDays, totalDays };
};

const UsageHoursStatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
`;

type StatisticCardProps = {
  loading: boolean;
  currentValue: number | null;
  previousValue: number | null;
  currentAssetsCount?: number;
  previousAssetsCount?: number;
  currentUsedAssetsCount?: number;
  previousUsedAssetsCount?: number;
  title: string;
  titleInfo: string | React.ReactNode;
  unit: Unit;
  currentPeriodLabel: string;
  previousPeriodLabel: string;
  businessDayInfo?: React.ReactChild;
  onClick?: () => void;
};

const UsageHoursStatisticCard = ({
  loading,
  currentValue,
  previousValue,
  currentAssetsCount,
  previousAssetsCount,
  currentUsedAssetsCount,
  previousUsedAssetsCount,
  title,
  titleInfo,
  unit,
  currentPeriodLabel,
  previousPeriodLabel,
  businessDayInfo,
  onClick,
}: StatisticCardProps) => {
  return (
    <SimpleValueCard
      loading={loading}
      title={title}
      titleInfo={titleInfo}
      value={currentValue}
      comparativeValue={previousValue}
      unit={unit}
      tooltip={
        <TooltipContent
          label={title}
          currentPeriodLabel={currentPeriodLabel}
          previousPeriodLabel={previousPeriodLabel}
          currentValue={currentValue}
          currentValueDetails={
            currentAssetsCount ? (
              <>
                <Bold>{currentUsedAssetsCount}</Bold>/{currentAssetsCount}
              </>
            ) : undefined
          }
          previousValue={previousValue}
          previousValueDetails={
            previousAssetsCount ? (
              <>
                <Bold>{previousUsedAssetsCount}</Bold>/{previousAssetsCount}
              </>
            ) : undefined
          }
          unit={unit}
          businessDayInfo={businessDayInfo}
        />
      }
      reverseColor
      onClick={onClick}
    />
  );
};

export const useUsageHoursStatistics = ({
  assetFilters,
  since,
  until,
}: UsageHoursAnalysisCardProps) => {
  const parseGQLResponse = (
    data: UsageHoursStatisticsResponse['viewer']['usageHoursReport']['statisticsCustomPeriod'],
  ) => {
    return {
      averageUsageHoursPerActiveDays: {
        current: data.current?.averageUsageHoursPerActiveDays,
        previous: data.previous?.averageUsageHoursPerActiveDays,
      },

      activeAssetsCount: {
        current: data.current?.activeAssetsCount,
        previous: data.previous?.activeAssetsCount,
      },
      usedAssetsCount: {
        current: data.current?.usedAssetsCount,
        previous: data.previous?.usedAssetsCount,
      },
      dateStart: {
        current: data.current?.period.start,
        previous: data.previous?.period.start,
      },
      dateEnd: {
        current: data.current?.period.end,
        previous: data.previous?.period.end,
      },
      averageActiveDaysPerUsedAssets: {
        current: data.current?.averageActiveDaysPerUsedAssets,
        previous: data.previous?.averageActiveDaysPerUsedAssets,
      },
    };
  };

  const { loading, data } = useQuery<UsageHoursStatisticsResponse>(
    UsageHoursStatisticsQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      variables: {
        assetFilters,
        period: {
          since: getUTCStartOfDayDate(since),
          until: getUTCEndOfDayDate(until),
        },
      },
    },
  );

  const parsedData = useMemo(() => {
    if (!data) return undefined;
    return parseGQLResponse(
      data.viewer?.usageHoursReport.statisticsCustomPeriod,
    );
  }, [data]);

  return { loading, data: parsedData };
};

type StatisticMetric = 'avUsageHours' | 'avDaysOfUse' | 'usedAssets';

const getLabels = (
  data: ReturnType<typeof useUsageHoursStatistics>['data'],
) => {
  const currentPeriodLabel = `${formatDateShort(
    data?.dateStart.current,
  )} - ${formatDateShort(data?.dateEnd.current)}`;
  const previousPeriodLabel = `${formatDateShort(
    moment(data?.dateStart.previous),
  )} - ${formatDateShort(data?.dateEnd.previous)}`;
  const hasAvUsageHours = !!data?.averageUsageHoursPerActiveDays.current;
  const hasAvDaysOfUse = !!data?.averageActiveDaysPerUsedAssets.current;
  const hasUsedAssets = !!data?.usedAssetsCount.current;

  return {
    currentPeriodLabel,
    previousPeriodLabel,
    hasAvUsageHours,
    hasAvDaysOfUse,
    hasUsedAssets,
  };
};

export const UsageHoursStatistics = ({
  assetFilters,
  since,
  until,
}: UsageHoursAnalysisCardProps) => {
  const { t } = useTranslation('analytics');
  const [selectedMetric, setSelectedMetric] = useState<StatisticMetric | null>(
    null,
  );
  const { loading, data } = useUsageHoursStatistics({
    assetFilters,
    since,
    until,
  });

  const {
    currentPeriodLabel,
    previousPeriodLabel,
    hasAvUsageHours,
    hasAvDaysOfUse,
    hasUsedAssets,
  } = getLabels(data);

  const drawerHeader = () => {
    if (!selectedMetric || !data) return null;
    const title = getPeriodLabel(since, until);
    const infos = t('reports.common.assetsCountOverTotal', {
      count: data.usedAssetsCount.current,
      total: data.activeAssetsCount.current,
    });
    switch (selectedMetric) {
      case 'avUsageHours':
        return (
          <SimpleValueWithInfosCard
            title={title}
            infos={infos}
            unit="h"
            value={data.averageUsageHoursPerActiveDays.current || null}
            comparativeValue={
              data.averageUsageHoursPerActiveDays.previous || null
            }
            reverseTrendColor
          />
        );

      case 'avDaysOfUse':
        return (
          <SimpleValueWithInfosCard
            title={title}
            infos={infos}
            unit="days"
            value={data.averageActiveDaysPerUsedAssets.current || null}
            comparativeValue={
              data.averageActiveDaysPerUsedAssets.previous || null
            }
            reverseTrendColor
          />
        );

      case 'usedAssets':
        return (
          <SimpleValueWithInfosCard
            title={title}
            infos={infos}
            unit="assets"
            value={data.usedAssetsCount.current || null}
            comparativeValue={data.usedAssetsCount.previous || null}
            reverseTrendColor
          />
        );
    }
  };

  const assetListPeriod = useMemo(() => {
    if (!data?.dateStart.current || !data?.dateEnd.current) return null;
    return {
      start: new Date(data.dateStart.current),
      end: new Date(data.dateEnd.current),
    };
  }, [data]);

  const { triggerAssetListExport, exportLoading } = useAssetListExport();
  const handleAssetListExport = useCallback(() => {
    if (!assetListPeriod) return;
    triggerAssetListExport(
      assetFilters,
      assetListPeriod.start,
      assetListPeriod.end,
      'daily',
    );
  }, [assetFilters, assetListPeriod, triggerAssetListExport]);

  const mainDrawerContent = () => {
    if (!selectedMetric || !assetListPeriod) {
      return null;
    }
    return (
      <AssetsListTable
        assetFilters={assetFilters}
        assetListDataSource="daily"
        since={new Date(assetListPeriod.start)}
        until={new Date(assetListPeriod.end)}
        onExportClick={handleAssetListExport}
        exportLoading={exportLoading}
      />
    );
  };

  const handleCardClick = (metric: StatisticMetric) => {
    return () => setSelectedMetric(metric);
  };

  const { businessDays, totalDays } = getBusinessOntotalDays({
    startDate: moment(since),
    endDate: moment(until),
  });

  return (
    <UsageHoursStatisticsRow>
      <UsageHoursStatisticCard
        title={t(
          'reports.usageHours.analysis.statistics.averageUsageHours.title',
        )}
        titleInfo={
          <TooltipContainer>
            <TitleInfo>
              {t(
                'reports.usageHours.analysis.statistics.averageUsageHours.info',
              )}
            </TitleInfo>
            <TitleInfoLink
              href={t(
                'reports.usageHours.analysis.statistics.averageUsageHours.findMoreUri',
              )}
              target="_blank"
            >
              {t('commonUi:helpLink')}
            </TitleInfoLink>
          </TooltipContainer>
        }
        unit={'h'}
        loading={loading}
        currentValue={data?.averageUsageHoursPerActiveDays.current ?? null}
        previousValue={data?.averageUsageHoursPerActiveDays.previous ?? null}
        currentUsedAssetsCount={data?.usedAssetsCount.current}
        previousUsedAssetsCount={data?.usedAssetsCount.previous}
        currentAssetsCount={data?.activeAssetsCount.current}
        previousAssetsCount={data?.activeAssetsCount.previous}
        currentPeriodLabel={currentPeriodLabel}
        previousPeriodLabel={previousPeriodLabel}
        onClick={hasAvUsageHours ? handleCardClick('avUsageHours') : undefined}
      />

      <UsageHoursStatisticCard
        title={t(
          'reports.usageHours.analysis.statistics.averageDaysOfUsePerAsset.title',
        )}
        titleInfo={
          <TooltipContainer>
            {t(
              'reports.usageHours.analysis.statistics.averageDaysOfUsePerAsset.info',
              { totalDays },
            )}
          </TooltipContainer>
        }
        unit={'days'}
        loading={loading}
        currentValue={data?.averageActiveDaysPerUsedAssets.current ?? null}
        previousValue={data?.averageActiveDaysPerUsedAssets.previous ?? null}
        currentUsedAssetsCount={data?.usedAssetsCount.current}
        previousUsedAssetsCount={data?.usedAssetsCount.previous}
        currentAssetsCount={data?.activeAssetsCount.current}
        previousAssetsCount={data?.activeAssetsCount.previous}
        currentPeriodLabel={currentPeriodLabel}
        previousPeriodLabel={previousPeriodLabel}
        businessDayInfo={
          <>
            <Bold>{businessDays}</Bold>/{totalDays}
          </>
        }
        onClick={hasAvDaysOfUse ? handleCardClick('avDaysOfUse') : undefined}
      />

      <UsageHoursStatisticCard
        title={t('reports.usageHours.analysis.statistics.assetUseds.title')}
        titleInfo={
          <TooltipContainer>
            <TitleInfo>
              {t('reports.usageHours.analysis.statistics.assetUseds.info')}
            </TitleInfo>
            <TitleInfoLink
              href={t(
                'reports.usageHours.analysis.statistics.assetUseds.findMoreUri',
              )}
              target="_blank"
            >
              {t('commonUi:helpLink')}
            </TitleInfoLink>
          </TooltipContainer>
        }
        unit={'assets'}
        loading={loading}
        currentValue={data?.usedAssetsCount.current ?? null}
        previousValue={data?.usedAssetsCount.previous ?? null}
        currentUsedAssetsCount={data?.usedAssetsCount.current}
        previousUsedAssetsCount={data?.usedAssetsCount.previous}
        currentAssetsCount={data?.activeAssetsCount.current}
        previousAssetsCount={data?.activeAssetsCount.previous}
        currentPeriodLabel={currentPeriodLabel}
        previousPeriodLabel={previousPeriodLabel}
        onClick={hasUsedAssets ? handleCardClick('usedAssets') : undefined}
      />

      <AssetListDrawer
        open={selectedMetric !== null}
        title={t('reports.common.assetsUsed')}
        header={drawerHeader()}
        mainContent={mainDrawerContent()}
        onClose={() => {
          setSelectedMetric(null);
        }}
        trackingProps={{
          page: 'usageHours',
          section: 'analysis',
          source: 'statisticsCard',
        }}
      />
    </UsageHoursStatisticsRow>
  );
};
