export const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M13.2543 14.1604H2.82617V6.44727L7.99461 2.95654L13.2543 6.44727V14.1604Z"
      stroke="#2D2F30"
    />
    <path d="M6.29614 14.0429V9.93042H9.7175V13.9242" stroke="#2D2F30" />
  </svg>
);
