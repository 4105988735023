import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 22px;
  overflow: auto;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 8px 0;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
`;

export const InvalidFeedback = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: ${colors.magenta};
`;
