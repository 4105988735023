import colors from 'constants/colors';
import type { GradientColorObject, SeriesOptionsType } from 'highcharts';
import HighchartsReact, {
  type HighchartsReactRefObject,
} from 'highcharts-react-official';
import Highcharts, {
  HCDefaultAreasplineOptions,
  HCDefaultOptions,
  HCDefaultPlotOptions,
  HCDefaultTooltipOptions,
  HCDefaultYAxisOptions,
} from 'modules/analytics/lib/highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type PeriodScale, formatDateWithPeriodScale } from 'utils/time';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import {
  HighchartsTooltipV2,
  type TooltipLine,
} from '../HighchartsTooltip/HighchartsTooltipV2';
import {
  type ChartValue,
  type ChartValueCustomData,
  LoadingComponent,
  NoDataComponent,
  computeYMin,
  generateXaxisOptions,
} from './common';
import { ChartContainer } from './styles.css';

type LineChartHorizontalLine = {
  value: number;
  label: string;
  color?: string;
};

export type LineChartData = {
  tooltipData?: TooltipLine[];
  date: Date;
  value: number;
  details?: string;
  missing?: boolean;
  originalData?: any;
};

export type LineChartProps = {
  data: LineChartData[];
  yAxisTitle?: string;
  fordwardRef?: React.RefObject<HighchartsReactRefObject>;
  loading?: boolean;
  horizontalLines?: LineChartHorizontalLine[];
  periodScale?: PeriodScale;
  unit: Unit;
  onItemClicked?: (originalData: any) => void;
  tooltipV2?: boolean;
  lineFillOptions?: GradientColorObject;
  displayBars?: boolean;
};

export const LineChart = ({
  data,
  yAxisTitle,
  fordwardRef,
  loading = false,
  horizontalLines = [],
  periodScale,
  unit,
  onItemClicked = () => {},
  tooltipV2,
  lineFillOptions,
  displayBars = false,
}: LineChartProps) => {
  const { t } = useTranslation('analytics');

  const legendEnabled = typeof yAxisTitle === 'string' && yAxisTitle.length > 0;
  const options = useMemo<Highcharts.Options>(() => {
    const zones: Highcharts.SeriesZonesOptionsObject[] = [];
    if (data.length && data[0].missing) {
      const lastNotMissing = data.findIndex((item) => !item.missing);
      if (lastNotMissing > -1) {
        zones.push({
          value: data[lastNotMissing].date.getTime(),
          color: colors.gray500,
        });
      }
    }

    const chartItemClickOptions: {
      cursor: Highcharts.CursorValue;
      events: Highcharts.SeriesEventsOptionsObject;
    } = {
      cursor: 'pointer',
      events: {
        click: (event) => {
          if (
            event.point.options.custom &&
            !event.point.options.custom.missing
          ) {
            onItemClicked(
              (event.point.options.custom as ChartValueCustomData).originalData,
            );
          }
        },
      },
    };

    return {
      ...HCDefaultOptions,
      chart: {
        ...HCDefaultOptions.chart,
        events: {
          click: function () {
            if (this.hoverPoint) {
              const custom: ChartValueCustomData | null = (
                this.hoverPoint as any
              ).custom;
              if (custom && !custom.missing)
                onItemClicked(
                  ((this.hoverPoint as any).custom as ChartValueCustomData)
                    .originalData,
                );
            }
          },
        },
      },
      plotOptions: {
        ...HCDefaultPlotOptions,
        areaspline: {
          ...HCDefaultAreasplineOptions,
          ...chartItemClickOptions,
          fillColor: lineFillOptions ?? undefined,
          fillOpacity: Number(!!lineFillOptions),
          zones,
        },
        column: {
          ...chartItemClickOptions,
        },
      },
      title: {
        text: undefined,
      },
      xAxis: generateXaxisOptions(data, periodScale),
      legend: {
        enabled: legendEnabled,
        align: 'left',
        events: {
          itemClick: () => {
            // disable legend click
            return false;
          },
        },
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        ...HCDefaultYAxisOptions,
        title: undefined,
        labels: {
          ...HCDefaultYAxisOptions.labels,
          formatter: function () {
            return displayValueAndUnit(this.value, unit, t, 'int');
          },
        },
        lineWidth: 0,
        startOnTick: true,
        min: computeYMin(data),
        plotLines: horizontalLines.map((line) => ({
          color: line.color || colors.yellow500,
          width: 2,
          value: line.value,
          zIndex: 5,
        })),
      },
      tooltip: {
        useHTML: tooltipV2 === true,
        crosshairs: true,
        enabled: true,
        animation: false,
        ...HCDefaultTooltipOptions,
        formatter: function () {
          let value =
            this.y != null ? displayValueAndUnit(this.y, unit, t) : '';
          const customData = this.options.custom as ChartValueCustomData;
          if (this.y === 0 && customData.missing) {
            value = t('chart.noData');
          }
          if (tooltipV2 && customData.tooltipDetails) {
            return `<b>${formatDateWithPeriodScale(this.x)}</b><br />${
              customData.tooltipDetails
            }`;
          }
          if (customData.tooltipDetails) {
            return `${formatDateWithPeriodScale(
              this.x,
              periodScale,
            )}<br/><b>${value}</b><br />${customData.tooltipDetails}`;
          }
          return `${formatDateWithPeriodScale(
            this.x,
            periodScale,
          )}<br/><b>${value}</b>`;
        },
      },
      series: [
        {
          color: colors.blue400,
          name: yAxisTitle,
          type: displayBars ? 'column' : 'areaspline',
          data: data.map((item): ChartValue => {
            return {
              x: item.date.getTime(),
              y: item.value,
              custom: {
                originalData: item.originalData,
                tooltipDetails: item.tooltipData
                  ? HighchartsTooltipV2(item.tooltipData, t)
                  : item.details,
                missing: item.missing,
              },
            };
          }),
        },
        // simulate a serie for each horizontal line
        // so it is displayed in the legend
        ...horizontalLines.map(
          (line) =>
            ({
              color: line.color || colors.yellow500,
              lineWidth: 1,
              name: line.label,
              type: 'areaspline',
              fillColor: undefined,
              fillOpacity: 0,
              includeInDataExport: false,
            }) as SeriesOptionsType,
        ),
      ],
    };
  }, [
    data,
    lineFillOptions,
    periodScale,
    legendEnabled,
    horizontalLines,
    tooltipV2,
    yAxisTitle,
    displayBars,
    onItemClicked,
    unit,
    t,
  ]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (data.length === 0) {
    return <NoDataComponent />;
  }

  return (
    <ChartContainer>
      <HighchartsReact
        className="line-chart"
        highcharts={Highcharts}
        options={options}
        ref={fordwardRef}
      />
    </ChartContainer>
  );
};
