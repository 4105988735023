import {
  type Page,
  type Section,
  trackAssetDrawerOpened,
} from 'modules/analytics/lib/tracking';
import { SideDrawer, Spinner } from 'modules/common-ui';
import { useTracking } from 'modules/utils';
import type React from 'react';
import { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Container, HeaderInnerContent } from './styles';

export type ComponentSource =
  | 'historyChart'
  | 'overviewTrendYearCard'
  | 'overviewTrendMonthCard'
  | 'overviewTrendWeekCard'
  | 'evolutionChart'
  | 'statisticsCard'
  | 'categoryChart'
  | 'other';

type TrackingProperties = {
  page: Page;
  section: Section;
  source: ComponentSource;
};

type AssetListDrawerProps = {
  open: boolean;
  title: string | null;
  mainContent: React.ReactNode;
  header?: React.ReactNode;
  loading?: boolean;
  onClose: () => void;
  trackingProps?: TrackingProperties;
};

export const AssetListDrawer = ({
  open,
  title,
  mainContent,
  header = null,
  loading = false,
  onClose,
  trackingProps,
}: AssetListDrawerProps) => {
  const { track } = useTracking();

  useEffect(() => {
    if (!open || !trackingProps) return;
    trackAssetDrawerOpened(track, trackingProps);
  }, [open, trackingProps, track]);

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <SideDrawer open={open} onClose={onClose} title={title}>
        {open && (
          <Container>
            <HeaderInnerContent>{header}</HeaderInnerContent>
            {loading ? <Spinner /> : mainContent}
          </Container>
        )}
      </SideDrawer>
    </OutsideClickHandler>
  );
};
