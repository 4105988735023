import type * as Types from '../../../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FleetSegmentGqlFragment = {
  __typename?: 'FleetSegment';
  id: number;
  name: string;
  assetCount: number;
  kind: Types.FleetSegmentKind;
  filters: {
    __typename?: 'AssetFilters';
    names?: Array<string> | null;
    categories?: Array<number> | null;
    models?: Array<string> | null;
    makers?: Array<string> | null;
    sources?: Array<string> | null;
    enterprises?: Array<string> | null;
    favorite?: boolean | null;
    zones?: Array<string> | null;
    customfields?: Array<{
      __typename?: 'CustomField';
      id: number;
      values: Array<string>;
    }> | null;
  };
};

export type FleetSegmentsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FleetSegmentsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    workspace?: {
      __typename?: 'Group';
      id?: string | null;
      fleetSegments?: Array<{
        __typename?: 'FleetSegment';
        id: number;
        name: string;
        assetCount: number;
        kind: Types.FleetSegmentKind;
        filters: {
          __typename?: 'AssetFilters';
          names?: Array<string> | null;
          categories?: Array<number> | null;
          models?: Array<string> | null;
          makers?: Array<string> | null;
          sources?: Array<string> | null;
          enterprises?: Array<string> | null;
          favorite?: boolean | null;
          zones?: Array<string> | null;
          customfields?: Array<{
            __typename?: 'CustomField';
            id: number;
            values: Array<string>;
          }> | null;
        };
      }> | null;
    } | null;
  } | null;
};

export const FleetSegmentGqlFragmentDoc = gql`
    fragment FleetSegmentGql on FleetSegment {
  id
  name
  assetCount
  filters {
    names
    categories
    models
    makers
    sources
    enterprises
    customfields {
      id
      values
    }
    favorite
    zones
  }
  kind
}
    `;
export const FleetSegmentsDocument = gql`
    query FleetSegments {
  viewer {
    id
    workspace {
      id
      fleetSegments {
        ...FleetSegmentGql
      }
    }
  }
}
    ${FleetSegmentGqlFragmentDoc}`;

/**
 * __useFleetSegmentsQuery__
 *
 * To run a query within a React component, call `useFleetSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFleetSegmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FleetSegmentsQuery,
    FleetSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FleetSegmentsQuery, FleetSegmentsQueryVariables>(
    FleetSegmentsDocument,
    options,
  );
}
export function useFleetSegmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FleetSegmentsQuery,
    FleetSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FleetSegmentsQuery, FleetSegmentsQueryVariables>(
    FleetSegmentsDocument,
    options,
  );
}
export type FleetSegmentsQueryHookResult = ReturnType<
  typeof useFleetSegmentsQuery
>;
export type FleetSegmentsLazyQueryHookResult = ReturnType<
  typeof useFleetSegmentsLazyQuery
>;
export type FleetSegmentsQueryResult = Apollo.QueryResult<
  FleetSegmentsQuery,
  FleetSegmentsQueryVariables
>;
