import { gql } from '@apollo/client';
import { usageHoursAssetPerformanceSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.fragment';
import type { UsageHoursAssetSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.generated';

export type UsageHoursHistoryItem = {
  averageUsageHoursPerActiveDays: number;
  averageActiveDaysPerUsedAssets: number;
  usedAssetsCount: number;
  activeAssetsCount: number;
  date: string;
};

export type UsageHoursHistory = {
  viewer: {
    usageHoursReport: {
      history: UsageHoursHistoryItem[];
    };
  };
};

export const usageHoursHistoryQuery = gql`
  query UsageHoursHistoryReport(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
  ) {
    viewer {
      id
      usageHoursReport {
        history(assetFilters: $assetFilters, periodScale: $periodScale) {
          averageUsageHoursPerActiveDays
          averageActiveDaysPerUsedAssets
          usedAssetsCount
          activeAssetsCount
          date
        }
      }
    }
  }
`;

type UsageHoursTrendsPeriodData = {
  averageUsageHoursPerActiveDays: number;
  averageActiveDaysPerUsedAssets: number;
  usedAssetsCount: number;
  activeAssetsCount: number;
  dateStart: string;
  dateEnd: string;
};

export type UsageHoursTrendsPeriodComparativeData = {
  current: UsageHoursTrendsPeriodData | null;
  previous: UsageHoursTrendsPeriodData | null;
};

export type UsageHoursTrendsResponse = {
  viewer: {
    usageHoursReport: {
      currentYear: UsageHoursTrendsPeriodComparativeData | null;
      currentMonth: UsageHoursTrendsPeriodComparativeData | null;
      currentWeek: UsageHoursTrendsPeriodComparativeData | null;
    };
  };
};

export const usageHoursTrendsQuery = gql`
  query getUsageHoursTrends($assetFilters: ReportAssetFilter!) {
    viewer {
      id
      usageHoursReport {
        currentYear(assetFilters: $assetFilters) {
          current {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            dateStart
            dateEnd
          }
          previous {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            dateStart
            dateEnd
          }
        }
        currentMonth(assetFilters: $assetFilters) {
          current {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            dateStart
            dateEnd
          }
          previous {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            dateStart
            dateEnd
          }
        }

        currentWeek(assetFilters: $assetFilters) {
          current {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            dateStart
            dateEnd
          }
          previous {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            dateStart
            dateEnd
          }
        }
      }
    }
  }
`;

export type Category = {
  id: number;
  name: string;
};

export type UsageHoursByCategory = {
  categoryId: number | null;
  averageUsageHoursPerActiveDays: number;
  averageActiveDaysPerUsedAssets: number;
  usedAssetsCount: number;
  activeAssetsCount: number;
};

export type UsageHoursByCategoryResponse = {
  viewer: {
    id: number;
    organization: {
      id: number;
      categories: Category[];
    };
    usageHoursReport: {
      byCategory: UsageHoursByCategory[];
    };
  };
};

export const usageHoursByCategoryQuery = gql`
  query getUsageHoursByCategory(
    $assetFilters: ReportAssetFilter!
    $period: Period!
  ) {
    viewer {
      id
      organization {
        id
        categories {
          id
          name
        }
      }
      usageHoursReport {
        byCategory(assetFilters: $assetFilters, period: $period) {
          categoryId
          averageUsageHoursPerActiveDays
          averageActiveDaysPerUsedAssets
          usedAssetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

export type UsageHoursByPeriod = {
  averageUsageHoursPerActiveDays: number;
  date: Date;
  activeAssetsCount: number;
  usedAssetsCount: number;
  averageActiveDaysPerUsedAssets: number;
};

export type UsageHoursByPeriodResponse = {
  viewer: {
    id: string;
    usageHoursReport: {
      usageHours: UsageHoursByPeriod[];
    };
  };
};

export const usageHoursReportEmissionsQuery = gql`
  query UsageHoursByPeriodQuery(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
    $since: String!
    $until: String!
  ) {
    viewer {
      id
      usageHoursReport {
        usageHours(
          assetFilters: $assetFilters
          periodScale: $periodScale
          period: { since: $since, until: $until }
        ) {
          averageUsageHoursPerActiveDays
          date
          activeAssetsCount
          usedAssetsCount
          averageActiveDaysPerUsedAssets
        }
      }
    }
  }
`;

type UsageHoursStatisticsByPeriod = {
  averageUsageHoursPerActiveDays: number;
  period: {
    start: string;
    end: string;
  };
  activeAssetsCount: number;
  usedAssetsCount: number;
  averageActiveDaysPerUsedAssets: number;
};

export type UsageHoursStatisticsResponse = {
  viewer: {
    usageHoursReport: {
      statisticsCustomPeriod: {
        current: UsageHoursStatisticsByPeriod | null;
        previous: UsageHoursStatisticsByPeriod | null;
      };
    };
  };
};

export const UsageHoursStatisticsQuery = gql`
  query getUsageHoursStatisticsCustomPeriodReport(
    $assetFilters: ReportAssetFilter!
    $period: Period!
  ) {
    viewer {
      id
      usageHoursReport {
        statisticsCustomPeriod(assetFilters: $assetFilters, period: $period) {
          current {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            period {
              start
              end
            }
          }
          previous {
            averageUsageHoursPerActiveDays
            averageActiveDaysPerUsedAssets
            usedAssetsCount
            activeAssetsCount
            period {
              start
              end
            }
          }
        }
      }
    }
  }
`;

export type UsageHoursAssetsPerformanceRow = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  make: string | null;
  categoryId: number | null;
  enterprise: string | null;
  model: string | null;
  serialNumber: string | null;
  source: string | null;
  averageUsageHoursPerActiveDays: number;
  previousAvgUsageHoursPerActiveDays: number | null;
  previousAvgUsageHoursPerActiveDaysDelta: number | null;
  usageHours: number;
  activeDays: number;
  categoryAvgUsageHoursPerActiveDays: number | null;
  categoryAvgUsageHoursPerActiveDaysDelta: number | null;
  categoryUsedAssetsCount: number | null;
  modelAvgUsageHoursPerActiveDays: number | null;
  modelAvgUsageHoursPerActiveDaysDelta: number | null;
  modelUsedAssetsCount: number | null;
} & UsageHoursAssetSharingInfosFragment;

export type UsageHoursAssetsPerformanceResponse = {
  viewer: {
    usageHoursReport: {
      assetsPerformance: {
        rows: UsageHoursAssetsPerformanceRow[];
        total: number;
        offset: number;
        limit: number;
      };
    };
  };
};

export const usageHoursAssetsPerformanceQuery = gql`
  ${usageHoursAssetPerformanceSharingInfosFragment}
  query UsageHoursAssetsPerformance(
    $assetFilters: ReportAssetFilter!
    $period: Period!
    $pagination: Pagination!
    $sortBy: UsageHoursPerformanceSortBy!
  ) {
    viewer {
      id
      usageHoursReport {
        assetsPerformance(
          assetFilters: $assetFilters
          period: $period
          pagination: $pagination
          sortBy: $sortBy
        ) {
          total
          offset
          limit
          rows {
            assetInOrganizationId
            assetId
            name
            enterprise
            categoryId
            model
            make
            serialNumber
            source
            averageUsageHoursPerActiveDays
            previousAvgUsageHoursPerActiveDays
            previousAvgUsageHoursPerActiveDaysDelta
            usageHours
            activeDays
            categoryAvgUsageHoursPerActiveDays
            categoryAvgUsageHoursPerActiveDaysDelta
            categoryUsedAssetsCount
            modelAvgUsageHoursPerActiveDays
            modelAvgUsageHoursPerActiveDaysDelta
            modelUsedAssetsCount
            ...UsageHoursAssetSharingInfos
          }
        }
      }
    }
  }
`;

export type UsageHoursAssetsListItem = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  make: string | null;
  averageUsageHoursPerActiveDays: number;
};

export type UsageHoursAssetsListResponse = {
  viewer: {
    usageHoursReport: {
      assetList: UsageHoursAssetsListItem[];
    };
  };
};

export type AssetListDataSource = 'daily' | 'monthly';

export const usageHoursAssetsListQuery = gql`
  query UsageHoursAssetsList(
    $assetFilters: ReportAssetFilter!
    $period: Period!
    $dataSource: AssetListDataSource!
  ) {
    viewer {
      id
      usageHoursReport {
        assetList(
          assetFilters: $assetFilters
          period: $period
          dataSource: $dataSource
        ) {
          assetInOrganizationId
          assetId
          name
          make
          averageUsageHoursPerActiveDays
        }
      }
    }
  }
`;
