import { useTranslation } from 'react-i18next';
import { Container, Value } from './styles.css';

export const NoTrend = () => {
  const { t } = useTranslation('analytics');

  return (
    <Container>
      <Value>{t('simpleValue.trend.noData')}</Value>
    </Container>
  );
};
