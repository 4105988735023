import {
  Dropdown,
  type DropdownOption,
  ExportIcon,
  Tooltip,
} from 'modules/common-ui';
import styled from 'styled-components';

type CardOptionsProps = {
  options: (DropdownOption & {
    handler: () => void;
  })[];
  forwardRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  disabledTooltipInfo?: string;
};

const IconContainer = styled.div`
  padding: 7px 8px 3px 8px;
  user-select: none;
  width: 32px;
  text-align: center;
`;

export const CardOptions = ({
  forwardRef,
  options,
  disabled = false,
  disabledTooltipInfo,
}: CardOptionsProps) => {
  if (options.length === 0) return null;

  const handleMenuChange = (value: any) => {
    const option = options.find((o) => o.value === value);
    if (option) option.handler();
  };

  const displayTooltip = disabled && disabledTooltipInfo;

  return (
    <div ref={forwardRef} data-for={'card-options-tooltip'} data-tip>
      <Dropdown
        align="right"
        customSelector={
          <IconContainer>
            <ExportIcon size={16} />
          </IconContainer>
        }
        options={options}
        value={null}
        onChange={handleMenuChange}
        disabled={disabled}
      />
      {displayTooltip && (
        <Tooltip id={'card-options-tooltip'} place="bottom">
          {disabledTooltipInfo}
        </Tooltip>
      )}
    </div>
  );
};
