import colors from 'constants/colors';
import fontSizes from 'constants/fontSizes';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  font-size: ${fontSizes.headings.h3};
  font-weight: 500;
  line-height: 30px;
  color: ${colors.gray850};
`;

const Subtitle = styled.div`
  font-size: ${fontSizes.headings.h5};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.gray500};
`;

export const Header = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <HeaderContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </HeaderContainer>
  );
};
